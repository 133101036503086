import { createAsyncThunk } from '@reduxjs/toolkit';
import * as userQuizApi from './userQuizApi';

export const fetchUserQuiz = createAsyncThunk(
  'userQuiz/fetchUserQuiz',
  async ({ userId, quizId }: { userId: string; quizId: string }) => {
    return await userQuizApi.getUserQuizData(userId, quizId);
  }
);

export const startNewQuiz = createAsyncThunk(
  'userQuiz/startNewQuiz',
  async (quizId: string) => {
    return await userQuizApi.startQuiz(quizId);
  }
);

export const submitQuizAnswer = createAsyncThunk(
  'userQuiz/submitAnswer',
  async ({ userQuizId, questionId, answer }: { userQuizId: string; questionId: string; answer: string }) => {
    return await userQuizApi.submitAnswer(userQuizId, questionId, answer);
  }
); 