import { Card, CardBody, Text, HStack, CardHeader, Badge, List, ListItem, Icon, ListIcon, Link, Button } from "@chakra-ui/react";
import { useAppSelector } from "@/hooks/useRedux";
import { FaBook, FaBookReader, FaChrome, FaVideo } from "react-icons/fa";

const resourceTypeIconMap = {
  pdf : FaBookReader,
  video: FaVideo,
  website: FaChrome,
  default: FaBook,
} as const;

// Define the type for valid resource types
export type ResourceType = keyof typeof resourceTypeIconMap;

const LearningResourcesCard = () => {
  const { resources } = useAppSelector((state) => state.learnerDashboard);

  return (
    <Card>
      <CardHeader bg="green.500" py={3}>
        <HStack>
          <Icon as={FaBookReader} color="white" />
          <Text color="white" fontSize="lg" fontWeight="bold">
            Learning Resources
          </Text>
        </HStack>
      </CardHeader>
      <CardBody>
        <List spacing={3}>
          {resources.map((resource) => {
            const icon = resourceTypeIconMap[resource.type as ResourceType] || resourceTypeIconMap.default
             
            return (
              <ListItem key={resource.id}>
                <HStack justify="space-between">
                  <HStack>
                    <ListIcon as={icon} color="green.500" />
                    <Text>{resource.name}</Text>
                    <Badge colorScheme="green">{resource.type}</Badge>
                  </HStack>
                  <Link href={resource.id}>
                    <Button size="sm" colorScheme="green" variant="outline">
                      Access
                    </Button>
                  </Link>
                </HStack>
              </ListItem>
            );
          })}
        </List>
      </CardBody>
    </Card>
  );
};

export default LearningResourcesCard;
