import api from "@/utils/api";
import { ICourse } from "@/features/admin/course/adminCourseTypes";

export const getEnrollableCourseByIdApi = async (courseId: string): Promise<ICourse> => {
  const response = await api.get(`/courses/${courseId}`);
  return response.data;
};

export const createEnrollment = async (courseId: string) => {
  const response = await api.post("/enrollments", { courseId });
  return response.data;
};

export const initiatePayment = async (enrollmentId: string) => {
  const response = await api.post("/payments/create-checkout-session", { enrollmentId });
  return response.data;
};