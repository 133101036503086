import React from 'react';
import {
 
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  HStack,
  Icon,
  Card,
 
  CardBody,
} from '@chakra-ui/react';
import { FaListUl, FaKeyboard, FaPencilAlt, FaArrowsAlt } from 'react-icons/fa';
import { MdHeadset, MdOutlineTextIncrease } from 'react-icons/md';
import { Quiz } from '@/features/learner/course/courseTypes';

interface ModuleQuizStatsProps {
  quiz: Quiz;
}

const questionTypeMap = {
  'multi-option': { label: 'Multi Option', icon: FaListUl },
  'fill_blank': { label: 'Fill The Blank', icon: FaKeyboard },
  'sentence': { label: 'Sentence', icon: FaPencilAlt },
  'drag-drop': { label: 'Drag Drop', icon: FaArrowsAlt },
} as const;

const sourceTypeMap = {
  'audio': { label: 'Listening', icon: MdHeadset },
  'text': { label: 'Reading', icon: MdOutlineTextIncrease },
} as const;

export const ModuleQuizStats: React.FC<ModuleQuizStatsProps> = ({ quiz }) => {
  return (
    <HStack spacing={8} align="flex-start" justify="space-between">
      <Card variant="outline">
        
        <CardBody>
          <StatGroup>
            <Stat borderWidth={1} borderRadius="lg" p={4} bg="green.500" color="white">
              <StatNumber fontSize="2xl">{quiz.questions.length}</StatNumber>
              <HStack justify="center" spacing={1}>
                <Icon as={FaListUl} boxSize={4} />
                <StatLabel fontSize="sm">Total</StatLabel>
              </HStack>
            </Stat>
          </StatGroup>
        </CardBody>
      </Card>

      <Card variant="outline">
        
        <CardBody>
          <StatGroup gap={4} display="flex" flexWrap="wrap">
            {(() => {
              const types: Record<string, number> = {};
              const colors = ['purple.400', 'purple.600'];
              quiz.questions.forEach(q => {
                types[q.sourceType] = (types[q.sourceType] || 0) + 1;
              });
              return Object.entries(types).map(([type, count], index) => (
                <Stat key={type} borderWidth={1} borderRadius="lg" p={4} flex="1" bg={colors[index]} color="white">
                  <StatNumber fontSize="2xl">{count}</StatNumber>
                  <HStack justify="center" spacing={1} whiteSpace="nowrap">
                    <Icon as={sourceTypeMap[type as keyof typeof sourceTypeMap].icon} boxSize={4} />
                    <StatLabel fontSize="sm">{sourceTypeMap[type as keyof typeof sourceTypeMap].label}</StatLabel>
                  </HStack>
                </Stat>
              ));
            })()}
          </StatGroup>
        </CardBody>
      </Card>
      <Card variant="outline">
         
        <CardBody>
          <StatGroup gap={4} display="flex" flexWrap="wrap">
            {(() => {
              const types: Record<string, number> = {};
              const colors = ['blue.400', 'blue.500', 'blue.600', 'blue.700'];
              quiz.questions.forEach(q => {
                types[q.questionType] = (types[q.questionType] || 0) + 1;
              });
              return Object.entries(types).map(([type, count], index) => (
                <Stat key={type} borderWidth={1} borderRadius="lg" p={4} flex="1" bg={colors[index]} color="white">
                  <StatNumber fontSize="2xl">{count}</StatNumber>
                  <HStack justify="center" spacing={1} whiteSpace="nowrap">
                    <Icon as={questionTypeMap[type as keyof typeof questionTypeMap].icon} boxSize={4} />
                    <StatLabel fontSize="sm">{questionTypeMap[type as keyof typeof questionTypeMap].label}</StatLabel>
                  </HStack>
                </Stat>
              ));
            })()}
          </StatGroup>
        </CardBody>
      </Card>

    </HStack>
  );
}; 