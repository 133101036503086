import React from "react";
import {
  Card,
  CardBody,
  Stack,
  Text,
  Button,
  List,
  ListItem,
  ListIcon,
} from "@chakra-ui/react";
import { CheckCircle, XCircle } from "lucide-react";
import { ITelegramGroupRequirement } from "@/features/admin/course/adminCourseTypes";

interface GroupRequirementsCardProps {
  requirements: ITelegramGroupRequirement;
  onCreateGroups: () => void;
}

const GroupRequirementsCard: React.FC<GroupRequirementsCardProps> = ({
  requirements,
  onCreateGroups,
}) => {
  return (
    <Card variant="outline">
      <CardBody>
        <Stack spacing={4}>
          <Text fontWeight="bold">Group Requirements</Text>
          <List spacing={3}>
            <ListItem display="flex" alignItems="center">
              <ListIcon as={requirements.canCreate ? CheckCircle : XCircle} color={requirements.canCreate ? "green.500" : "red.500"} />
              <Text>{requirements.message}</Text>
            </ListItem>
            {requirements.canCreate && (
              <>
                <ListItem>
                  Total Students: {requirements.enrollmentCount}
                </ListItem>
                <ListItem>
                  Required Groups: {requirements.requiredGroups}
                </ListItem>
                <ListItem>
                  Group Sizes: {requirements.groupSizes.join(", ")} students
                </ListItem>
              </>
            )}
          </List>
          {requirements.canCreate && (
            <Button colorScheme="blue" onClick={onCreateGroups}>
              Create Telegram Groups
            </Button>
          )}
        </Stack>
      </CardBody>
    </Card>
  );
};

export default GroupRequirementsCard;