import { VStack, Card, CardBody, Text, Heading, Avatar, Badge, Progress, Divider, ListItem, List, ListIcon } from "@chakra-ui/react";

import { MdEmail, MdScore } from "react-icons/md";
import { FaCalendarTimes, FaUserAltSlash, FaUserCheck, FaUserEdit, FaUserShield, FaUserTimes } from "react-icons/fa";
import { IExtendedUser } from "@/features/users/userTypes";
import { API_URL } from "@/constants/globalConstants";

interface Props {
  user: IExtendedUser | null;
  showAdminFileds: boolean;
}
const UserProfile = ({ user: currentUser, showAdminFileds }: Props) => {
  return (
    <Card>
      <CardBody>
        {!currentUser && <Text>No User Id Selected Yet</Text>}

        <VStack spacing={4} align="center">
          <Avatar size="xl" name={currentUser?.username} src={`${API_URL}/images/${currentUser?.id}.jpg`} />
          <Heading size="md">{currentUser?.username}</Heading>
          <Divider />
          <List spacing={3} fontSize={"1.5em"}>
            <ListItem>
              <ListIcon as={MdEmail} color="pink.500" />
              <Text fontSize={"medium"} as={"span"}>
                {" "}
                e-mail : {currentUser?.email}
              </Text>
            </ListItem>
            <ListItem>
              <ListIcon as={FaUserEdit} color="pink.500" />
              <Text fontSize={"medium"} as={"b"}>
                Role : {currentUser?.role.toLocaleUpperCase()}
              </Text>
            </ListItem>

            {currentUser?.role === "learner" && (
              <>
                <ListItem>
                  <ListIcon as={MdScore} color="pink.500" />
                  <Text fontSize={"medium"} as={"span"}>
                    {" "}
                    English Level :{" "}
                  </Text>
                  <Badge colorScheme="green" fontSize="1.2em" variant={"solid"}>
                    {currentUser?.leveltest?.level}
                  </Badge>
                  {" - "}
                  <Badge colorScheme="green" fontSize="1.2em" variant={"solid"}>
                    {currentUser?.leveltest?.score != null ? Math.round(currentUser.leveltest.score) : "N/A"}
                  </Badge>
                </ListItem>
                <ListItem>
                  <Text>Overall Progress</Text>
                  <Progress value={45} width="100%" colorScheme="blue" />
                </ListItem>
              </>
            )}
            {showAdminFileds && (
              <>
                <Divider />
                <ListItem>
                  <ListIcon as={FaUserCheck} color="purple.500" />
                  <Text fontSize={"medium"} as={"span"}>
                    {" "}
                    Verified Email ? : {currentUser?.isVerified}
                  </Text>
                </ListItem>
                <ListItem>
                  <ListIcon as={FaCalendarTimes} color="purple.500" />
                  <Text fontSize={"medium"} as={"span"}>
                    {" "}
                    Verification Code Expire Due : {currentUser?.verificationCodeExpires ? currentUser?.verificationCodeExpires.toString() : ""}
                  </Text>
                </ListItem>
                <Divider />
                <ListItem>
                  <ListIcon as={FaUserAltSlash} color="purple.500" />
                  <Text fontSize={"medium"} as={"span"}>
                    {" "}
                    Failed Login Attempts : {currentUser?.failedLoginAttempts}
                  </Text>
                </ListItem>
                <ListItem>
                  <ListIcon as={FaUserTimes} color="purple.500" />
                  <Text fontSize={"medium"} as={"span"}>
                    {" "}
                    Last Failed Login Attempt : {currentUser?.lastFailedLoginAttempt ? currentUser?.lastFailedLoginAttempt.toString() : ""}
                  </Text>
                </ListItem>
                <ListItem>
                  <ListIcon as={FaUserShield} color="purple.500" />
                  <Text fontSize={"medium"} as={"span"}>
                    {" "}
                    Lock-out Until : {currentUser?.lockoutUntil ? currentUser?.lockoutUntil.toString() : ""}
                  </Text>
                </ListItem>
                <Divider />
              </>
            )}
          </List>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default UserProfile;
