import {
    Card,
    CardHeader,
    CardBody,
    Text,
    VStack,
    HStack,
    Badge,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    PopoverHeader,
    Button,
    Skeleton,
    PopoverArrow,
  } from "@chakra-ui/react";
  import { useNavigate } from "react-router-dom";
  import {  MdGroup, MdCalendarToday } from "react-icons/md";
  import { useAppSelector } from "@/hooks/useRedux";
  import { IEnrollableCourse } from "@/features/learner/dashboard/learnerDashboardTypes";
  
  const EnrollableCourses = () => {
    const { enrollableCourses, isLoading } = useAppSelector((state) => state.learnerDashboard);
    const navigate = useNavigate();
  
    const handleEnrollClick = (courseId: string) => {
      navigate(`/learner/enroll/${courseId}`);
    };
  
    // Format date helper
    const formatDate = (date: Date) => {
      return new Date(date).toLocaleDateString("en-US", {
        year: "numeric",
        month: "short",
        day: "numeric",
      });
    };
  
    
  
    return (
      <Card>
        <CardHeader bg="orange.500" py={3}>
          <Text color="white" fontSize="lg" fontWeight="bold">
            Available Courses
          </Text>
        </CardHeader>
        <CardBody>
          <VStack spacing={4} align="stretch">
            {isLoading ? (
              [...Array(3)].map((_, i) => <Skeleton key={i} height="100px" borderRadius="md" />)
            ) : enrollableCourses.length === 0 ? (
              <Text color="gray.500" textAlign="center">
                No courses available for enrollment at this time.
              </Text>
            ) : (
              enrollableCourses.map((course: IEnrollableCourse) => (
                <Popover key={course.id} trigger="hover" placement="left">
                  <PopoverTrigger>
                    <Card variant="outline" _hover={{ shadow: "md", borderColor: "orange.200" }} cursor="pointer">
                      <CardBody>
                        <HStack justify="space-between" align="start">
                          <VStack align="start" spacing={2}>
                            <Text fontWeight="bold" fontSize="md">
                              {course.name}
                            </Text>
                            <HStack spacing={4} color="gray.600" fontSize="sm">
                              <HStack>
                                <MdCalendarToday />
                                <Text>{formatDate(course.startDate)}</Text>
                              </HStack>
                              <HStack>
                                <MdGroup />
                                <Text>{course.maxEnrollments - course.currentEnrollments} spots left</Text>
                              </HStack>
                            </HStack>
                          </VStack>
                          <VStack align="end" spacing={2}>
                            <Badge colorScheme="green" fontSize="md" px={2}>
                              ${course.price}
                            </Badge>
                            <Button
                              size="sm"
                              colorScheme="orange"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleEnrollClick(course.id);
                              }}
                            >
                              Enroll Now
                            </Button>
                          </VStack>
                        </HStack>
                      </CardBody>
                    </Card>
                  </PopoverTrigger>
                  <PopoverContent w={"20vw"}  bg="rgba(107, 70, 193, 0.85)" color="white"  >
                    <PopoverArrow bg={'purple.800'}/>
                    <PopoverHeader fontWeight="bold" borderBottom="1px solid" borderColor="gray.200" >
                      Course Details
                    </PopoverHeader>
                    <PopoverBody >
                      <VStack align="start" spacing={3}>
                        <Text >
                           {formatDate(course.startDate)} - {formatDate(course.endDate)}
                        </Text>
                        
  
                        <Text fontSize={'small'}>{course.description}</Text>
                      </VStack>
                    </PopoverBody>
                  </PopoverContent>
                </Popover>
              ))
            )}
          </VStack>
        </CardBody>
      </Card>
    );
  };
  
  export default EnrollableCourses;
  