// src/components/admin/stats/IncomingZoomSessionsList.tsx
import React from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Card, CardBody, Heading, Skeleton, Badge, Link } from "@chakra-ui/react";
import { IZoomSession } from "@/features/admin/dashboard/adminDashboardTypes";
 

interface IncomingZoomSessionsListProps {
  sessions: IZoomSession[];
  isLoading?: boolean;
}

const IncomingZoomSessionsList: React.FC<IncomingZoomSessionsListProps> = ({ sessions, isLoading = false }) => {
  // Helper function to format date and determine if session is today
  const formatSessionDate = (date: Date): { formattedDate: string; isToday: boolean } => {
    const sessionDate = new Date(date);
    const today = new Date();
    const isToday = sessionDate.toDateString() === today.toDateString();

    return {
      formattedDate: sessionDate.toLocaleString("en-US", {
        weekday: "short",
        month: "short",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      }),
      isToday,
    };
  };

  if (isLoading) {
    return (
      <Card>
        <CardBody>
          <Heading size="md" mb={4}>
            Incoming Zoom Sessions
          </Heading>
          <Box overflowX="auto">
            <Skeleton height="200px" />
          </Box>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardBody>
        <Heading size="md" mb={4}>
          Incoming Zoom Sessions
        </Heading>
        <Box overflowX="auto">
          <Table variant="striped" size="sm">
            <Thead>
              <Tr>
                <Th>Course Name</Th>
                <Th>Link</Th>
                <Th>Date</Th>
                <Th>Status</Th>
              </Tr>
            </Thead>
            <Tbody>
              {sessions.map((session, index) => {
                const { formattedDate, isToday } = formatSessionDate(session.date);
                return (
                  <Tr key={index}>
                    <Td>{session.name}</Td>
                    <Td><Link color="blue.500" target="_blank" href={session.zoomLink}>Click to Open Zoom Link</Link></Td>
                    <Td>{formattedDate}</Td>
                    <Td>{isToday ? <Badge colorScheme="green">Today</Badge> : <Badge colorScheme="blue">Upcoming</Badge>}</Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </Box>
      </CardBody>
    </Card>
  );
};

export default IncomingZoomSessionsList;
