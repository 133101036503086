import React, { useState } from "react";
import { Button, Stack, useToast } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { fetchTelegramGroupRequirementsThunk } from "@/features/admin/course/adminCourseActions";
import { ICourse } from "@/features/admin/course/adminCourseTypes";
import GroupRequirementsCard from "./GroupRequirementsCard";
import CreateGroupsModal from "./CreateGroupsModal";

interface TelegramGroupManagementProps {
  currentCourse: ICourse | null;
}

const TelegramGroupManagement: React.FC<TelegramGroupManagementProps> = ({ currentCourse }) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const { telegramGroupRequirements, isLoading } = useAppSelector((state) => state.adminCourse);

  const handleGetRequirements = async () => {
    if (currentCourse) {
      try {
        await dispatch(fetchTelegramGroupRequirementsThunk(currentCourse.id)).unwrap();
      } catch (error) {
        toast({
          title: "Error fetching requirements",
          description: error instanceof Error ? error.message : "Failed to fetch requirements",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <Stack spacing={4}>
      {!telegramGroupRequirements ? (
        <Button colorScheme="blue" isLoading={isLoading} onClick={handleGetRequirements}>
          Get Group Requirements
        </Button>
      ) : (
        <GroupRequirementsCard requirements={telegramGroupRequirements} onCreateGroups={() => setIsCreateModalOpen(true)} />
      )}

      <CreateGroupsModal isOpen={isCreateModalOpen} onClose={() => setIsCreateModalOpen(false)} requirements={telegramGroupRequirements} courseId={currentCourse?.id || ""} />
    </Stack>
  );
};

export default TelegramGroupManagement;
