import { Box, Button, Card, CardBody, Container, Grid, GridItem, Text, VStack, Center, Spinner } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { FaClipboardList } from "react-icons/fa";

import UserProfile from "@/components/common/UserProfile";

 
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { fetchDashboardData } from "@/features/learner/dashboard/learnerDashboardActions";
import { useEffect } from "react";
import EnrollableCourses from "@/components/learner/dashboard/EnrollableCourses";
import TelegramGroup from "@/components/learner/dashboard/TelegramGroup";
import EnrolledCourses from "@/components/learner/dashboard/EnrolledCourses";
import LearningAnalyticsCard from "@/components/learner/dashboard/LearningAnalytics";
import LearningResourcesCard from "@/components/learner/dashboard/LearningResourcesCard";
import UpcomingZoomSessions from "@/components/learner/dashboard/UpcomingZoomSessions";

const LearnerDashboard = () => {
 
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Get dashboard data from our new slice
  const { currentUser } = useAppSelector((state) => state.user);
  const { isLoading, error } = useAppSelector((state) => state.learnerDashboard);

  // Fetch dashboard data on component mount
  useEffect(() => {
    console.log("useeffect");
    dispatch(fetchDashboardData());
  }, [dispatch]);

  const handleStartLevelTest = () => {
    navigate("/leveltest");
  };

  if (isLoading) {
    return (
      <Container centerContent py={20}>
        <Spinner size="xl" color="purple.500" />
        <Text mt={4}>Loading your dashboard...</Text>
      </Container>
    );
  }

  if (error) {
    return (
      <Center>
        <Text color="red.500">{error}</Text>
      </Center>
    );
  }

  // If user hasn't taken the level test, show only the level test prompt
  if (currentUser && !currentUser.leveltest) {
    return (
      <Container maxW="container.xl" py={8}>
        <Card bg="purple.50" border="1px" borderColor="purple.200" shadow="lg">
          <CardBody>
            <VStack spacing={6} align="center" py={10}>
              <FaClipboardList size={64} color="#805AD5" />
              <Text fontSize="2xl" fontWeight="bold" color="purple.700">
                Welcome to FluentChatter!
              </Text>
              <Text fontSize="lg" textAlign="center" color="gray.600">
                Before you can enroll in any courses, we need to assess your current English level. This helps us recommend the most suitable courses for you.
              </Text>
              <Button size="lg" colorScheme="purple" onClick={handleStartLevelTest} shadow="md">
                Take Level Test Now
              </Button>
            </VStack>
          </CardBody>
        </Card>
      </Container>
    );
  }

  // Main dashboard view for users with level test
  return (
    <Box p={8}>
      <Grid
        templateColumns={{
          base: "1fr", // Stack on mobile
          lg: "380px 1fr 1fr", // Three columns on desktop
        }}
        gap={6}
      >
        {/* Left Column - User Profile and Quick Access */}
        <GridItem pr={5}>
          <VStack spacing={6}>
          
            <UserProfile user={currentUser} showAdminFileds={false} />{" "}
          </VStack>
        </GridItem>

        {/* Middle Column - Course Lists */}
        <GridItem>
          <VStack spacing={6} align={"stretch"}>
            {/* Enrollable Courses */}
            <EnrollableCourses />

            {/* Enrolled Courses */}
            <EnrolledCourses />

            {/* Learning Resources */}
            <LearningResourcesCard />
          </VStack>
        </GridItem>
        {/* Right Column - Course Information and Analytics */}
        <GridItem>
          <VStack spacing={6} align="stretch">
            {/* Learning Analytics Card */}
            <LearningAnalyticsCard />

            {/* Telegram Group Card */}
            <TelegramGroup />
            {/* Upcoming Zoom Sessions */}
            <UpcomingZoomSessions />
          </VStack>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default LearnerDashboard;
