import { createAsyncThunk } from "@reduxjs/toolkit";
import { ICourse, ICourseType, IModule, IQuizQuestion } from "./adminCourseTypes";
import { AxiosError } from "axios";
import {
  changeUserGroup,
  createCourseApi,
  createCourseTypeApi,
  createModuleApi,
  createTelegramGroups,
  getCourseGroups,
  getGroupMembers,
  getTelegramGroupRequirements,
  toggleCourseActiveApi,
  toggleCourseEnrollmentApi,
  updateCourseApi,
  updateCourseTypeApi,
  updateModuleApi,
  createQuizApi,
  getQuizQuestions,
  createQuizQuestionApi,
  updateQuizQuestionApi,
  deleteQuizQuestionApi,
  getModuleQuiz,
} from "./adminCourseApi";


export const createCourseTypeThunk = createAsyncThunk("adminCourse/createCourseType", async (courseTypeData: ICourseType, { rejectWithValue }) => {
  try {
    return await createCourseTypeApi(courseTypeData);
  } catch (error) {
    //If the API returns a status code other than 201 (or any 2xx), Axios will throw an error.
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Create Course Type failed");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});

export const updateCourseTypeThunk = createAsyncThunk("adminCourse/updateCourseType", async (courseTypeData: ICourseType, { rejectWithValue }) => {
  try {
    return await updateCourseTypeApi(courseTypeData);
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Update Course Type failed");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});

export const createModuleThunk = createAsyncThunk("adminCourse/createModule", async (moduleData: IModule, { rejectWithValue }) => {
  try {
    return await createModuleApi(moduleData);
  } catch (error) {
    //If the API returns a status code other than 201 (or any 2xx), Axios will throw an error.
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Create Module failed");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});

export const updateModuleThunk = createAsyncThunk("adminCourse/updateModule", async (moduleData: IModule, { rejectWithValue }) => {
  try {
    return await updateModuleApi(moduleData);
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Update Module failed");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});

export const createCourseThunk = createAsyncThunk("adminCourse/createCourse", async (courseData: ICourse, { rejectWithValue }) => {
  try {
    return await createCourseApi(courseData);
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Create Course failed");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});

export const updateCourseThunk = createAsyncThunk("adminCourse/updateCourse", async (courseData: ICourse, { rejectWithValue }) => {
  try {
    return await updateCourseApi(courseData);
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Update Course failed");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});

export const toggleCourseActiveThunk = createAsyncThunk("adminCourse/toggleCourseAtive", async (courseId: string, { rejectWithValue }) => {
  try {
    return await toggleCourseActiveApi(courseId);
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Toggle  Course Active failed");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});

export const toggleCourseEnrollmentThunk = createAsyncThunk("adminCourse/toggleCourseEnrollment", async (courseId: string, { rejectWithValue }) => {
  try {
    return await toggleCourseEnrollmentApi(courseId);
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Toggle Course Enrollment failed");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});

/* Telegram Group Thunks */
export const fetchTelegramGroupRequirementsThunk = createAsyncThunk("adminCourse/fetchTelegramGroupRequirements", async (courseId: string, { rejectWithValue }) => {
  try {
    return await getTelegramGroupRequirements(courseId);
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Failed to fetch group requirements");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});

export const fetchTelegramGroupsThunk = createAsyncThunk("adminCourse/fetchTelegramGroups", async (courseId: string, { rejectWithValue }) => {
  try {
    return await getCourseGroups(courseId);
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Failed to fetch telegram groups");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});

export const fetchGroupMembersThunk = createAsyncThunk("adminCourse/fetchGroupMembers", async (groupId: string, { rejectWithValue }) => {
  try {
    return await getGroupMembers(groupId);
  } catch (error) {
    if (error instanceof AxiosError && error.response) {
      return rejectWithValue(error.response.data.message || "Failed to fetch group members");
    }
    return rejectWithValue(`An error occurred: ${error}`);
  }
});

export const createTelegramGroupsThunk = createAsyncThunk(
  "adminCourse/createTelegramGroups",
  async ({ courseId, groupNames }: { courseId: string; groupNames: string[] }, { rejectWithValue }) => {
    try {
      return await createTelegramGroups(courseId, groupNames);
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data.message || "Failed to create telegram groups");
      }
      return rejectWithValue(`An error occurred: ${error}`);
    }
  }
);

export const changeUserGroupThunk = createAsyncThunk(
  "adminCourse/changeUserGroup",
  async (data: { userId: string; currentGroupId: string; newGroupId: string }, { rejectWithValue }) => {
    try {
      return await changeUserGroup(data);
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data.message || "Failed to change user group");
      }
      return rejectWithValue(`An error occurred: ${error}`);
    }
  }
);

export const createQuizThunk = createAsyncThunk(
  "adminCourse/createQuiz",
  async (moduleId: string, { rejectWithValue }) => {
    try {
      return await createQuizApi({ moduleId });
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data.message || "Create Quiz failed");
      }
      return rejectWithValue(`An error occurred: ${error}`);
    }
  }
);


export const fetchModuleQuizThunk = createAsyncThunk(
  "adminCourse/fetchModuleQuiz",
  async (moduleId: string, { rejectWithValue }) => {
    try {
      return await getModuleQuiz(moduleId);
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data.message || "Fetch Module Quiz failed");
      }
      return rejectWithValue(`An error occurred: ${error}`);
    }
  }
);


export const fetchQuizQuestionsThunk = createAsyncThunk(
  "adminCourse/fetchQuizQuestions",
  async (quizId: string, { rejectWithValue }) => {
    try {
      return await getQuizQuestions(quizId);
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data.message || "Fetch Questions failed");
      }
      return rejectWithValue(`An error occurred: ${error}`);
    }
  }
);

export const createQuizQuestionThunk = createAsyncThunk(
  "adminCourse/createQuizQuestion",
  async ({ quizId, questionData }: { quizId: string; questionData: Omit<IQuizQuestion, 'id' | 'quizId'> }, { rejectWithValue }) => {
    try {
      return await createQuizQuestionApi(quizId, questionData);
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data.message || "Create Question failed");
      }
      return rejectWithValue(`An error occurred: ${error}`);
    }
  }
);

export const updateQuizQuestionThunk = createAsyncThunk(
  "adminCourse/updateQuizQuestion",
  async ({ quizId, questionId, questionData }: { quizId: string; questionId: string; questionData: Partial<IQuizQuestion> }, { rejectWithValue }) => {
    try {
      return await updateQuizQuestionApi(quizId, questionId, questionData);
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data.message || "Update Question failed");
      }
      return rejectWithValue(`An error occurred: ${error}`);
    }
  }
);

export const deleteQuizQuestionThunk = createAsyncThunk(
  "adminCourse/deleteQuizQuestion",
  async ({ quizId, questionId }: { quizId: string; questionId: string }, { rejectWithValue }) => {
    try {
      await deleteQuizQuestionApi(quizId, questionId);
      return { questionId }; // Return the ID for the reducer to remove from state
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data.message || "Delete Question failed");
      }
      return rejectWithValue(`An error occurred: ${error}`);
    }
  }
);
