import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getEnrollableCourseById } from "./enrollmentActions";
import { IEnrollmentState, IPrerequisitesCheck } from "./enrollmentTypes";
import { ICourse } from "@/features/admin/course/adminCourseTypes";

const initialState: IEnrollmentState = {
  currentCourse: null,
  prerequisites: {
    levelTestMet: false,
    previousCourseMet: false
  },
  isLoading: false,
  error: null,
  checkoutUrl: null
};

const enrollmentSlice = createSlice({
  name: "enrollment",
  initialState,
  reducers: {
    resetEnrollment: () => initialState,
    setPrerequisites: (state, action: PayloadAction<IPrerequisitesCheck>) => {
      state.prerequisites = action.payload;
    },
    setCheckoutUrl: (state, action: PayloadAction<string | null>) => {
      state.checkoutUrl = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEnrollableCourseById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(getEnrollableCourseById.fulfilled, (state, action: PayloadAction<ICourse>) => {
        state.isLoading = false;
        state.currentCourse = action.payload;
        state.error = null;
      })
      .addCase(getEnrollableCourseById.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  }
});

export const { resetEnrollment, setPrerequisites, setCheckoutUrl, clearError } = enrollmentSlice.actions;
export default enrollmentSlice.reducer;