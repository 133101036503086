import React, { useState } from "react";
import { Box, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, IconButton } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Outlet } from "react-router-dom";

import ReusableBreadcrumb from "@/components/common/ReusableBreadcrumb";
import CoachHeader from "@/components/coach/CoachHeader";
import CoachSidebar from "@/components/coach/CoachSideBar";

const CoachLayout: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  
  return (
    <Box>
      <CoachHeader onSidebarToggle={onOpen} />
      <Box display="flex">
        {/* Desktop Sidebar */}
        <Box width={isCollapsed ? "60px" : "250px"} bg="orange.600" minH="calc(100vh - 60px)" transition="width 0.3s" position="relative" display={{ base: "none", lg: "block" }}>
          <CoachSidebar isCollapsed={isCollapsed} />
          <IconButton
            size={"sm"}
            colorScheme="orange"
            aria-label="Toggle Sidebar"
            icon={isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            onClick={toggleSidebar}
            position="absolute"
            right="-15px"
            top="20px"
            zIndex="1"
          />
        </Box>

        {/* Main Content Area */}
        <Box flex={1} p={4} width={{ base: "100%", lg: `calc(100% - ${isCollapsed ? "60px" : "250px"})` }}>
          <ReusableBreadcrumb hideBelow="md" rootLabel="Coach" rootPath="/coach" separator=">" mb={4} />
          <Outlet />
        </Box>
      </Box>

      {/* Mobile Drawer */}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Coach Navigation</DrawerHeader>
            <DrawerBody>
              <CoachSidebar onClose={onClose} />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
};

export default CoachLayout;
