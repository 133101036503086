// src/features/auth/authSlice.ts

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AuthState, AuthResponse, RegisterUserResponse } from "./authtypes";
import {
  login,
  logout,
  registerUser,
  verifyEmail,
  resendVerificationCode,
} from "./authActions";

//import { stat } from "fs";

// Define the initial state for the auth slice
// This represents the structure of our auth-related data in the Redux store
const initialState: AuthState = {
  user: null,
  token: null,
  refreshToken: null,
  isLoading: false,
  error: null,
  registeredEmail: null,
  registrationMessage: null,
};

// Create a slice for auth
const authSlice = createSlice({
  name: "auth", // This name will be used as a prefix for action types
  initialState,
  reducers: {
    //clear the auth state 
    clearAuth : (state) => {
      state.isLoading = false;
      state.user = null;
      state.token = null;
      state.refreshToken = null;
    },
    // updates the tokens in the state
    updateTokens: (
      state,
      action: PayloadAction<{ token: string; refreshToken: string }>
    ) => {
      state.token = action.payload.token;
      state.refreshToken = action.payload.refreshToken;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
  // Extra reducers allow the slice to respond to actions defined outside of it
  // In this case, we're handling the async thunks defined in authActions
  extraReducers: (builder) => {
    builder
      // Handle the pending state of the login thunk
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      // Handle the fulfilled state of the login thunk
      .addCase(
        login.fulfilled,
        (state, action: PayloadAction<AuthResponse>) => {
          state.isLoading = false;
          state.user = action.payload.user;
          state.token = action.payload.accessToken;
          state.refreshToken = action.payload.refreshToken;
        }
      )
      // Handle the rejected state of the login thunk
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      // Handle the fulfilled state of the logout thunk
      .addCase(logout.fulfilled, (state) => {
        // Reset the auth state when logout is successful
        state.isLoading = false;
        state.user = null;
        state.token = null;
        state.refreshToken = null;
        console.log('logout fullfilled')
      })
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.registrationMessage = null;
      })
      .addCase(
        registerUser.fulfilled,
        (state, action: PayloadAction<RegisterUserResponse>) => {
          state.isLoading = false;
          state.registrationMessage = action.payload.message;
          state.registeredEmail = action.payload.email;
        }
      )
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
    
      })
      .addCase(verifyEmail.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(verifyEmail.fulfilled, (state) => {
        state.isLoading = false;
        // You might want to update user state here if needed
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })
      .addCase(resendVerificationCode.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(resendVerificationCode.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(resendVerificationCode.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

// Export the action creators
export const { updateTokens, clearError ,clearAuth} = authSlice.actions;

// Export the reducer
export default authSlice.reducer;
