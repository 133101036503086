import React, { useState, useEffect } from 'react';
import { Box, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure,   IconButton } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { Outlet } from 'react-router-dom';
import LearnerHeader from '@/components/learner/LearnerHeader';
import LearnerSidebar from '@/components/learner/LearnerSidebar';
 

const LearnerLayout: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isCollapsed, setIsCollapsed] = useState(false);

  useEffect(() => {
    const handleCollapseSidebar = () => {
      setIsCollapsed(true);
    };

    window.addEventListener('collapseSidebar', handleCollapseSidebar);
    return () => {
      window.removeEventListener('collapseSidebar', handleCollapseSidebar);
    };
  }, []);

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Box>
    <LearnerHeader onSidebarToggle={onOpen} />
    <Box display="flex">
      {/* Desktop Sidebar */}
      <Box 
        width={isCollapsed ? "60px" : "250px"} 
        bg="pink.600" 
        minH="calc(100vh - 60px)" 
        transition="width 0.3s"
        position="relative"
        display={{ base: "none", lg: "block" }}
      >
        <LearnerSidebar isCollapsed={isCollapsed} />
        <IconButton
          size={'sm'}
          bg='pink.600'
          color="white"
          _hover={{ bg: 'pink.700' }}
          aria-label="Toggle Sidebar"
          icon={isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          onClick={toggleSidebar}
          position="absolute"
          right="-15px"
          top="20px"
          zIndex="1"
        />
      </Box>
      
      {/* Main Content Area */}
      <Box flex={1} width={{ base: "100%", lg: `calc(100% - ${isCollapsed ? "60px" : "250px"})` }} id='boxlayoutoutlet'>
        <Outlet />
      </Box>
    </Box>

    {/* Mobile Drawer */}
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Navigation</DrawerHeader>
          <DrawerBody>
            <LearnerSidebar onClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  </Box>
   
  );
};

export default LearnerLayout;