import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  VStack,
  useToast,
  
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { createTelegramGroupsThunk, fetchTelegramGroupsThunk } from "@/features/admin/course/adminCourseActions";
import { ITelegramGroupRequirement } from "@/features/admin/course/adminCourseTypes";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

interface CreateGroupsModalProps {
  isOpen: boolean;
  onClose: () => void;
  requirements: ITelegramGroupRequirement | null;
  courseId: string;
}

interface FormData {
  groupNames: string[];
}
const schema = yup.object().shape({
  groupNames: yup.array().of(yup.string().trim().min(3, "Group name must be at least 3 characters").required("Group name is required")).required("Group names are required"),
});
const CreateGroupsModal: React.FC<CreateGroupsModalProps> = ({ isOpen, onClose, requirements, courseId }) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const { isLoading } = useAppSelector((state) => state.adminCourse);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(schema),
    mode: "onBlur",
    criteriaMode: "all",
    defaultValues: {
      groupNames: Array(requirements?.requiredGroups || 0).fill(""),
    },
  });

  const onSubmit = async (data: FormData) => {
    try {
      await dispatch(
        createTelegramGroupsThunk({
          courseId,
          groupNames: data.groupNames,
        })
      ).unwrap();

      // Refresh the telegram groups
      await dispatch(fetchTelegramGroupsThunk(courseId));

      toast({
        title: "Groups created successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      reset();
      onClose();
    } catch (error) {
      toast({
        title: "Error creating groups",
        description: error instanceof Error ? error.message : "Failed to create groups",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent as="form" onSubmit={handleSubmit(onSubmit)}>
        <ModalHeader>Create Telegram Groups</ModalHeader>
        <ModalBody>
          <VStack spacing={4}>
            {Array.from({ length: requirements?.requiredGroups || 0 }).map((_, index) => (
              <FormControl key={index} isInvalid={!!errors.groupNames?.[index]}>
                <FormLabel>Group {index + 1} Name</FormLabel>
                <Input
                  {...register(`groupNames.${index}`, {
                    required: "Group name is required",
                    minLength: {
                      value: 3,
                      message: "Group name must be at least 3 characters",
                    },
                  })}
                  placeholder={`Enter name for group ${index + 1}`}
                />
                <FormErrorMessage>{errors.groupNames?.[index]?.message}</FormErrorMessage>
              </FormControl>
            ))}
          </VStack>
        </ModalBody>
        <ModalFooter gap={3}>
          <Button onClick={onClose}>Cancel</Button>
          <Button colorScheme="blue" isLoading={isLoading} onClick={handleSubmit(onSubmit)}>
            Create Groups
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateGroupsModal;
