import React, { useEffect }   from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/store/store";
import { Box, Button, Card, CardBody, CardHeader, Text, useToast } from "@chakra-ui/react";
import { createQuizThunk, fetchModuleQuizThunk } from "@/features/admin/course/adminCourseActions";
import Quiz_Questions from "./Quiz_Questions";

interface QuizProps {
  moduleId: string;
}

const Quiz: React.FC<QuizProps> = ({ moduleId }) => {
  const dispatch = useDispatch<AppDispatch>();
  const toast = useToast();
  const currentQuiz = useSelector((state: RootState) => state.adminCourse.currentQuiz);

  useEffect(() => {
    dispatch(fetchModuleQuizThunk(moduleId));
  }, [dispatch, moduleId]);

  const handleCreateQuiz = async () => {
    try {
      await dispatch(createQuizThunk(moduleId)).unwrap();
      toast({
        title: "Quiz created successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error creating quiz",
        description: (error as Error).message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Card>
      <CardHeader bg="blue.500" color="white">
        <Text>Module Quiz</Text>
      </CardHeader>
      <CardBody>
        {currentQuiz ? (
          <Quiz_Questions quizId={currentQuiz.id} />
        ) : (
          <Box textAlign="center" py={4}>
            <Text mb={4}>No quiz found for this module.</Text>
            <Button colorScheme="blue" onClick={handleCreateQuiz}>
              Create Quiz
            </Button>
          </Box>
        )}
      </CardBody>
    </Card>
  );
};

export default Quiz; 