// src/routes/index.tsx
import { createBrowserRouter, Navigate } from "react-router-dom";
import { useAppSelector } from "@/hooks/useRedux";
import { isAuthenticated, hasRole } from "@/features/auth/authUtils";

// Import layouts
import PublicLayout from "@/layouts/PublicLayout";
import LearnerLayout from "@/layouts/LearnerLayout";
import AdminLayout from "@/layouts/AdminLayout";

// Import pages

//public
import Home from "@/pages/public/Home";
import About from "@/pages/public/About";
import Contact from "@/pages/public/Contact";

import HowItWorks from "@/pages/public/HowItWorks";
import CourseDetails from "@/pages/public/CourseDetails";

import NotFound from "@/pages/NotFound";

//auth
import LoginPage from "@/pages/auth/LoginPage";
import Register from "@/pages/auth/RegisterPage";
import EmailVerification from "@/pages/auth/VerifyEmail"; // Add this import

//learner
import LearnerCourses from "@/pages/learner/Courses";
import LearnerDashboard from "@/pages/learner/Dashboard";

//admin
import AdminDashboard from "@/pages/admin/Dashboard";
import FAQ from "@/pages/public/Faq";
import Pricing from "@/pages/public/Pricing";
import ContentCreators from "@/pages/public/ContentCreators";
import PrivacyTerms from "@/pages/public/Privacyterms";
import Imprint from "@/pages/public/Imprint";
import RequestPasswordResetPage from "./pages/auth/RequestPasswordResetPage";
import ResetPasswordPage from "./pages/auth/ResetPasswordPage";
import LevelTestScreen from "./components/learner/leveltest/LevelTestScreen";

import AdminLeveltestPage from "./pages/admin/LevelTests/AdminLevelTestPage";
import EditLevelTestPage from "./pages/admin/LevelTests/EditLevelTestPage";
import ProfilePage from "./pages/learner/Profile";
import UsersPage from "./pages/admin/Users/UsersPage";

import CourseTypePage from "./pages/admin/Courses/CourseTypesPage";
import AdminCourseDashboardPage from "./pages/admin/Courses/AdminCourseDashboardPage";

import ModuleListPage from "./pages/admin/Courses/ModuleListPage";
import ModuleForm from "./components/admin/courses/ModuleForm";
import CoachesPage from "./pages/admin/Users/CoachesPage";
import CourseListPage from "./pages/admin/Courses/CourseListPage";
import CourseForm from "./components/admin/courses/courseForm/CourseForm";
import StatisticPage from "./pages/admin/Stats/StatisticPage";
import Unauthorized from "./pages/Unauthorized";
import CoachLayout from "./layouts/CaochLayout";
import CoachDashboard from "./pages/coach/CoachDashboard";
import EnrollmentPaymentResult from "./components/learner/enrollment/EnrollmentPaymentResult";
import CourseEnrollmentPage from "./pages/learner/CourseEnrollmentPage";
import { MainCoursePage } from "./pages/learner/MainCoursePage";

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const authState = useAppSelector((state) => state.auth);
  return isAuthenticated(authState) ? <>{children}</> : <Navigate to="/auth/login" replace />;
};

const RoleRoute = ({ children, role }: { children: React.ReactNode; role: "learner" | "coach" | "admin" }) => {
  const authState = useAppSelector((state) => state.auth);

  if (!isAuthenticated(authState)) {
    // Authentication failed - redirect to login
    return <Navigate to="/login" replace />;
  }
  if (!hasRole(authState, role)) {
    // Authorization failed - redirect to unauthorized
    return <Navigate to="/unauthorized" replace />;
  }

  // Both checks passed
  return <>{children}</>;

  //return isAuthenticated(authState) && hasRole(authState, role) ? <>{children}</> : <Navigate to="/unauthorized" replace />;
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: <PublicLayout />,
    children: [
      { index: true, element: <Home /> },
      { path: "about", element: <About /> },
      { path: "contact", element: <Contact /> },
      { path: "courses", element: <CourseDetails /> },
      { path: "pricing", element: <Pricing /> },

      { path: "faq", element: <FAQ /> },
      { path: "howitworks", element: <HowItWorks /> },
      { path: "creators", element: <ContentCreators /> },
      { path: "privacy", element: <PrivacyTerms /> },
      { path: "imprint", element: <Imprint /> },
    ],
  },
  {
    path: "auth",
    element: <PublicLayout />,
    children: [
      { path: "login", element: <LoginPage /> },
      { path: "register", element: <Register /> },
      { path: "verify-email", element: <EmailVerification /> },
      { path: "request-password-reset", element: <RequestPasswordResetPage /> },
      { path: "reset-password", element: <ResetPasswordPage /> },
    ],
  },
  {
    path: "learner",
    element: (
      <ProtectedRoute>
        <RoleRoute role="learner">
          <LearnerLayout />
        </RoleRoute>
      </ProtectedRoute>
    ),
    children: [
      { index: true, element: <LearnerDashboard /> },
      { path: "profile", element: <ProfilePage /> },

      { path: "courses", element: <LearnerCourses /> },
      { path: "course/:courseId", element: <MainCoursePage /> },
      { path: "takeleveltest", element: <LevelTestScreen /> },
      {
        path: "enroll/:courseId",
        element: <CourseEnrollmentPage />,
      },
      {
        path: "enrollment/success",
        element: <EnrollmentPaymentResult />,
      },
      {
        path: "enrollment/cancel",
        element: <Navigate to="/learner/dashboard" replace />,
      },
    ],
  },
  {
    path: "coach",
    element: (
      <ProtectedRoute>
        <RoleRoute role="coach">
          <CoachLayout />
        </RoleRoute>
      </ProtectedRoute>
    ),
    children: [
      { index: true, element: <CoachDashboard /> },
      { path: "profile", element: <ProfilePage /> },
    ],
  },
  {
    path: "admin",
    element: (
      <ProtectedRoute>
        <RoleRoute role="admin">
          <AdminLayout />
        </RoleRoute>
      </ProtectedRoute>
    ),
    children: [
      { index: true, element: <AdminDashboard /> },
      { path: "courses", element: <AdminCourseDashboardPage /> },
      { path: "courses/types", element: <CourseTypePage /> },
      { path: "courses/modules", element: <ModuleListPage /> },
      { path: "courses/modules/:courseTypeId/:modeParam/:moduleId", element: <ModuleForm /> },
      { path: "courses/courselist", element: <CourseListPage /> },
      { path: "courses/courseform/:modeParam/:courseId", element: <CourseForm /> },
      { path: "coaches", element: <CoachesPage /> },
      { path: "level-tests", element: <AdminLeveltestPage /> },
      { path: "level-tests/edit/:testId", element: <EditLevelTestPage /> },
      { path: "users", element: <UsersPage /> },
      { path: "profile", element: <ProfilePage /> },
      { path: "statistics", element: <StatisticPage /> },

      // Add more admin routes here as needed
    ],
  },
  {
    path: "unauthorized",
    element: <Unauthorized />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);
 