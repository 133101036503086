import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDashboardResponse, ILearnerDashboardState } from "./learnerDashboardTypes";
import { fetchDashboardData } from "./learnerDashboardActions";

const initialState: ILearnerDashboardState = {
  isLoading: false,
  error: null,
  enrollableCourses: [],
  enrolledCourses: [],
  resources: [],
  telegramGroup: null,
  incomingZoomSessions: [],
  learnerAnalytics: null,
};

const learnerDahsboardSlice = createSlice({
  name: "learnerDashboard",
  initialState,
  reducers: {
    clearError: (state) => {
      state.error = null;
    },
    resetDashboard: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDashboardData.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchDashboardData.fulfilled, (state, action: PayloadAction<IDashboardResponse>) => {
        state.isLoading = false;
        state.enrollableCourses = action.payload.enrollableCourses;
        state.enrolledCourses = action.payload.enrolledCourses;
        state.resources = action.payload.resources;
        state.learnerAnalytics = action.payload.learnerAnalytics;
        state.telegramGroup = action.payload.telegramGroup;
        state.incomingZoomSessions = action.payload.incomingZoomSessions;

        state.error = null;
      })
      .addCase(fetchDashboardData.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { clearError, resetDashboard } = learnerDahsboardSlice.actions;
export default learnerDahsboardSlice.reducer;
