import { useState } from "react";
import { Card, CardHeader, CardBody, Text, Link, VStack, HStack, Icon, Badge, Container, Box, Grid, GridItem } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@chakra-ui/icons";
import { Module, ModuleDocument } from "@/features/learner/course/courseTypes";
import VimeoPlayer from "@/components/common/VimeoPlayer";
import { ImFilePdf } from "react-icons/im";
import { extractVimeoVideoId } from "@/utils/helpers";
import ModuleQuiz from "./ModuleQuiz";
import { useSelector } from "react-redux";
import { RootState } from "@/store/store";

interface ModuleComponentProps {
  module: Module;
}

export const ModuleComponent: React.FC<ModuleComponentProps> = ({ module }) => {
  const vimeoId = module.video?.source ? extractVimeoVideoId(module.video.source) : null;
  const userId = useSelector((state: RootState) => state.auth.user?.id);
  const [isMetaExpanded, setIsMetaExpanded] = useState(false);

  return (
    <Container maxW="container.xl" p={2} pb={16}>
      <VStack spacing={2} align="stretch">
        <Card>
          <CardHeader bg="purple.600" color="white" cursor="pointer" onClick={() => setIsMetaExpanded(!isMetaExpanded)}>
            <HStack justify="space-between">
              <Text fontSize="xl">
                Module {module.order} - {module.name}
              </Text>
              <HStack>
                <Badge colorScheme="green" fontSize="md">
                  In Progress
                </Badge>
                <Icon as={isMetaExpanded ? ChevronUpIcon : ChevronDownIcon} />
              </HStack>
            </HStack>
          </CardHeader>
          {isMetaExpanded && (
            <CardBody>
              <Text fontSize="md" color="gray.600" fontStyle="italic" mb={4}>
                {module.description}
              </Text>
            </CardBody>
          )}
        </Card>

        <Grid templateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={3}>
          {module.video && vimeoId && (
            <GridItem>
              <Card>
                <CardHeader bg="orange.500" color="white">
                  <Text fontSize="lg">{module.video.title}</Text>
                </CardHeader>
                <CardBody>
                  <VStack spacing={4}>
                    <Box w="100%" position="relative" pb="56.25%">
                      <Box position="absolute" top="0" left="0" width="100%" height="100%">
                        <VimeoPlayer videoId={vimeoId} autoplay={false} />
                      </Box>
                    </Box>
                    <Box maxH="100px" overflowY="auto" w="100%">
                      <Text color="gray.600">{module.video.description}</Text>
                    </Box>
                  </VStack>
                </CardBody>
              </Card>
            </GridItem>
          )}

          {module.docs && module.docs.length > 0 && (
            <GridItem>
              <Card maxH="400px" overflowY="auto">
                <CardHeader bg="pink.500" color="white" position="sticky" top={0} zIndex={1}>
                  <Text fontSize="lg">Module Documents</Text>
                </CardHeader>
                <CardBody>
                  <VStack spacing={4}>
                    {module.docs.map((doc: ModuleDocument, index: number) => (
                      <Card key={index} variant="outline" w="full" _hover={{ shadow: "md" }}>
                        <CardBody>
                          <HStack spacing={4}>
                            <Icon as={ImFilePdf} boxSize={8} color="red.500" />
                            <VStack align="start" spacing={1}>
                              <Text fontWeight="bold" noOfLines={1}>
                                {doc.title}
                              </Text>
                              <Text fontSize="sm" color="gray.600" noOfLines={2}>
                                {doc.description}
                              </Text>
                              <Link href={doc.source} isExternal color="blue.500" fontSize="sm">
                                View Document
                              </Link>
                            </VStack>
                          </HStack>
                        </CardBody>
                      </Card>
                    ))}
                  </VStack>
                </CardBody>
              </Card>
            </GridItem>
          )}
        </Grid>

        {module.quiz && userId && (
          <Card mb={8}>
            <CardHeader bg="green.500" color="white">
              <Text fontSize="lg">Module Quiz</Text>
            </CardHeader>
            <CardBody pb={6}>
              <ModuleQuiz quiz={module.quiz} userId={userId} />
            </CardBody>
          </Card>
        )}
      </VStack>
    </Container>
  );
};
