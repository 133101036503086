import { enumCourseStatus, ICourseType } from "@/features/admin/course/adminCourseTypes";
import { CourseFormData } from "./courseFormConstants";
import { courseSchema } from "./courseFormConstants";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  Text,
  Grid,
  GridItem,
  Heading,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useToast,
  CardFooter,
  Tag,
  TagLeftIcon,
  TagLabel,
  Flex,
} from "@chakra-ui/react";
import { ICoachUser } from "@/features/admin/coaches/adminCoachTypes";
import { useEffect, useRef, useState } from "react";
import { formMode } from "@/types/globalTypes";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createCourseThunk, updateCourseThunk } from "@/features/admin/course/adminCourseActions";
import { unwrapResult } from "@reduxjs/toolkit";
import BasicInfoSection from "./sections/BasicInfoSection";
import { getCourseTypes } from "@/features/admin/course/adminCourseApi";
import { getCoachList } from "@/features/admin/coaches/adminCoachApi";
import StatusSection from "./sections/StatusSection";
import { formatDateForInput } from "@/utils/helpers";
import EnrollmentSection from "./sections/EnrollmentSection";
import InstructorsSection from "./sections/InstructorsSection";
import PrerequisitesSection from "./sections/PrerequisitesSection";
import ScheduleSection from "./sections/ScheduleSection";
import ZoomSessionSection from "./sections/ZoomSessionSection";
import EnrollmentStatus from "./sections/EnrollmentStatus";
import TelegramGroupSection from "./sections/TelegramGroupSection/TelegramGroupSection";
import { FaCalendarAlt, FaEuroSign, FaHourglassHalf, FaToggleOn, FaUsers } from "react-icons/fa";

const CourseForm: React.FC = () => {
  // Router hooks
  const { courseId, modeParam } = useParams<{ courseId: string; modeParam: string }>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const toast = useToast();

  // Local state for dropdowns
  const [courseTypes, setCourseTypes] = useState<ICourseType[]>([]);
  const [coaches, setCoaches] = useState<ICoachUser[]>([]);

  // Redux state
  const { currentCourse, isLoading } = useAppSelector((state) => state.adminCourse);

  // Convert route param to form mode
  const mode = parseInt(modeParam || "0", 10) as formMode;

  // Use a ref to track if initial data is loaded
  const dataLoadedRef = useRef({
    dropdowns: false,
    initialPopulation: false,
  });

  // Load dropdown data
  useEffect(() => {
    const loadFormData = async () => {
      try {
        const [typesData, coachesData] = await Promise.all([getCourseTypes(), getCoachList(true)]);
        setCourseTypes(typesData);
        setCoaches(coachesData);
        dataLoadedRef.current.dropdowns = true;
        //console.log("Dropdowns loaded:", typesData, coachesData);
      } catch (error) {
        toast({
          title: "Error loading form data",
          description: error instanceof Error ? error.message : "Failed to load form data",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    };

    if (!dataLoadedRef.current.dropdowns) {
      loadFormData();
    }
  }, [toast]);

  // Form setup
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    control,
    watch,
  } = useForm<CourseFormData>({
    resolver: yupResolver(courseSchema),
    mode: "onBlur",
    defaultValues: {
      prerequisites: {}, // Provide empty object as default
      isActive: true, // Provide default for boolean
    },
  });

  useEffect(() => {
    if ((mode === formMode.EDIT || mode === formMode.VIEW) && !dataLoadedRef.current.initialPopulation && currentCourse && courseTypes.length > 0 && coaches.length > 0) {
      // Set all values at once
      const formValues = {
        name: currentCourse.name,
        description: currentCourse.description,
        typeId: currentCourse.typeId,
        startDate: formatDateForInput(currentCourse.startDate), //.toISOString().split('T')[0],
        endDate: formatDateForInput(currentCourse.endDate), //.toISOString().split('T')[0],
        primaryCoachId: currentCourse.primaryCoachId,
        secondaryCoachId: currentCourse.secondaryCoachId,
        price: currentCourse.price,
        maxEnrollments: currentCourse.maxEnrollments,
        moduleDuration: currentCourse.moduleDuration,
        prerequisites: currentCourse.prerequisites || {},
        zoomTimes: currentCourse.zoomTimes,
        zoomLink: currentCourse.zoomLink,
        isActive: currentCourse.isActive,
      };

      // Set each form field
      Object.entries(formValues).forEach(([key, value]) => {
        setValue(key as keyof CourseFormData, value);
      });

      dataLoadedRef.current.initialPopulation = true;
    }
  }, [currentCourse, mode, setValue, courseTypes.length, coaches.length]);

  const onSubmit = async (data: CourseFormData) => {
    try {
      let resultAction;

      if (mode === formMode.CREATE) {
        resultAction = await dispatch(
          createCourseThunk({
            ...data,
            startDate: new Date(data.startDate),
            endDate: new Date(data.endDate),
            id: "",
            currentEnrollments: 0,
            status: enumCourseStatus.Upcoming,
            isClosedToEnrolments: false,
          })
        );
      } else if (mode === formMode.EDIT && courseId && currentCourse) {
        resultAction = await dispatch(
          updateCourseThunk({
            ...data,
            startDate: new Date(data.startDate),
            endDate: new Date(data.endDate),
            id: courseId,
            currentEnrollments: currentCourse.currentEnrollments || 0,
            status: currentCourse?.status || enumCourseStatus.Upcoming,
            isClosedToEnrolments: currentCourse.isClosedToEnrolments,
          })
        );
      }

      unwrapResult(resultAction!);

      toast({
        title: `Course ${mode === formMode.CREATE ? "created" : "updated"} successfully`,
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      navigate("/admin/courses/courselist");
    } catch (error) {
      toast({
        title: "Operation failed",
        description: error instanceof Error ? error.message : (error as string),
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleCancel = () => {
    navigate("/admin/courses/courselist");
  };

  const currentTypeId = watch("typeId");

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={6}>
          {/* Header Card */}
          <Card>
            <CardHeader bg="purple.500" py={4}>
              <Heading size="md" color="white">
                {mode === formMode.CREATE ? "Create New Course" : mode === formMode.EDIT ? "Edit Course" : "View Course"}
              </Heading>
            </CardHeader>
            <Grid templateColumns="repeat(12, 1fr)" gap={6} padding={6}>
              <GridItem colSpan={{ base: 12, md: 8 }}>
                <BasicInfoSection register={register} mode={mode} errors={errors} courseTypes={courseTypes} />
              </GridItem>

              {/* Actions Sections */}
              <GridItem colSpan={{ base: 12, md: 4 }}>
                <Card>
                  <CardHeader bg="pink.500" py={3}>
                    <Heading size="sm" color="white">
                      Actions
                    </Heading>
                  </CardHeader>
                  <CardBody>
                    <Tabs variant={"enclosed-colored"} colorScheme="purple">
                      <TabList>
                        <Tab>Information</Tab>
                        <Tab>Enrollment Status</Tab>
                        <Tab>Activation</Tab>
                      </TabList>

                      <TabPanels>
                        <TabPanel>
                          {currentCourse && (
                            <Flex gap={4} wrap={"wrap"}>
                              <Tag
                                key={"status"}
                                size={"lg"}
                                colorScheme={currentCourse.status === enumCourseStatus.Completed ? "gray" : currentCourse.status === enumCourseStatus.Ongoing ? "orange" : "blue"}
                              >
                                <TagLeftIcon boxSize="16px" as={FaHourglassHalf} />
                                <TagLabel>{currentCourse.status.toLocaleUpperCase()}</TagLabel>
                              </Tag>
                              <Tag key={"startdate"} size={"lg"} colorScheme="purple">
                                <TagLeftIcon boxSize="16px" as={FaCalendarAlt} />
                                <TagLabel>
                                  {new Date(currentCourse.startDate).toLocaleDateString()} - {new Date(currentCourse.endDate).toLocaleDateString()}
                                </TagLabel>
                              </Tag>
                              <Tag key={"active"} size={"lg"} colorScheme={currentCourse.isActive ? "green" : "red"}>
                                <TagLeftIcon boxSize="16px" as={FaToggleOn} />
                                <TagLabel>{currentCourse.isActive ? "Active" : "Deleted"}</TagLabel>
                              </Tag>
                              <Tag key={"price"} size={"lg"} colorScheme={"teal"}>
                                <TagLeftIcon boxSize="16px" as={FaEuroSign} />
                                <TagLabel>{currentCourse.price}</TagLabel>
                              </Tag>
                              <Tag key={"enrollments"} size={"lg"} colorScheme={"orange"}>
                                <TagLeftIcon boxSize="16px" as={FaUsers} />
                                <TagLabel>{currentCourse.currentEnrollments}</TagLabel>
                              </Tag>
                            </Flex>
                          )}
                        </TabPanel>
                        <TabPanel>
                          {currentCourse && mode === formMode.EDIT ? (
                            <EnrollmentStatus currentCourse={currentCourse} />
                          ) : (
                            <Text>You can set the enrollment status only in edit mode</Text>
                          )}
                        </TabPanel>
                        <TabPanel>
                          {currentCourse && mode === formMode.EDIT ? <StatusSection currentCourse={currentCourse} /> : <Text>You can set the status only in edit mode</Text>}
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </CardBody>
                  <CardFooter>
                    {mode !== formMode.VIEW && (
                      <ButtonGroup width="100%" spacing={4}>
                        <Button onClick={handleCancel} width="50%" colorScheme="gray">
                          Cancel
                        </Button>
                        <Button type="submit" width="50%" colorScheme="purple" isLoading={isLoading}>
                          {mode === formMode.CREATE ? "Create" : "Update"}
                        </Button>
                      </ButtonGroup>
                    )}
                    {mode === formMode.VIEW && (
                      <Button onClick={handleCancel} width="50%" colorScheme="gray">
                        Back to Course List
                      </Button>
                    )}
                  </CardFooter>
                </Card>
              </GridItem>
            </Grid>
            <Tabs variant={"enclosed-colored"} colorScheme="pink">
              <TabList>
                <Tab>Schedule</Tab>
                <Tab>Enrollment</Tab>
                <Tab>Prerequisites</Tab>
                <Tab>Instructors</Tab>
                <Tab>Zoom Session</Tab>
                <Tab>Telegram Groups</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <ScheduleSection register={register} errors={errors} mode={mode} control={control} />
                </TabPanel>
                <TabPanel>
                  <EnrollmentSection control={control} errors={errors} mode={mode} currentCourse={currentCourse} />
                </TabPanel>
                <TabPanel>
                  <PrerequisitesSection register={register} control={control} errors={errors} mode={mode} courseTypes={courseTypes} currentTypeId={currentTypeId} />
                </TabPanel>
                <TabPanel>
                  <InstructorsSection register={register} mode={mode} coaches={coaches} errors={errors} />
                </TabPanel>
                <TabPanel>
                  <ZoomSessionSection register={register} control={control} errors={errors} mode={mode} setValue={setValue} watch={watch} />
                </TabPanel>
                <TabPanel>
                  <TelegramGroupSection mode={mode} currentCourse={currentCourse} />
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Card>
        </Stack>
      </form>
    </Box>
  );
};

export default CourseForm;
