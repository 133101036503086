import React, { useEffect, useState } from "react";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { IModule, IModuleDocument, IModuleVideo } from "@/features/admin/course/adminCourseTypes";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
 
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { formMode } from "@/types/globalTypes";
import { AddIcon } from "@chakra-ui/icons";
import { MdCancel, MdClose, MdSave } from "react-icons/md";
import { ImFilePdf } from "react-icons/im";
import { useParams } from "react-router-dom";
import { extractVimeoVideoId } from "@/utils/helpers";
import VimeoPlayer from "@/components/common/VimeoPlayer";
import { createModuleThunk, updateModuleThunk } from "@/features/admin/course/adminCourseActions";
import { unwrapResult } from "@reduxjs/toolkit";
import Quiz from "@/components/admin/courses/Quiz";

//Define the form data structure :
export type ModuleFormData = Omit<IModule, "id" | "courseTypeId">;

// Define the validation schema for IModuleDocument
const moduleDocsSchema: yup.ObjectSchema<IModuleDocument> = yup.object().shape({
  title: yup.string().required("Document title is required"),
  description: yup.string().required("Document description is required"),
  source: yup.string().required("Document URL is required"),
});

// Define the validation schema for IModuleVideo
const moduleVideoSchema: yup.ObjectSchema<IModuleVideo> = yup.object().shape({
  title: yup.string().required("Video title is required"),
  description: yup.string().required("Video description is required"),
  source: yup.string().required("Video source is required"),
});

const moduleFormSchema: yup.ObjectSchema<ModuleFormData> = yup.object().shape({
  name: yup.string().min(3, "Module Name must be at least 3 characters").required("Name is required"),
  description: yup.string().required("Description is required"),
  order: yup.number().positive("Order must be a positive number").required("Order is required"),
  video: moduleVideoSchema.required("Video information is required"),
  docs: yup.array().of(moduleDocsSchema).required("Documents is required").min(1, "At least one document item is required"),
});

const CourseTypeForm: React.FC = () => {
  //get router params
  const { courseTypeId, modeParam, moduleId } = useParams<{ courseTypeId: string; modeParam: string; moduleId: string }>();
  //get redux slice states
  const { currentModule, error, isLoading } = useAppSelector((state) => state.adminCourse);
  const dispatch = useAppDispatch();
  //use local state for vimeoId
  const [vimeoId, setVimeoId] = useState<number | null>(null);
  const toast = useToast();

  // Convert the mode string to number,then formMode enum
  const modenum = parseInt(modeParam || "0", 10);
  const mode: formMode = modenum;

  //react hook form  init:
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<ModuleFormData>({
    resolver: yupResolver(moduleFormSchema),
    mode: "onBlur",
    criteriaMode: "all",
  });

  //for module docs dynamic creating :
  const { fields, append, remove } = useFieldArray({
    control,
    name: "docs",
  });

  // to be aware of any changes on docs array in react hook form:
  const docs = useWatch({
    control,
    name: "docs",
  });

  useEffect(() => {
    //set  the react form states to currentModule
    console.log(currentModule, mode);
    if (currentModule && (mode === formMode.EDIT || mode === formMode.VIEW)) {
      setValue("name", currentModule.name);
      setValue("description", currentModule.description);
      setValue("order", currentModule.order);
      setValue("video", currentModule.video);
      setValue("docs", currentModule.docs);
    }
  }, [currentModule, mode, setValue]);

  //url params checks :
  if (modenum < 0 || modenum > 2 || !courseTypeId) {
    return <Box> Bad route parameters !</Box>;
  }

  if (mode !== formMode.CREATE && !moduleId) {
    return <Box> Bad route parameters !</Box>;
  }

  //to show the video preview :
  const onVideoLostFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const videosource = event.target.value;

    const vimeoIdSource = extractVimeoVideoId(videosource);
    // console.log("video source", videosource, "vimeoid", vimeoIdSource);
    setVimeoId(vimeoIdSource);
  };

  const onSubmit = async (data: ModuleFormData) => {
    //console.log(data);
    try {
      let resultAction;

      switch (mode) {
        case formMode.CREATE:
          resultAction = await dispatch(
            createModuleThunk({
              ...data,
              id: "",
              courseTypeId: courseTypeId,
            })
          );
          unwrapResult(resultAction);
          toast({
            title: "Module created.",
            description: "The new module has been successfully created.",
            status: "success",
            duration: 5000,
            isClosable: true,
          });
          break;
        case formMode.EDIT:
          if (courseTypeId && currentModule) {
            resultAction = await dispatch(
              updateModuleThunk({
                ...data,
                id: currentModule.id,
                courseTypeId: courseTypeId,
              })
            );
            unwrapResult(resultAction);
            toast({
              title: "Module updated.",
              description: "The module has been successfully updated.",
              status: "success",
              duration: 5000,
              isClosable: true,
            });
          }
          break;
      }
    } catch (error) {
      toast({
        title: "An error occurred.",
        description: error instanceof Error ? error.message : "Failed to process the module.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    //  onClose(data);
  };

  //to control the add document feature :
  const addNewDocument = () => {
    if (docs && docs.length === 4) {
      toast({
        title: "Document Limit !",
        description: "You can add max 4 Documents to a module",
        status: "error",
        isClosable: true,
      });
      return;
    }
    append({ title: "", description: "", source: "" });
  };

  return (
    <>
      <Box as="form" onSubmit={handleSubmit(onSubmit)}>
        <Tabs>
          <TabList>
            <Tab>Module Information</Tab>
            <Tab>Module Video</Tab>
            <Tab>Quiz</Tab>
            <Tab>Module Documents</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {/* Module Data Area*/}
              <Card>
                <CardHeader bg={"green.600"} color={"white"}>
                  <Heading size="sm">Module Information</Heading>
                </CardHeader>
                <CardBody>
                  
                  <VStack spacing={4} m={5} justify="center" align="center">
                    {/* Module Order No Field*/}
                    <FormControl isInvalid={!!errors.name}>
                      <HStack>
                        <FormLabel flex={1} htmlFor="order">
                          Module Order
                        </FormLabel>
                        <NumberInput flex={2} id="order" variant="flushed" size="md" min={1}  >
                          <NumberInputField {...register("order", { valueAsNumber: true })} />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>
                      </HStack>
                      <FormErrorMessage>{errors.order?.message}</FormErrorMessage>
                    </FormControl>
                    {/* Module Name Field*/}
                    <FormControl isInvalid={!!errors.name}>
                      <HStack>
                        <FormLabel flex={1} htmlFor="name">
                          Module Name
                        </FormLabel>
                        <Input flex={2} id="name" {...register("name")} size={"md"} variant={"outline"} />
                      </HStack>
                      <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                    </FormControl>
                    {/* Module Description Field*/}
                    <FormControl isInvalid={!!errors.description}>
                      <FormLabel htmlFor="name">Description</FormLabel>
                      <Textarea id="description" {...register("description")} size="md" variant="outline" resize="vertical" minHeight="200px" />

                      <FormErrorMessage>{errors.description?.message}</FormErrorMessage>
                    </FormControl>
                    {/* Cancel + Save Buttons : */}
                    <HStack width={"full"} justify={"right"} p={0}>
                      <Button colorScheme="gray" variant={"flushed"} leftIcon={<MdCancel />}>
                        Cancel
                      </Button>
                      {mode !== formMode.VIEW && (
                        <Button type="submit" colorScheme="green" isLoading={isLoading} leftIcon={<MdSave />}>
                          {mode === formMode.CREATE && "Create"}
                          {mode === formMode.EDIT && "Update"}
                        </Button>
                      )}
                    </HStack>
                    {/*Show the state error */}
                    {error && <Text color="red">{error}</Text>}
                  </VStack>
                </CardBody>
              </Card>
            </TabPanel>
            <TabPanel>
              {/* Module Video Area*/}
             
                <Card flex={2} ml={5}  >
                  <CardHeader bg={"orange.500"} color={"white"}>
                    <Heading size="sm">Module Video</Heading>
                  </CardHeader>
                  <CardBody>
                    <VStack>
                      <VStack spacing={4} m={5}  w="full">
                        {/* Video Title Field*/}
                        <FormControl isInvalid={!!errors.video?.title}>
                          <HStack>
                            <FormLabel flex={1} htmlFor="video.title">
                              Video Title
                            </FormLabel>
                            <Input flex={2} id="video.title" {...register("video.title")} size="md" variant="flushed" />
                          </HStack>
                          <FormErrorMessage>{errors.video?.title?.message}</FormErrorMessage>
                        </FormControl>
                        {/* Video Source Field*/}
                        <FormControl isInvalid={!!errors.video?.source}>
                          <HStack>
                            <FormLabel flex={1} htmlFor="video.Source">
                              Video Source
                            </FormLabel>
                            <Input
                              flex={2}
                              id="video.source"
                              {...register("video.source")}
                              onBlur={(e) => {
                                register("video.source").onBlur(e); // Ensure React Hook Form's onBlur is called
                                onVideoLostFocus(e); // Our custom onBlur handler
                              }}
                              size="md"
                              variant="flushed"
                            />
                          </HStack>
                          <FormErrorMessage>{errors.video?.source?.message}</FormErrorMessage>
                        </FormControl>
                        {/* Video Description Field*/}
                        <FormControl isInvalid={!!errors.video?.description}>
                          <FormLabel htmlFor="video.description">Video Description</FormLabel>
                          <Textarea id="video.description" {...register("video.description")} size="md" variant="flushed" resize="vertical" minHeight="80px" />
                          <FormErrorMessage>{errors.video?.description?.message}</FormErrorMessage>
                        </FormControl>
                      </VStack>
                      {/* Video Preview Area */}
                      <Center  w={"full"} border={"1px dotted pink"} bg={"gray.200"} minHeight={"400px"}>
                        {!vimeoId && <Text color={"gray.300"}>Enter a Vimeo Video ID or Url to to preview the video.</Text>}
                        {vimeoId && <VimeoPlayer key={vimeoId ? vimeoId : "key951"} videoId={vimeoId} autoplay={false} />}
                      </Center>
                    </VStack>
                  </CardBody>
                </Card>
              
            </TabPanel>
            <TabPanel>
              {(mode === formMode.EDIT || mode === formMode.VIEW) && moduleId && <Quiz moduleId={moduleId} />}
              {mode === formMode.CREATE && (
                <Box mt={4}>
                  <Alert status="warning">
                    <AlertIcon />
                    <AlertDescription>You will be able to create and modify the quiz after creating the module.</AlertDescription>
                  </Alert>
                </Box>
              )}
            </TabPanel>
            <TabPanel>
              {/* Module Docs Area */}
              <Box mt={2}>
                <Card>
                  <CardHeader bg={"pink.500"} color={"white"}>
                    <Heading size="sm">Module Documents</Heading>
                  </CardHeader>
                  <CardBody>
                    <Flex>
                      {/* Document Creation Boxes && Fields*/}
                      <Flex flex={2} overflowX="auto">
                        {fields.map((field, index) => (
                          <Box key={field.id} position="relative" borderWidth={1} borderRadius="md" p={4} mb={4} ml={4} minW={"250px"}>
                            {mode !== formMode.VIEW && (
                              <IconButton
                                aria-label="Remove content"
                                icon={<MdClose />}
                                onClick={() => remove(index)}
                                colorScheme="red"
                                size="sm"
                                position="absolute"
                                top={2}
                                right={2}
                              />
                            )}

                            <VStack spacing={2} align="stretch">
                              <HStack>
                                {/* Doc Title Field*/}
                                <FormControl isInvalid={!!errors.docs?.[index]?.title}>
                                  <FormLabel htmlFor={`docs.${index}.title`}>Title</FormLabel>
                                  <Input {...register(`docs.${index}.title`)} size="sm" />
                                  <FormErrorMessage>{errors.docs?.[index]?.title?.message}</FormErrorMessage>
                                </FormControl>
                              </HStack>
                              {/* Doc Description Field*/}
                              <FormControl isInvalid={!!errors.docs?.[index]?.description}>
                                <FormLabel htmlFor={`docs.${index}.description`}>Description</FormLabel>
                                <Input {...register(`docs.${index}.description`)} size="sm" />
                                <FormErrorMessage>{errors.docs?.[index]?.description?.message}</FormErrorMessage>
                              </FormControl>
                              {/* Doc Source Field*/}
                              <FormControl isInvalid={!!errors.docs?.[index]?.source}>
                                <FormLabel htmlFor={`docs.${index}.source`}>Source</FormLabel>
                                <Input {...register(`docs.${index}.source`)} size="sm" />
                                <FormErrorMessage>{errors.docs?.[index]?.source?.message}</FormErrorMessage>
                              </FormControl>
                            </VStack>
                          </Box>
                        ))}
                      </Flex>
                      {/* Doc Preview Area*/}
                      <Card flex={1} ml={5} maxHeight="300px">
                        <CardBody overflowY="auto">
                          <HStack spacing={4} align="stretch" wrap={"wrap"}>
                            {docs && docs.length > 0 ? (
                              docs.map((doc, index) => (
                                <Box key={index} p={3} borderWidth={1} borderRadius="md" bg={"gray.200"} maxW={"200px"}>
                                  <HStack spacing={4} align="start">
                                    <ImFilePdf size={56} color="red" />
                                    <VStack align="start" spacing={1} flex={1}>
                                      <Text fontWeight="bold" noOfLines={1}>
                                        {doc.title || "Untitled"}
                                      </Text>
                                      <Text fontSize="sm" noOfLines={1}>
                                        {doc.description || "No description"}
                                      </Text>
                                      <Text fontSize="xs" color="blue.500" noOfLines={1}>
                                        {doc.source || "No source"}
                                      </Text>
                                    </VStack>
                                  </HStack>
                                </Box>
                              ))
                            ) : (
                              <Center>
                                <Text color={"gray.300"}>No documents to preview</Text>
                              </Center>
                            )}
                          </HStack>
                        </CardBody>

                        <CardFooter>
                          {errors.docs?.root?.message && <Text color="red">{errors.docs?.root?.message}</Text>}
                          <Spacer />
                          {mode !== formMode.VIEW && (
                            <Button leftIcon={<AddIcon />} onClick={addNewDocument} colorScheme="blue" size="sm" ml={4}>
                              Add Document
                            </Button>
                          )}
                        </CardFooter>
                      </Card>
                    </Flex>
                  </CardBody>
                </Card>
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </>
  );
};

export default CourseTypeForm;
