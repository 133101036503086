import { IFetchDataOptions, IFetchDataResult } from "@/components/common/ReusableTable/ReusableTable";

export enum enPaginationSide {
  CLIENT,
  SERVER,
}
export type ApiCallFunc<T> = () => Promise<T[] | IFetchDataResult<T>>;

export const fetchData_ReusableTable = async <T>(
  options: IFetchDataOptions,
  getData: ApiCallFunc<T>,
  paginationSide: enPaginationSide = enPaginationSide.CLIENT
): Promise<IFetchDataResult<T>> => {
  try {
    //if succeed return paginated data to grid :
    //check if data is coming from server ( which means it is already paginated):
    if (paginationSide && paginationSide === enPaginationSide.SERVER) {
      const paginatedData_serverside = await getData();
      return paginatedData_serverside as IFetchDataResult<T>;
    } else {
      //apply pagination here :
      const allresults = await getData();
      const dataRows: T[] = allresults as T[];

      const startIndex = options.pageIndex * options.pageSize;
      const endIndex = startIndex + options.pageSize;
      const paginatedData = {
        data: dataRows.slice(startIndex, endIndex),
        totalCount: dataRows.length,
        error: "",
      };
      return paginatedData;
    }
  } catch (error) {
    const errorMessage = (error as Error).message;
    return {
      data: [],
      totalCount: 0,
      error: errorMessage,
    };
  }
};

export function extractVimeoVideoId(input: string): number | null {
  // First, try to parse the input as a number (direct ID input)
  const directId = parseInt(input, 10);
  if (!isNaN(directId) && directId.toString() === input) {
    return directId;
  }

  // If not a direct ID, try to extract ID from URL
  const patterns = [
    /vimeo\.com\/(\d+)/, // https://vimeo.com/123456789
    /vimeo\.com\/.*\/(\d+)/, // https://vimeo.com/channels/staffpicks/123456789
    /vimeo\.com\/video\/(\d+)/, // https://vimeo.com/video/123456789
    /player\.vimeo\.com\/video\/(\d+)/, // https://player.vimeo.com/video/123456789
    /vimeo\.com\/external\/(\d+)/, // https://vimeo.com/external/123456789
  ];

  for (const pattern of patterns) {
    const match = input.match(pattern);
    if (match && match[1]) {
      return parseInt(match[1], 10);
    }
  }

  return null; // If no valid ID found
}


export const formatDateForInput = (date: Date | string): string => {
  const d = typeof date === 'string' ? new Date(date) : date;
  return d.toLocaleDateString('en-CA');
};

export const formatDateForDisplay = (date : Date) : string =>{
  return date.toLocaleString("en-GB", {
    weekday: "short",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
}

export const parseInputDate = (dateString: string): Date => {
  return new Date(dateString);
};