import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { getCourseById, getModuleById } from "./courseApi";
import { Course, Module } from "./courseTypes";

export const fetchCourse = createAsyncThunk<Course, string>(
    "learnerCourse/getCourse",
    async (courseId: string, { rejectWithValue }) => {
        try {
            return await getCourseById(courseId);
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                return rejectWithValue(error.response.data.message || "Failed to fetch course");
            }
            return rejectWithValue(`An error occurred: ${error}`);
        }
    }
);

export const fetchModule = createAsyncThunk<Module, string>(
    "learnerCourse/getModule",
    async (moduleId: string, { rejectWithValue }) => {
        try {
            return await getModuleById(moduleId);
        } catch (error) {
            if (error instanceof AxiosError && error.response) {
                return rejectWithValue(error.response.data.message || "Failed to fetch module");
            }
            return rejectWithValue(`An error occurred: ${error}`);
        }
    }
); 