// src/components/admin/courses/courseForm/sections/TelegramGroupSection/components/ChangeUserGroupDialog.tsx

import React from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Select, FormControl, FormLabel, useToast, Alert, AlertIcon, Text } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { changeUserGroupThunk, fetchGroupMembersThunk } from "@/features/admin/course/adminCourseActions";
import { ITelegramGroupMember } from "@/features/admin/course/adminCourseTypes";

interface ChangeUserGroupDialogProps {
  isOpen: boolean;
  onClose: () => void;
  user: ITelegramGroupMember;
  currentGroupId: string;
}

const ChangeUserGroupDialog: React.FC<ChangeUserGroupDialogProps> = ({ isOpen, onClose, user, currentGroupId }) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [selectedGroupId, setSelectedGroupId] = React.useState("");

  const { telegramGroups, isLoading } = useAppSelector((state) => state.adminCourse);

  const handleChange = async () => {
    if (!selectedGroupId) return;

    try {
      await dispatch(
        changeUserGroupThunk({
          userId: user.id,
          currentGroupId,
          newGroupId: selectedGroupId,
        })
      ).unwrap();

      // Refresh the members list of current group
      await dispatch(fetchGroupMembersThunk(currentGroupId));

      toast({
        title: "User group changed successfully",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      onClose();
    } catch (error) {
      toast({
        title: "Error changing user group",
        description: error instanceof Error ? error.message : "Failed to change user group",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader bg={"orange"}>Change User Group</ModalHeader>
        <ModalBody>
          <Alert status="info" variant="left-accent" mb={5} >
            <AlertIcon />
           
            <Text>User : {user.username}  <br/>
            Score : {user.levelTestResult?.score} </Text>
          </Alert>
          <FormControl>
            <FormLabel>Select New Group</FormLabel>
            <Select value={selectedGroupId} onChange={(e) => setSelectedGroupId(e.target.value)} placeholder="Select new group">
              {telegramGroups
                ?.filter((group) => group.id !== currentGroupId)
                .map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.name} ({group.memberCount} members)
                  </option>
                ))}
            </Select>
          </FormControl>
        </ModalBody>
        <ModalFooter gap={3}>
          <Button onClick={onClose}>Cancel</Button>
          <Button colorScheme="blue" onClick={handleChange} isLoading={isLoading} isDisabled={!selectedGroupId}>
            Change Group
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ChangeUserGroupDialog;
