import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  VStack,
  HStack,
  Heading,
  Text,
  Button,
  Badge,
  SimpleGrid,
  Icon,
  Divider,
  Box,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td
} from '@chakra-ui/react';
import { MdAccessTime, MdGroup, MdCalendarToday } from 'react-icons/md';
import { useAppSelector } from '@/hooks/useRedux';
import { IconType } from 'react-icons';
import { DayOfWeek } from '@/features/admin/course/adminCourseTypes';

interface CourseDetailsProps {
  onNext: () => void;
}

interface DetailItemProps {
  icon: IconType;
  label: string;
  value: string | JSX.Element;
}

const DetailItem = ({ icon, label, value }: DetailItemProps) => (
  <HStack spacing={3} alignItems="flex-start">
    <Icon as={icon} color="purple.500" boxSize={5} mt={1} />
    <Box>
      <Text fontSize="sm" color="gray.600">
        {label}
      </Text>
      {typeof value === 'string' ? (
        <Text fontWeight="medium">{value}</Text>
      ) : (
        value
      )}
    </Box>
  </HStack>
);

/**
 * Formats a time string from hour and minute values
 * @param hour - Hour value (0-23)
 * @param minute - Minute value (0-59)
 * @returns Formatted time string (e.g., "09:00")
 */
const formatTime = (hour: number, minute: number): string => {
  return `${hour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')}`;
};

/**
 * Capitalizes the first letter of a string
 * @param str - String to capitalize
 * @returns Capitalized string
 */
const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

const CourseDetails: React.FC<CourseDetailsProps> = ({ onNext }) => {
  const { currentCourse } = useAppSelector((state) => state.learnerEnrollment);

  if (!currentCourse) return null;

  const formatDate = (date: Date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  // Sort zoom sessions by day of week and time
  const sortedZoomTimes = [...currentCourse.zoomTimes].sort((a, b) => {
    const dayOrder = Object.values(DayOfWeek).indexOf(a.dayOfWeek) - 
                    Object.values(DayOfWeek).indexOf(b.dayOfWeek);
    if (dayOrder !== 0) return dayOrder;
    return (a.hour * 60 + a.minute) - (b.hour * 60 + b.minute);
  });

  const renderZoomSessions = () => (
    <Table variant="simple" size="sm">
      <Thead>
        <Tr>
          <Th>Day</Th>
          <Th>Time</Th>
          <Th>Duration</Th>
        </Tr>
      </Thead>
      <Tbody>
        {sortedZoomTimes.map((session, index) => (
          <Tr key={index}>
            <Td>{capitalizeFirstLetter(session.dayOfWeek)}</Td>
            <Td>{formatTime(session.hour, session.minute)}</Td>
            <Td>{session.duration} minutes</Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  return (
    <Card>
      <CardHeader>
        <Heading size="md">Course Information</Heading>
      </CardHeader>
      <CardBody>
        <VStack align="stretch" spacing={6}>
          <HStack justify="space-between" align="start">
            <VStack align="start" spacing={2}>
              <Heading size="lg">{currentCourse.name}</Heading>
              <Text color="gray.600">{currentCourse.description}</Text>
            </VStack>
            <Badge colorScheme="green" p={2} fontSize="xl">
              ${currentCourse.price}
            </Badge>
          </HStack>

          <Divider />

          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
            <DetailItem
              icon={MdCalendarToday}
              label="Start Date"
              value={formatDate(currentCourse.startDate)}
            />
            <DetailItem
              icon={MdCalendarToday}
              label="End Date"
              value={formatDate(currentCourse.endDate)}
            />
            <DetailItem
              icon={MdAccessTime}
              label="Weekly Sessions"
              value={renderZoomSessions()}
            />
            <DetailItem
              icon={MdGroup}
              label="Class Size"
              value='Max size 25 Student / Telegram group'
            />
           
          </SimpleGrid>

          <Button 
            colorScheme="purple" 
            size="lg" 
            onClick={onNext}
            mt={4}
          >
            Continue to Prerequisites
          </Button>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default CourseDetails;