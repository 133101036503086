import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDashboardData } from './learnerDashboardApi';
import { AxiosError } from 'axios';

export const fetchDashboardData = createAsyncThunk(
  'learnerDashboard/fetchData',
  async (_, { rejectWithValue }) => {
    try {
      return await getDashboardData();
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data.message || 'Failed to fetch dashboard data');
      }
      return rejectWithValue(`An error occurred: ${error}`);
    }
  }
);
