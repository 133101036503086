import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  loginUser,
  register,
  verifyEmailApi,
  resendVerificationCodeApi,
  requestPasswordResetApi,
  resetPasswordApi,
  logoutUser
} from "./authApi";
import {
  LoginCredentials,
  RegisterUserData,
  EmailVerificationData,
} from "./authtypes";
import { AxiosError } from "axios";

// createAsyncThunk is a Redux Toolkit function that simplifies the creation of async action creators
// It automatically generates pending, fulfilled, and rejected action types

// These thunks can be dispatched like regular actions, e.g., dispatch(login(credentials))
// Redux Toolkit will automatically handle the async flow and dispatch the appropriate actions

// This thunk handles the login process
export const login = createAsyncThunk(
  "auth/login", // This string is used to generate action types like auth/login/pending, auth/login/fulfilled, etc.
  // The async function that will be executed when this thunk is dispatched
  async (credentials: LoginCredentials, { rejectWithValue }) => {
   
    try {
      // Attempt to log in the user
      const response = await loginUser(credentials);
      // If successful, return the response (this will be the payload of the fulfilled action)
      return response;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        // If it's an Axios error with a response, return the error message
        return rejectWithValue(error.response.data.message || "Login failed");
      }
      // For any other type of error, return a generic message
      return rejectWithValue(`An error occurred : ${error}`);
    }
  }
);

// This thunk handles the logout process
export const logout = createAsyncThunk(
  "auth/logout",
  // The underscore is used as a placeholder for the first parameter, which we don't need for logout
  async (_, { rejectWithValue }) => {
    try {
      // Attempt to log out the user
     await logoutUser();
     console.log('logout thunk')
      // If successful, the thunk will automatically dispatch a fulfilled action
    } catch (error) {
      // Type assertion to treat the error as an AxiosError
      const err = error as AxiosError<{ message: string }>;
      if (error instanceof AxiosError && error.response) {
        // If it's an Axios error with a response, return the error message
        return rejectWithValue(err.response?.data?.message || "Logout failed");
      }
      // For any other type of error, return a generic message
      return rejectWithValue("An unexpected error occurred");
    }
  }
);

export const registerUser = createAsyncThunk(
  "auth/register",
  async (userData: RegisterUserData, { rejectWithValue }) => {
    try {
      return await register(userData);
    } catch (error) {
      //If the API returns a status code other than 201 (or any 2xx), Axios will throw an error.
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(
          error.response.data.message || "Registration failed"
        );
      }
      return rejectWithValue(`An error occurred: ${error}`);
    }
  }
);

export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async (data: EmailVerificationData, { rejectWithValue }) => {
    try {
      const response = await verifyEmailApi(data);
      return response;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(
          error.response.data.message || "Email verification failed"
        );
      }
      return rejectWithValue("An unexpected error occurred");
    }
  }
);

export const resendVerificationCode = createAsyncThunk(
  "auth/resendVerificationCode",
  async (email: string, { rejectWithValue }) => {
    try {
      const response = await resendVerificationCodeApi(email);
      return response;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(
          error.response.data.message || "Failed to resend verification code"
        );
      }
      return rejectWithValue("An unexpected error occurred");
    }
  }
);

export const requestPasswordReset = createAsyncThunk(
  "auth/requestPasswordReset",
  async (email: string, { rejectWithValue }) => {
    try {
      const message = await requestPasswordResetApi(email);
      return message;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data.message || "Password reset request failed");
      }
      return rejectWithValue(`An error occurred: ${error}`);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async ({ token, newPassword }: { token: string; newPassword: string }, { rejectWithValue }) => {
    try {
      const message = await resetPasswordApi(token, newPassword);
      return message;
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data.message || "Password reset failed");
      }
      return rejectWithValue(`An error occurred: ${error}`);
    }
  }
);