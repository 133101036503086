import React from "react";
import {
  Box,
  Flex,
  Text,
  Spacer,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useBreakpointValue,
  HStack,
  useDisclosure,
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@chakra-ui/react";
import { HamburgerIcon, BellIcon, ChatIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { logout } from "@/features/auth/authActions";
import NotificationList from "@/components/common/NotificationList ";
import MessageList from "@/components/common/MessageList";
import NotificationDialog from "@/components/common/NotificationDialog";
import MessageDialog from "@/components/common/MessageDialog";
import PopoverIconButton from "../common/PopoverIconButton";
import UserProfile from "../common/UserProfile";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import { API_URL } from "@/constants/globalConstants";

interface AdminHeaderProps {
  onSidebarToggle: () => void;
}

const AdminHeader: React.FC<AdminHeaderProps> = ({ onSidebarToggle }) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const unreadMessageCount = 3; // TODO: Replace with actual unread message count
  const unreadNotificationCount = 2; // TODO: Replace with actual unread notification count

  const isMobile = useBreakpointValue({ base: true, md: false });
  const { currentUser } = useCurrentUser();
  const handleLogout = async () => {
    await dispatch(logout());
    navigate("/auth/login");
  };

  const { isOpen: isNotificationOpen, onOpen: onNotificationOpen, onClose: onNotificationClose } = useDisclosure();
  const { isOpen: isMessageOpen, onOpen: onMessageOpen, onClose: onMessageClose } = useDisclosure();

  return (
    <Box bg="purple.700" px={4} py={2}>
      <Flex alignItems="center">
        {isMobile && <IconButton icon={<HamburgerIcon />} variant="outline" colorScheme="whiteAlpha" aria-label="Toggle Sidebar" onClick={onSidebarToggle} mr={4} />}
        <Text color="white" fontWeight="bold" display={{ base: "none", md: "block" }}>
          Admin Dashboard
        </Text>
        <Spacer />
        <HStack spacing={4}>
          <PopoverIconButton icon={<BellIcon />} badgeNumber={unreadNotificationCount} iconColorScheme={"whiteAlpha"} badgeColorScheme={"red"}>
            <NotificationList onItemClick={onNotificationOpen} />
          </PopoverIconButton>
          <PopoverIconButton icon={<ChatIcon />} badgeNumber={unreadMessageCount} iconColorScheme={"whiteAlpha"} badgeColorScheme={"red"}>
            <MessageList onItemClick={onMessageOpen} />
          </PopoverIconButton>
          <HStack>
            <Popover>
              <PopoverTrigger>
                <HStack cursor={"pointer"}>
                  <Avatar size="sm" name={user?.username} src={`${API_URL}/images/${user?.id}.jpg`} />

                  <Text color="white" display={{ base: "none", md: "block" }}>
                    {user?.username}
                  </Text>
                </HStack>
              </PopoverTrigger>
              <PopoverContent>
                <UserProfile user={currentUser} showAdminFileds={false} />
              </PopoverContent>
            </Popover>

            <Menu>
              <MenuButton as={IconButton} icon={<ChevronDownIcon />} variant="ghost" colorScheme="whiteAlpha" />
              <MenuList>
                <MenuItem as={RouterLink} to="/admin/profile">
                  Profile
                </MenuItem>
                <MenuItem as={RouterLink} to="/admin/settings">
                  Settings
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </HStack>
      </Flex>
      <NotificationDialog isOpen={isNotificationOpen} onClose={onNotificationClose} />
      <MessageDialog isOpen={isMessageOpen} onClose={onMessageClose} />
    </Box>
  );
};

export default AdminHeader;
