import React from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider as ReduxProvider } from "react-redux";
import { store } from "./store/store";

import App from "./App";
import theme from "./theme";



ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
   
      <ReduxProvider store={store}>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </ReduxProvider>
   
  </React.StrictMode>
);
