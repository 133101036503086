import React, { useEffect } from 'react';
import { Box, Flex, Spinner, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { fetchCourse, fetchModule } from '../../features/learner/course/courseActions';
import { Module } from '../../features/learner/course/courseTypes';
import { CourseSidebar } from '../../components/learner/course/CourseSidebar';
import { CourseTopBar } from '../../components/learner/course/CourseTopBar';
import { ModuleComponent } from '../../components/learner/course/ModuleComponent';

// Create a custom event to communicate with the layout
const collapseSidebarEvent = new CustomEvent('collapseSidebar');

export const MainCoursePage: React.FC = () => {
    const { courseId } = useParams<{ courseId: string }>();
    const dispatch = useAppDispatch();
    const { currentCourse, currentModule, loading, error } = useAppSelector(
        (state) => state.learnerCourse
    );

    useEffect(() => {
        if (courseId) {
            dispatch(fetchCourse(courseId));
            // Dispatch the event to collapse the sidebar
            window.dispatchEvent(collapseSidebarEvent);
        }
    }, [courseId, dispatch]);

    const handleModuleSelect = (module: Module) => {
        dispatch(fetchModule(module.id));
    };

    if (loading && !currentCourse) {
        return (
            <Flex justify="center" align="center" h="100vh">
                <Spinner />
            </Flex>
        );
    }

    if (error && !currentCourse) {
        return (
            <Flex justify="center" align="center" h="100vh">
                <Text color="red.500">{error || 'Course not found'}</Text>
            </Flex>
        );
    }

    if (!currentCourse) {
        return (
            <Flex justify="center" align="center" h="100vh">
                <Text color="gray.500">Course not found</Text>
            </Flex>
        );
    }

    return (
        <Flex h="calc(100vh - 60px)" direction="column">
            <CourseTopBar course={currentCourse} />
            <Flex flex={1} overflow="hidden">
                <CourseSidebar
                    modules={currentCourse.modules}
                    currentModuleId={currentModule?.id}
                    onModuleSelect={handleModuleSelect}
                />
                <Box flex={1} p={4} overflowY="auto" height="calc(100vh - 100px)">
                    {loading && !currentModule ? (
                        <Flex justify="center" align="center" h="100%">
                            <Spinner />
                        </Flex>
                    ) : currentModule ? (
                        <ModuleComponent module={currentModule} />
                    ) : (
                        <Text color="gray.500">Select a module to view its content</Text>
                    )}
                </Box>
            </Flex>
        </Flex>
    );
};
