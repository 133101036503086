import { createSlice } from '@reduxjs/toolkit';
import { UserQuizState } from './userQuizTypes';
import { fetchUserQuiz, startNewQuiz, submitQuizAnswer } from './userQuizActions';

const initialState: UserQuizState = {
  quiz: null,
  userQuizData: null,
  currentQuestionIndex: 0,
  loading: false,
  error: null,
};

const userQuizSlice = createSlice({
  name: 'userQuiz',
  initialState,
  reducers: {
    resetQuiz: (state) => {
      state.quiz = null;
      state.userQuizData = null;
      state.currentQuestionIndex = 0;
      state.error = null;
    },
    nextQuestion: (state) => {
      state.currentQuestionIndex += 1;
    },
    previousQuestion: (state) => {
      if (state.currentQuestionIndex > 0) {
        state.currentQuestionIndex -= 1;
      }
    },
    setQuestionIndex: (state, action) => {
      state.currentQuestionIndex = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserQuiz.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserQuiz.fulfilled, (state, action) => {
        state.loading = false;
        state.userQuizData = action.payload;
      })
      .addCase(fetchUserQuiz.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch quiz data';
      })
      .addCase(startNewQuiz.fulfilled, (state, action) => {
        state.userQuizData = action.payload;
        state.currentQuestionIndex = 0;
      })
      .addCase(submitQuizAnswer.fulfilled, (state, action) => {
        state.userQuizData = action.payload;
      });
  },
});

export const { resetQuiz, nextQuestion, previousQuestion, setQuestionIndex } = userQuizSlice.actions;
export default userQuizSlice.reducer; 