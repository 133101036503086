import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AdminCoursesState, ICourse, ICourseType, IModule, IQuiz, IQuizQuestion } from "./adminCourseTypes";
import {
  changeUserGroupThunk,
  createCourseThunk,
  createCourseTypeThunk,
  createModuleThunk,
  createTelegramGroupsThunk,
  fetchGroupMembersThunk,
  fetchTelegramGroupRequirementsThunk,
  fetchTelegramGroupsThunk,
  toggleCourseActiveThunk,
  toggleCourseEnrollmentThunk,
  updateCourseThunk,
  updateCourseTypeThunk,
  updateModuleThunk,
  createQuizThunk,
  fetchQuizQuestionsThunk,
  createQuizQuestionThunk,
  updateQuizQuestionThunk,
  deleteQuizQuestionThunk,
  fetchModuleQuizThunk,
} from "./adminCourseActions";
import { IDefaultApiResponse } from "@/types/globalTypes";

const initialState: AdminCoursesState = {
  currentCourseType: null,
  currentModule: null,
  currentCourse: null,
  currentQuiz: null,

  telegramGroups: null,
  telegramGroupRequirements: null,
  selectedGroupMembers: null,
  
  quizQuestions: [],

  lastResponseMessage: "",
  isLoading: false,
  error: null,

 
  
};

const adminCourseSlice = createSlice({
  name: "adminCourse",
  initialState,
  reducers: {
    setCurrentCourseType: (state, action: PayloadAction<ICourseType | null>) => {
      state.error = null;
      state.currentCourseType = action.payload;
    },
    setCurrentModule: (state, action: PayloadAction<IModule | null>) => {
      state.error = null;
      state.currentModule = action.payload;
    },
    setCurrentCourse: (state, action: PayloadAction<ICourse | null>) => {
      state.error = null;
      state.currentCourse = action.payload;
    },
    clearTelegramData: (state) => {
      state.telegramGroups = null;
      state.telegramGroupRequirements = null;
      state.selectedGroupMembers = null;
    },
    clearError: (state) => {
      state.error = null;
    },
  
    
  },
  extraReducers: (builder) => {
    builder
      //Create Course Type
      .addCase(createCourseTypeThunk.pending, (state) => {
        state.lastResponseMessage = "";
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCourseTypeThunk.fulfilled, (state, action: PayloadAction<IDefaultApiResponse>) => {
        state.lastResponseMessage = action.payload.message;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(createCourseTypeThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.lastResponseMessage = "";
        state.error = action.payload as string;
      })
      //Update Course Type
      .addCase(updateCourseTypeThunk.pending, (state) => {
        state.lastResponseMessage = "";
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCourseTypeThunk.fulfilled, (state, action: PayloadAction<IDefaultApiResponse>) => {
        state.lastResponseMessage = action.payload.message;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateCourseTypeThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.lastResponseMessage = "";
        state.error = action.payload as string;
      })
      //Create Module
      .addCase(createModuleThunk.pending, (state) => {
        state.lastResponseMessage = "";
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createModuleThunk.fulfilled, (state, action: PayloadAction<IDefaultApiResponse>) => {
        state.lastResponseMessage = action.payload.message;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(createModuleThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.lastResponseMessage = "";
        state.error = action.payload as string;
      })
      //update Module
      .addCase(updateModuleThunk.pending, (state) => {
        state.lastResponseMessage = "";
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateModuleThunk.fulfilled, (state, action: PayloadAction<IDefaultApiResponse>) => {
        state.lastResponseMessage = action.payload.message;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateModuleThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.lastResponseMessage = "";
        state.error = action.payload as string;
      })
      //Create Course
      .addCase(createCourseThunk.pending, (state) => {
        state.lastResponseMessage = "";
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createCourseThunk.fulfilled, (state, action: PayloadAction<IDefaultApiResponse>) => {
        state.lastResponseMessage = action.payload.message;
        state.isLoading = false;
        state.error = null;
        state.currentCourse = null; // Clear the current course after successful creation
      })
      .addCase(createCourseThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.lastResponseMessage = "";
        state.error = action.payload as string;
      })
      //Update Course
      .addCase(updateCourseThunk.pending, (state) => {
        state.lastResponseMessage = "";
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateCourseThunk.fulfilled, (state, action: PayloadAction<IDefaultApiResponse>) => {
        state.lastResponseMessage = action.payload.message;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateCourseThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.lastResponseMessage = "";
        state.error = action.payload as string;
      })
      //Toggle Course Active
      .addCase(toggleCourseActiveThunk.pending, (state) => {
        state.lastResponseMessage = "";
        state.isLoading = true;
        state.error = null;
      })
      .addCase(toggleCourseActiveThunk.fulfilled, (state, action: PayloadAction<ICourse>) => {
        state.isLoading = false;
        state.error = null;
        state.currentCourse = action.payload;
      })
      .addCase(toggleCourseActiveThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.lastResponseMessage = "";
        state.error = action.payload as string;
      })
      //Toggle Course Enrollment Status
      .addCase(toggleCourseEnrollmentThunk.pending, (state) => {
        state.lastResponseMessage = "";
        state.isLoading = true;
        state.error = null;
      })
      .addCase(toggleCourseEnrollmentThunk.fulfilled, (state, action: PayloadAction<ICourse>) => {
        state.isLoading = false;
        state.error = null;
        state.currentCourse = action.payload;
      })
      .addCase(toggleCourseEnrollmentThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.lastResponseMessage = "";
        state.error = action.payload as string;
      })
      // Telegram Group Requirements

      .addCase(fetchTelegramGroupRequirementsThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTelegramGroupRequirementsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.telegramGroupRequirements = action.payload;
      })
      .addCase(fetchTelegramGroupRequirementsThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })

      // Telegram Groups

      .addCase(fetchTelegramGroupsThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchTelegramGroupsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.telegramGroups = action.payload;
      })
      .addCase(fetchTelegramGroupsThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })

      // Group Members

      .addCase(fetchGroupMembersThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchGroupMembersThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.selectedGroupMembers = action.payload;
      })
      .addCase(fetchGroupMembersThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })

      // Create Telegram Groups

      .addCase(createTelegramGroupsThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createTelegramGroupsThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.telegramGroups = action.payload.groups;
        state.lastResponseMessage = action.payload.message;
      })
      .addCase(createTelegramGroupsThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })

      // Change User Group

      .addCase(changeUserGroupThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(changeUserGroupThunk.fulfilled, (state, action) => {
        state.isLoading = false;
        state.lastResponseMessage = action.payload.message;
      })
      .addCase(changeUserGroupThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })

      // Create Quiz

      .addCase(createQuizThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createQuizThunk.fulfilled, (state, action: PayloadAction<IQuiz>) => {
        state.isLoading = false;
        state.error = null;
        state.currentQuiz = action.payload;
      })
      .addCase(createQuizThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })

      // Fetch Module Quiz

      .addCase(fetchModuleQuizThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchModuleQuizThunk.fulfilled, (state, action: PayloadAction<IQuiz>) => {
        state.isLoading = false;
        state.error = null;
        state.currentQuiz = action.payload;
      })
      .addCase(fetchModuleQuizThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })

      // Fetch Quiz Questions

      .addCase(fetchQuizQuestionsThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchQuizQuestionsThunk.fulfilled, (state, action: PayloadAction<IQuizQuestion[]>) => {
        state.isLoading = false;
        state.error = null;
        state.quizQuestions = action.payload;
      })
      .addCase(fetchQuizQuestionsThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })

      // Create Quiz Question

      .addCase(createQuizQuestionThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(createQuizQuestionThunk.fulfilled, (state, action: PayloadAction<IQuizQuestion>) => {
        state.isLoading = false;
        state.error = null;
        state.quizQuestions.push(action.payload);
      })
      .addCase(createQuizQuestionThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })

      // Update Quiz Question

      .addCase(updateQuizQuestionThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(updateQuizQuestionThunk.fulfilled, (state, action: PayloadAction<IQuizQuestion>) => {
        state.isLoading = false;
        state.error = null;
        const index = state.quizQuestions.findIndex((q) => q.id === action.payload.id);
        if (index !== -1) {
          state.quizQuestions[index] = action.payload;
        }
      })
      .addCase(updateQuizQuestionThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      })

      // Delete Quiz Question

      .addCase(deleteQuizQuestionThunk.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(deleteQuizQuestionThunk.fulfilled, (state, action: PayloadAction<{ questionId: string }>) => {
        state.isLoading = false;
        state.error = null;
        state.quizQuestions = state.quizQuestions.filter((q) => q.id !== action.payload.questionId);
      })
      .addCase(deleteQuizQuestionThunk.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload as string;
      });
  },
});

export const { setCurrentCourseType, setCurrentModule, setCurrentCourse, clearError } =
  adminCourseSlice.actions;

export default adminCourseSlice.reducer;
