import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { createEnrollment, getEnrollableCourseByIdApi, initiatePayment } from "./enrollmentApi";
import { setCheckoutUrl } from "./enrollmentSlice";
import { ICourse } from "@/features/admin/course/adminCourseTypes";

export const getEnrollableCourseById = createAsyncThunk<ICourse, string>(
  "enrollment/getCourse",
  async (courseId: string, { rejectWithValue }) => {
    try {
      return await getEnrollableCourseByIdApi(courseId);
    } catch (error) {
      if (error instanceof AxiosError && error.response) {
        return rejectWithValue(error.response.data.message || "Failed to fetch course");
      }
      return rejectWithValue(`An error occurred: ${error}`);
    }
  }
);

export const startEnrollment = createAsyncThunk(
  "enrollment/start",
  async (courseId: string, { dispatch, rejectWithValue }) => {
    try {
      // First create the enrollment
      const enrollment = await createEnrollment(courseId);
      
      // Then initiate payment
      const { sessionUrl } = await initiatePayment(enrollment.id);
      
      // Store the checkout URL
      dispatch(setCheckoutUrl(sessionUrl));
      
      return sessionUrl;
    } catch (error) {
       
      if (error instanceof AxiosError && error.response) {
       
        return rejectWithValue(error.response.data.message || "Failed to start enrollment");
      }
      return rejectWithValue(`An error occurred: ${error}`);
    }
  }
);