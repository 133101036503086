// src/components/admin/stats/ActiveCoursesList.tsx
import React from "react";
import { Box, Table, Thead, Tbody, Tr, Th, Td, Progress, Card, CardBody, Heading, Skeleton, useToast } from "@chakra-ui/react";
import { ActiveCourse } from "@/features/admin/dashboard/adminDashboardTypes";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "@/hooks/useRedux";
import { getCourseById } from "@/features/admin/course/adminCourseApi";
import { clearError, setCurrentCourse } from "@/features/admin/course/adminCourseSlice";
import { formMode } from "@/types/globalTypes";

interface ActiveCoursesListProps {
  courses: ActiveCourse[];
  isLoading?: boolean;
}

const ActiveCoursesList: React.FC<ActiveCoursesListProps> = ({ courses, isLoading = false }) => {
  // Helper function to format date
  const formatDate = (date: Date): string => {
    return new Date(date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  const navigate = useNavigate();
  const toast = useToast();
  const dispatch = useAppDispatch();

  const handleCourseSelect = async (courseId: string) => {
    try {
      const response = await getCourseById(courseId);
      dispatch(clearError());
      dispatch(setCurrentCourse(response));
      navigate(`/admin/courses/courseform/${formMode.VIEW}/${courseId}`);
    } catch (error) {
      toast({
        title: "Error loading course data",
        description: error instanceof Error ? error.message : "Failed to load course data",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return (
      <Card>
        <CardBody>
          <Heading size="md" mb={4}>
            Active Courses
          </Heading>
          <Box overflowX="auto">
            <Skeleton height="200px" />
          </Box>
        </CardBody>
      </Card>
    );
  }

  return (
    <Card>
      <CardBody>
        <Heading size="md" mb={4}>
          Active Courses
        </Heading>
        <Box overflowX="auto">
          <Table variant="striped" size="sm">
            <Thead>
              <Tr>
                <Th>Course Name</Th>
                <Th>Started On</Th>
                <Th>Will End On</Th>
                <Th>Remaining Days</Th>
                <Th>Progress</Th>
              </Tr>
            </Thead>
            <Tbody>
              {courses.map((course, index) => (
                <Tr key={index}>
                  <Td cursor={'pointer'} _hover={{color:'blue.500'}} onClick={() => handleCourseSelect(course.id)}>{course.name}</Td>
                  <Td>{formatDate(course.startedOn)}</Td>
                  <Td>{formatDate(course.willEndOn)}</Td>
                  <Td>{course.remainingDays} days</Td>
                  <Td>
                    <Progress value={course.progress} size="sm" colorScheme={course.progress < 30 ? "purple" : course.progress < 60 ? "pink" : "green"} />
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      </CardBody>
    </Card>
  );
};

export default ActiveCoursesList;
