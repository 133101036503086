import { Card, CardHeader, CardBody, Text, VStack, HStack, Badge, Skeleton, Progress, Icon, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { MdClass, MdCalendarToday, MdArrowForward } from "react-icons/md";
import { useAppSelector } from "@/hooks/useRedux";
import { enEnrollmentStatus, IEnrolledCourse } from "@/features/learner/dashboard/learnerDashboardTypes";

const EnrolledCourses = () => {
  const { enrolledCourses, isLoading } = useAppSelector((state) => state.learnerDashboard);
  const navigate = useNavigate();

  const handleCourseClick = (courseId: string) => {
    navigate(`/learner/course/${courseId}`);
  };

  // Format date helper
  const formatDate = (dateString: string) => {
    return new Date(dateString).toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  return (
    <Card>
      <CardHeader bg="teal.500" py={3}>
        <Text color="white" fontSize="lg" fontWeight="bold">
          Your Enrolled Courses
        </Text>
      </CardHeader>
      <CardBody>
        <VStack spacing={4} align="stretch">
          {isLoading ? (
            [...Array(2)].map((_, i) => <Skeleton key={i} height="120px" borderRadius="md" />)
          ) : enrolledCourses.length === 0 ? (
            <Card variant="outline" py={6}>
              <VStack spacing={3}>
                <Text color="gray.500" textAlign="center">
                  You are not enrolled in any courses yet.
                </Text>
                <Button size="sm" colorScheme="teal" variant="outline" onClick={() => navigate("/learner/dashboard")}>
                  Browse Available Courses
                </Button>
              </VStack>
            </Card>
          ) : (
            enrolledCourses.map((course: IEnrolledCourse) => (
              <Card key={course.id} variant="outline" _hover={{ shadow: "md", borderColor: "teal.200" }} cursor="pointer" onClick={() => handleCourseClick(course.id)}>
                <CardBody>
                  <VStack align="stretch" spacing={3}>
                    {/* Course Name and Current Module */}
                    <HStack justify="space-between" align="start">
                      <VStack align="start" spacing={1}>
                        <Text fontWeight="bold" fontSize="md">
                          {course.name}
                        </Text>
                        <Badge variant="solid" colorScheme={course.enrollmentStatus === enEnrollmentStatus.Active ? "green" : "red"}>
                          {course.enrollmentStatus}
                        </Badge>

                        <HStack spacing={2}>
                          <Icon as={MdClass} color="teal.500" />
                          <Text fontSize="sm" color="gray.600">
                            Module {course.currentModule.moduleNo}: {course.currentModule.moduleName}
                          </Text>
                        </HStack>
                      </VStack>
                      <Icon as={MdArrowForward} color="teal.500" boxSize={5} />
                    </HStack>

                    {/* Course Timeline */}
                    <VStack align="stretch" spacing={1}>
                      <HStack justify="space-between" fontSize="sm" color="gray.600">
                        <HStack>
                          <Icon as={MdCalendarToday} />
                          <Text>
                            {formatDate(course.startDate)} - {formatDate(course.endDate)}
                          </Text>
                        </HStack>
                        <Badge colorScheme="teal" variant="subtle">
                          Module {course.currentModule.moduleNo} of 8
                        </Badge>
                      </HStack>

                      {/* Progress Bar */}
                      <Progress value={(course.currentModule.moduleNo / 8) * 100} size="sm" colorScheme="teal" borderRadius="full" />
                    </VStack>
                  </VStack>
                </CardBody>
              </Card>
            ))
          )}
        </VStack>
      </CardBody>
    </Card>
  );
};

export default EnrolledCourses;
