import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Heading,
  VStack,
  HStack,
  Button,
  Alert,
  AlertIcon,
  Box,
  Text,
  Divider,
  useToast,
  List,
  ListItem,
  ListIcon,
  Icon,
  Badge,
} from "@chakra-ui/react";
import { MdCheckCircle, MdInfo, MdPayment, MdLock } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { startEnrollment } from "@/features/learner/enrollment/enrollmentActions";

interface PaymentSectionProps {
  onBack: () => void;
}

const PaymentSection: React.FC<PaymentSectionProps> = ({ onBack }) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [isProcessing, setIsProcessing] = useState(false);

  const { currentCourse, error } = useAppSelector((state) => state.learnerEnrollment);

  if (!currentCourse) return null;

  const handlePayment = async () => {
    setIsProcessing(true);
    try {
        // send enrollment create request + initiate payment request ,get the session Url as return : 
      const result = await dispatch(startEnrollment(currentCourse.id)).unwrap();  
      if (result) {
        window.location.href = result; // Redirect to Stripe checkout using session url
      }
    } catch (error) {
      console.log(error)
      toast({
        title: "Payment Error",
        description: error instanceof Error ? error.message : error as string,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Card>
      <CardHeader bg="white" px={6} pt={6} pb={3}>
        <HStack spacing={3}>
          <Icon as={MdPayment} boxSize={6} color="purple.500" />
          <Heading size="md">Complete Your Enrollment</Heading>
        </HStack>
      </CardHeader>

      <CardBody>
        <VStack align="stretch" spacing={6}>
          {/* Course Summary */}
          <Card variant="outline">
            <CardBody>
              <VStack align="stretch" spacing={4}>
                <Heading size="sm">Course Summary</Heading>
                <HStack justify="space-between">
                  <VStack align="start" spacing={1}>
                    <Text fontWeight="medium">{currentCourse.name}</Text>
                    <Text fontSize="sm" color="gray.600">
                      Starts {new Date(currentCourse.startDate).toLocaleDateString()}
                    </Text>
                  </VStack>
                  <Badge colorScheme="green" fontSize="lg" p={2}>
                    ${currentCourse.price}
                  </Badge>
                </HStack>
              </VStack>
            </CardBody>
          </Card>

          {/* What's Included */}
          <Card variant="outline">
            <CardBody>
              <VStack align="stretch" spacing={4}>
                <Heading size="sm">What's Included</Heading>
                <List spacing={3}>
                  <ListItem>
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    {currentCourse.moduleDuration} weeks of structured learning
                  </ListItem>
                  <ListItem>
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    Weekly live sessions with instructor
                  </ListItem>
                  <ListItem>
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    Access to course materials and recordings
                  </ListItem>
                  <ListItem>
                    <ListIcon as={MdCheckCircle} color="green.500" />
                    Course completion certificate
                  </ListItem>
                </List>
              </VStack>
            </CardBody>
          </Card>

          {/* Payment Info */}
          <Card variant="outline">
            <CardBody>
              <VStack align="stretch" spacing={4}>
                <HStack>
                  <Icon as={MdLock} color="green.500" />
                  <Heading size="sm">Secure Payment</Heading>
                </HStack>

                <HStack justify="space-between">
                  <Text>Course Fee</Text>
                  <Text>${currentCourse.price}</Text>
                </HStack>

                <Divider />

                <HStack justify="space-between">
                  <Text fontWeight="bold">Total Payment</Text>
                  <Text fontWeight="bold" fontSize="xl" color="green.500">
                    ${currentCourse.price}
                  </Text>
                </HStack>
              </VStack>
            </CardBody>
          </Card>

          {/* Important Notes */}
          <Alert status="info" variant="left-accent">
            <AlertIcon as={MdInfo} />
            <Box>
              <Text fontWeight="medium">Before You Proceed</Text>
              <Text fontSize="sm">
                • Payment is processed securely through Stripe
                <br />
                • You'll receive instant confirmation via email
                <br />• Cancellation is available up to 24 hours before course start
              </Text>
            </Box>
          </Alert>

          {/* Error Display */}
          {error && (
            <Alert status="error">
              <AlertIcon />
              <Text>{error}</Text>
            </Alert>
          )}

          {/* Action Buttons */}
          <HStack justify="space-between" pt={4}>
            <Button onClick={onBack} isDisabled={isProcessing} variant="outline">
              Back
            </Button>
            <Button colorScheme="purple" onClick={handlePayment} isLoading={isProcessing} loadingText="Processing Payment" leftIcon={<MdLock />}>
              Complete Enrollment
            </Button>
          </HStack>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default PaymentSection;
