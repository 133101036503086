import React, { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Text,
  Box,
  Button,
  Card,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Switch,
  Textarea,
  VStack,
  CardHeader,
  CardBody,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { formMode } from "@/types/globalTypes";
import { ICoach } from "@/features/admin/coaches/adminCoachTypes";
import { MdSearch } from "react-icons/md";
import { getCoachUserThunk } from "@/features/admin/coaches/adminCoachActions";
import { clearCoachUser } from "@/features/admin/coaches/adminCoachSlice";
import ImageUploader from "@/components/common/ImageUploader";
import { IMAGE_TYPES } from "@/types/imageTypes";
//Define the form data structure :
export type CoachFormData = Omit<ICoach, "id">;

// Define the validation schema
const schema = yup.object().shape({
  userId: yup.string().required("User Id is required"),
  profilePhoto: yup.string().optional(),
  personalSummary: yup.string().required("Summary is required"),
  isActive: yup.bool().required("Active Status is required"),
  isNative: yup.bool().required("Native Status is required"),
  isZoomOnly: yup.bool().required("Zoom Only Status is required"),
});

interface Props {
  mode: formMode;
  onClose: (data: CoachFormData) => void;
}
const CoachForm: React.FC<Props> = ({ mode, onClose }) => {
  //redux slice
  const { currentCoach, coachUser, error, isLoading } = useAppSelector((state) => state.adminCouch);
  const dispatch = useAppDispatch();
  //refs :
  const refEmail = useRef<HTMLInputElement>(null);
  const refUserName = useRef<HTMLInputElement>(null);

  //react hook form  init:
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm<CoachFormData>({
    resolver: yupResolver(schema),
    mode: "onBlur",
    criteriaMode: "all",
  });

  useEffect(() => {
    //set  the react form states to currentCourseType
    if (currentCoach && (mode === formMode.EDIT || mode === formMode.VIEW)) {
      setValue("userId", currentCoach.userId);
      setValue("profilePhoto", currentCoach.profilePhoto);
      setValue("personalSummary", currentCoach.personalSummary);
      setValue("isActive", currentCoach.isActive);
      setValue("isNative", currentCoach.isNative);
      setValue("isZoomOnly", currentCoach.isZoomOnly);
      //and show the username , since it is not managed by react hook form , we should do it manually :
      refUserName.current!.value = currentCoach.user.username;
    }
  }, [currentCoach, mode, setValue]);

  useEffect(() => {
    if (mode === formMode.CREATE)
      if (coachUser) {
        //set react hook form state
        setValue("userId", coachUser.id);
        refUserName.current!.value = coachUser.username;
      } else {
        //clear react hook form state
        setValue("userId", "");
        refUserName.current!.value = "";
      }
  }, [coachUser, mode, setValue]);

  // Compute the visibility condition directly
  const shouldShowCoachData = (mode === formMode.CREATE && coachUser) || mode !== formMode.CREATE;

  const onSubmit = async (data: CoachFormData) => {
    dispatch(clearCoachUser());
    onClose(data);
  };

  const handleFindUser = async () => {
    const email = refEmail.current?.value;
    if (email) {
      await dispatch(getCoachUserThunk(email));
    }
  };

  return (
    <>
      <Card>
        <CardHeader bg="pink.400" color="white" p={3}>
          <Text>Coach User</Text>
        </CardHeader>
        <CardBody>
          {
            // if from Mode is create and user is not found yet show the part to find the user by email:
            !shouldShowCoachData && (
              <Box mb={3}>
                <Text mb={3}>To create a coach , first find the user by e-mail , then enter the caoch information, and click to create button.</Text>
                <FormControl isInvalid={!!errors.userId}>
                  <FormLabel htmlFor="email">Find coach user by e-mail : </FormLabel>
                  <HStack>
                    <Input id="email" size={"md"} ref={refEmail} />
                    <Button leftIcon={<MdSearch />} colorScheme="blue" size={"md"} onClick={handleFindUser} isLoading={isLoading}>
                      Get User
                    </Button>
                  </HStack>
                </FormControl>
                <Divider mt={2} color={"gray.300"} />
              </Box>
            )
          }

          <HStack>
            <FormLabel htmlFor="userId" flex={1}>
              User Id
            </FormLabel>
            <Input id="userId" {...register("userId")} size={"md"} bg={"gray.300"} flex={2} disabled />
          </HStack>
          <HStack mt={3}>
            <FormLabel htmlFor="userName" flex={1}>
              User Name :
            </FormLabel>
            <Input id="userName" size={"md"} bg={"gray.300"} flex={2} disabled value={currentCoach?.user.username} ref={refUserName} />
          </HStack>
        </CardBody>
      </Card>
      {
        // In Create mode if user is found then show the caoch data:
        shouldShowCoachData && (
          <Card>
            <CardHeader bg="pink.500" color="white" p={3}>
              <Text>Coach Data</Text>
            </CardHeader>
            <CardBody>
              <Box as="form" onSubmit={handleSubmit(onSubmit)}>
                <VStack spacing={4}>
                  <Tabs variant="enclosed">
                    <TabList>
                      <Tab>Coach Information</Tab>
                      <Tab>Coach Photo</Tab>
                    </TabList>
                    <TabPanels>
                      <TabPanel>
                        <FormControl isInvalid={!!errors.personalSummary}>
                          <FormLabel htmlFor="personalSummary">Personel Summary</FormLabel>
                          <Textarea fontSize={"medium"} id="personalSummary" {...register("personalSummary")} size={"lg"} variant={"flushed"} />
                          <FormErrorMessage>{errors.personalSummary?.message}</FormErrorMessage>
                        </FormControl>

                        <HStack height={"100px"} w={"full"}>
                          <FormControl isInvalid={!!errors.isNative}>
                            <HStack spacing={2}>
                              <FormLabel htmlFor="isNative" flex={5}>
                                Native Speaker?
                              </FormLabel>
                              <Switch id="isNative" {...register("isNative")} colorScheme="purple" flex={1} />
                            </HStack>
                          </FormControl>
                          <Divider orientation="vertical" ml={3} />
                          <FormControl isInvalid={!!errors.isZoomOnly}>
                            <HStack spacing={2}>
                              <FormLabel htmlFor="isZoomOnly" flex={5}>
                                Zoom Only Coach ?
                              </FormLabel>
                              <Switch id="isZoomOnly" {...register("isZoomOnly")} colorScheme="orange" flex={1} />
                            </HStack>
                          </FormControl>
                          <Divider orientation="vertical" ml={3} />

                          <FormControl isInvalid={!!errors.isActive}>
                            <HStack spacing={5}>
                              <FormLabel htmlFor="isActive" flex={4}>
                                Active Coach?
                              </FormLabel>
                              <Switch id="isActive" {...register("isActive")} colorScheme="red" flex={1} />
                            </HStack>
                          </FormControl>
                        </HStack>

                        {mode !== formMode.VIEW && (
                          <Button type="submit" colorScheme="green" width="full" isLoading={isLoading} mt={4}>
                            {mode === formMode.CREATE && "Create"}
                            {mode === formMode.EDIT && "Update"}
                          </Button>
                        )}
                        {/*Show the state error */}
                        {error && <FormErrorMessage>{error}</FormErrorMessage>}
                      </TabPanel>
                      <TabPanel>
                        <FormControl isInvalid={!!errors.profilePhoto}>
                          <HStack>
                            <FormLabel fontSize={"small"} htmlFor="isNative" flex={5}>
                              Uploaded Url : 
                            </FormLabel>
                            <Input fontSize={"small"} id="profilePhoto" {...register("profilePhoto")} size={"lg"} variant={"flushed"} flex={2} disabled />
                          </HStack>
                          
                          <ImageUploader
                            id={currentCoach?.userId as string}
                            type={IMAGE_TYPES.USER}
                            onUploadSuccess={ () => {}}
                            onUploadError={() => {
                              /*do nothing we show an error toast in component already*/
                            }}
                          />
                        </FormControl>
                      </TabPanel>
                    </TabPanels>
                  </Tabs>
                </VStack>
              </Box>
            </CardBody>
          </Card>
        )
      }
    </>
  );
};

export default CoachForm;
