import React from "react";
import {
  Box,
  VStack,
  Text,
  Radio,
  RadioGroup,
  Input,
  Stack,
  Image
} from "@chakra-ui/react";
import { FormData } from "./Quiz_QuestionForm";
import { IMAGE_TYPES } from "@/types/imageTypes";
import Quiz_DragDropPreview from "./Quiz_DragDropPreview";

interface Quiz_QuestionPreviewProps {
  formData: FormData;
}

const Quiz_QuestionPreview: React.FC<Quiz_QuestionPreviewProps> = ({ formData }) => {
  const APP_URL = import.meta.env.VITE_URL?.replace(/\/$/, '') || "https://fluentchatter.com";
  const [displayContent, setDisplayContent] = React.useState(formData.content);

  const handleDragUpdate = (orderedTexts: string[]) => {
    const updatedContent = formData.content + ' ' + orderedTexts.join(' ');
    setDisplayContent(updatedContent);
  };

  return (
    <Box 
      p={4} 
      borderWidth="1px" 
      borderRadius="lg" 
      width="100%" 
      bg="gray.50"
      boxShadow="sm"
    >
      <VStack align="start" spacing={4}>
        <Text fontSize="lg" fontWeight="bold">Question Preview</Text>
        
        {formData.sourceType === "audio" && formData.audioUrl && (
          <Box>
            <audio controls src={formData.audioUrl}>
              Your browser does not support the audio element.
            </audio>
          </Box>
        )}

        {formData.imageUrl && (
          <Box maxW="100%" overflow="hidden">
            <Image 
              src={`${APP_URL}/api/images/${IMAGE_TYPES.QUIZ}/${formData.imageUrl}.jpg`}
              alt="Question image"
              maxH="200px"
              objectFit="contain"
            />
          </Box>
        )}

        <Text>{formData.questionType === "drag-drop" ? displayContent : formData.content}</Text>

        {formData.questionType === "multi-option" && (
          <RadioGroup defaultValue={formData.correctAnswer}>
            <Stack>
              {formData.options?.map((option) => (
                <Radio key={option.id} value={option.id} isReadOnly>
                  {option.text}
                </Radio>
              ))}
            </Stack>
          </RadioGroup>
        )}

        {formData.questionType === "drag-drop" && formData.options && (
          <Box width="100%">
            <Quiz_DragDropPreview 
              options={formData.options} 
              onDragUpdate={handleDragUpdate}
            />
          </Box>
        )}

        {formData.questionType === "fill_blank" && (
          <Input 
            placeholder="Enter answer"
            isReadOnly
            value={formData.correctAnswer}
            width="200px"
          />
        )}

        {formData.questionType === "sentence" && (
          <Input
            placeholder="Type full sentence"
            isReadOnly
            value={formData.correctAnswer}
            width="100%"
          />
        )}
      </VStack>
    </Box>
  );
};

export default Quiz_QuestionPreview; 