import React, { useState, useEffect } from "react";
import { Box, VStack, Radio, RadioGroup, Input, Stack, Image, Text, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, HStack } from "@chakra-ui/react";
import { QuizQuestion as QuizQuestionType } from "@/features/learner/course/courseTypes";
import { IMAGE_TYPES } from "@/types/imageTypes";
import Quiz_DragDropPreview from "../../admin/courses/Quiz_DragDropPreview";
import { useAppDispatch } from "@/hooks/useRedux";
import { submitQuizAnswer } from "@/features/learner/userQuiz/userQuizActions";
import { ArrowRightIcon } from "@chakra-ui/icons";

interface QuizQuestionProps {
  question: QuizQuestionType;
  currentQuestionNumber: number;
  totalQuestions: number;
  isOpen: boolean;
  onClose: () => void;
  onNext: () => void;
  userQuizId: string;
}

const QuizQuestion: React.FC<QuizQuestionProps> = ({ 
  question, 
  currentQuestionNumber, 
  totalQuestions,
  isOpen,
  onClose,
  onNext,
  userQuizId
}) => {
  const dispatch = useAppDispatch();
  const APP_URL = import.meta.env.VITE_URL?.replace(/\/$/, "") || "https://fluentchatter.com";
  const [selectedAnswer, setSelectedAnswer] = useState<string>("");
  const [displayContent, setDisplayContent] = useState<string>("");

  useEffect(() => {
    if (question) {
      setDisplayContent(question.content);
      setSelectedAnswer("");
    }
  }, [question]);

  if (!question) {
    return null;
  }

  const handleAnswerChange = (value: string) => {
    setSelectedAnswer(value);
  };

  const handleDragUpdate = (orderedTexts: string[]) => {
    const updatedContent = question.content + " " + orderedTexts.join(" ");
    setDisplayContent(updatedContent);
    setSelectedAnswer(orderedTexts.join(" "));
  };

  const handleNext = async () => {
    if (selectedAnswer) {
      await dispatch(submitQuizAnswer({ 
        userQuizId: userQuizId, 
        questionId: question.id, 
        answer: selectedAnswer 
      }));
      setSelectedAnswer("");
      onNext();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
      <ModalOverlay />
      <ModalContent maxW="800px">
        <ModalHeader>
          <Text>
            Question {currentQuestionNumber} of {totalQuestions}
          </Text>
        </ModalHeader>
        <ModalBody>
          <VStack align="start" spacing={4} width="100%">
            {question.imageUrl && (
              <Box width="100%" overflow="hidden">
                <Image src={`${APP_URL}/api/images/${IMAGE_TYPES.QUIZ}/${question.imageUrl}.jpg`} alt="Question image" maxH="300px" objectFit="contain" width="100%" />
              </Box>
            )}

            {question.sourceType === "audio" && question.audioUrl && (
              <Box width="100%">
                <audio controls src={question.audioUrl} style={{ width: "100%" }}>
                  Your browser does not support the audio element.
                </audio>
              </Box>
            )}

            <Text fontSize="lg">{question.questionType === "drag-drop" ? displayContent : question.content}</Text>

            {question.questionType === "multi-option" && question.options && (
              <RadioGroup onChange={handleAnswerChange} value={selectedAnswer} width="100%">
                <Stack width="100%">
                  {question.options.map((option) => (
                    <Radio key={option.id} value={option.id} width="100%">
                      {option.text}
                    </Radio>
                  ))}
                </Stack>
              </RadioGroup>
            )}

            {question.questionType === "drag-drop" && question.options && (
              <Box width="100%">
                <Quiz_DragDropPreview options={question.options} onDragUpdate={handleDragUpdate} preview={false} />
              </Box>
            )}

            {question.questionType === "fill_blank" && <Input placeholder="Enter your answer" value={selectedAnswer} onChange={(e) => handleAnswerChange(e.target.value)} width="100%" />}

            {question.questionType === "sentence" && <Input placeholder="Type your answer" value={selectedAnswer} onChange={(e) => handleAnswerChange(e.target.value)} width="100%" />}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={4}>
            <Button onClick={onClose} variant="ghost">Cancel</Button>
            <Button 
              colorScheme="blue" 
              onClick={handleNext}
              isDisabled={!selectedAnswer}
              rightIcon={currentQuestionNumber < totalQuestions ? <ArrowRightIcon /> : undefined}
            >
              {currentQuestionNumber < totalQuestions ? "Next Question" : "Complete Quiz"}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default QuizQuestion;
