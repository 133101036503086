import React from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  VStack,
  HStack,
  Text,
  Textarea,
  FormErrorMessage,
  Box,
  Divider
} from "@chakra-ui/react";
import { IQuizQuestion } from "@/features/admin/course/adminCourseTypes";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Quiz_QuestionPreview from "./Quiz_QuestionPreview";
import FileUploadInput from '@/components/common/FileUploadInput';
import { IMAGE_TYPES } from "@/types/imageTypes";
 

interface Quiz_QuestionFormProps {
  initialData?: IQuizQuestion | null;
  onSubmit: (data: Omit<IQuizQuestion, 'id' | 'quizId'>) => void;
  onCancel: () => void;
}

type OptionId = string;

export type FormData = {
  content: string;
  sourceType: 'text' | 'audio';
  questionType: 'fill_blank' | 'sentence' | 'multi-option' | 'drag-drop';
  options?: { id: string; text: string }[];
  correctAnswer?: string;
  audioUrl?: string;
  imageUrl?: string;
};

const OPTION_IDS: OptionId[] = ["A", "B", "C", "D"];

const validationSchema = yup.object().shape({
  content: yup.string().required("Question content is required"),
  sourceType: yup.string().oneOf(['text', 'audio']).required(),
  questionType: yup.string().oneOf(['fill_blank', 'sentence', 'multi-option', 'drag-drop']).required(),
  options: yup.array().when('questionType', {
    is: (type: string) => ['multi-option', 'drag-drop'].includes(type),
    then: () => yup.array().of(
      yup.object({
        id: yup.string().required(),
        text: yup.string().required()
      })
    ).required(),
    otherwise: () => yup.array().optional()
  }),
  correctAnswer: yup.string().when('questionType', {
    is: 'drag-drop',
    then: () => yup.string().nullable(),
    otherwise: () => yup.string().required('Correct answer is required')
  }),
  audioUrl: yup.string().optional(),
  imageUrl: yup.string().optional()
}) as yup.ObjectSchema<FormData>;

const Quiz_QuestionForm: React.FC<Quiz_QuestionFormProps> = ({
  initialData,
  onSubmit,
  onCancel
}) => {
  const defaultValues: FormData = {
    content: initialData?.content || "",
    sourceType: initialData?.sourceType || "text",
    questionType: initialData?.questionType || "multi-option",
    options: OPTION_IDS.map((id) => ({
      id,
      text: initialData?.options?.find(opt => opt.id === id)?.text || "",
    })),
    correctAnswer: initialData?.correctAnswer || "A",
    audioUrl: initialData?.audioUrl || "",
    imageUrl: initialData?.imageUrl || "",
  };

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues,
    resolver: yupResolver<FormData>(validationSchema),
    context: { isMultiOption: false }
  });

  const questionType = watch('questionType');
 

  const sourceType = watch('sourceType');

  const onSubmitForm = (data: FormData) => {
    onSubmit({
      ...data,
      correctAnswer: data.correctAnswer || 'A'
    });
  };

  return (
    <HStack 
      align="start" 
      spacing={0} 
      width="100%" 
      minW="320px"
      overflow="auto"
      minH="70vh"
    >
      <Box 
        width="50%" 
        p={4}
        minW="300px"
        maxH="70vh"
        overflowY="auto"
      >
        <form onSubmit={handleSubmit(onSubmitForm)}>
          <VStack spacing={4}>
            <FormControl isRequired isInvalid={!!errors.content}>
              <FormLabel>Question Content</FormLabel>
              <Controller
                name="content"
                control={control}
                render={({ field }) => (
                  <Textarea 
                    {...field} 
                    rows={3}
                    resize="vertical" 
                    placeholder="Enter your question here..."
                  />
                )}
              />
              <FormErrorMessage>{errors.content?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.sourceType}>
              <HStack align="center" spacing={4}>
                <FormLabel mb={0} minW="100px">Source Type</FormLabel>
                <Controller
                  name="sourceType"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      <option value="text">Text</option>
                      <option value="audio">Audio</option>
                    </Select>
                  )}
                />
              </HStack>
              <FormErrorMessage>{errors.sourceType?.message}</FormErrorMessage>
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.questionType}>
              <HStack align="center" spacing={4}>
                <FormLabel mb={0} minW="100px">Question Type</FormLabel>
                <Controller
                  name="questionType"
                  control={control}
                  render={({ field }) => (
                    <Select {...field}>
                      <option value="fill_blank">Fill in the Blank</option>
                      <option value="sentence">Sentence</option>
                      <option value="multi-option">Multiple Choice</option>
                      <option value="drag-drop">Drag and Drop</option>
                    </Select>
                  )}
                />
              </HStack>
              <FormErrorMessage>{errors.questionType?.message}</FormErrorMessage>
            </FormControl>

            {sourceType === "audio" && (
              <FormControl isInvalid={!!errors.audioUrl}>
                <FormLabel>Audio URL</FormLabel>
                <Controller
                  name="audioUrl"
                  control={control}
                  render={({ field }) => <Input {...field} />}
                />
                <FormErrorMessage>{errors.audioUrl?.message}</FormErrorMessage>
              </FormControl>
            )}

            {initialData?.id && (
              <FormControl isInvalid={!!errors.imageUrl}>
                <Controller
                  name="imageUrl"
                  control={control}
                  render={({ field }) => (
                    <FileUploadInput
                      value={field.value}
                      onChange={field.onChange}
                      recordId={initialData.id}
                      imageType={IMAGE_TYPES.QUIZ}
                      isDisabled={!initialData.id}
                    />
                  )}
                />
                <FormErrorMessage>{errors.imageUrl?.message}</FormErrorMessage>
              </FormControl>
            )}

            {questionType === "multi-option" || questionType === "drag-drop" ? (
              <>
                <FormControl isRequired>
                  <FormLabel>{questionType === "drag-drop" ? "Sequence Options (in correct order)" : "Options"}</FormLabel>
                  {OPTION_IDS.map((id, index) => (
                    <HStack key={id} mt={2}>
                      <Text>{id}-</Text>
                      <Controller
                        name={`options.${index}.text`}
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            isInvalid={!!errors.options?.[index]?.text}
                            bg={questionType === "multi-option" && id === watch('correctAnswer') ? "green.200" : "pink.200"}
                          />
                        )}
                      />
                    </HStack>
                  ))}
                </FormControl>
              </>
            ) : null}

            {questionType !== "drag-drop" && (
              <FormControl isRequired isInvalid={!!errors.correctAnswer}>
                <HStack align="center" spacing={4}>
                  <FormLabel mb={0} minW="100px">Correct Answer</FormLabel>
                  <Controller
                    name="correctAnswer"
                    control={control}
                    render={({ field }) => (
                      questionType === "multi-option" ? (
                        <Select {...field}>
                          {OPTION_IDS.map((id) => (
                            <option key={id} value={id}>{id}</option>
                          ))}
                        </Select>
                      ) : (
                        <Input {...field} placeholder="Enter correct answer" />
                      )
                    )}
                  />
                </HStack>
                <FormErrorMessage>{errors.correctAnswer?.message}</FormErrorMessage>
              </FormControl>
            )}

            <HStack spacing={4} width="100%" justify="flex-end">
              <Button onClick={onCancel} variant="outline">
                Cancel
              </Button>
              <Button type="submit" colorScheme="blue">
                {initialData ? "Update" : "Create"}
              </Button>
            </HStack>
          </VStack>
        </form>
      </Box>
      
      <Divider 
        orientation='vertical' 
        h="70vh"
        borderColor="gray.300"
        borderWidth="1px"
      />
      
      <Box 
        width="50%" 
        p={4} 
        position="sticky" 
        top={4}
        minW="300px"
        maxH="70vh"
        overflowY="auto"
      >
        <Quiz_QuestionPreview formData={watch()} />
      </Box>
    </HStack>
  );
};

export default Quiz_QuestionForm; 