
import React, { useEffect } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Heading,
  Stack,
  Text,
  Grid,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
} from "@chakra-ui/react";
import { FaTelegram } from "react-icons/fa";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { formMode } from "@/types/globalTypes";
import { enumCourseStatus, ICourse } from "@/features/admin/course/adminCourseTypes";
import { fetchTelegramGroupsThunk } from "@/features/admin/course/adminCourseActions";
import GroupCard from "./components/GroupCard";
import TelegramGroupsInfo from "./components/TelegramGroupsInfo";
import TelegramGroupManagement from "./components/TelegramGroupManagement";

interface TelegramGroupSectionProps {
  mode: formMode;
  currentCourse: ICourse | null;
}

const TelegramGroupSection: React.FC<TelegramGroupSectionProps> = ({ mode, currentCourse }) => {
  const dispatch = useAppDispatch();
  const { telegramGroups } = useAppSelector((state) => state.adminCourse);

  const canManageGroups = 
    mode === formMode.EDIT && 
    currentCourse?.status === enumCourseStatus.Upcoming && 
    currentCourse?.isClosedToEnrolments;

  useEffect(() => {
    if (currentCourse) {
      dispatch(fetchTelegramGroupsThunk(currentCourse.id));
    }
  }, [currentCourse, dispatch]);

  return (
    <Card>
      <CardHeader bg="blue.500" py={3}>
        <Heading size="sm" color="white" display="flex" alignItems="center" gap={2}>
          <FaTelegram size={18} />
          Telegram Groups
        </Heading>
      </CardHeader>
      <CardBody>
        <Stack spacing={6}>
          {!telegramGroups?.length ? (
            // No groups exist
            canManageGroups ? (
              // User can manage groups - show management interface
              <TelegramGroupManagement currentCourse={currentCourse} />
            ) : (
              // User cannot manage groups - show info message
              <Alert
                status="info"
                variant="subtle"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                textAlign="center"
                borderRadius="md"
                p={6}
              >
                <AlertIcon boxSize="6" />
                <AlertTitle mt={4} mb={1} fontSize="lg">
                  No Telegram Groups Created
                </AlertTitle>
                <AlertDescription>
                  <TelegramGroupsInfo mode={mode} currentCourse={currentCourse} hasExistingGroups={!!telegramGroups} />
                </AlertDescription>
              </Alert>
            )
          ) : (
            // Groups exist - show them in read-only or editable mode
            <>
              {!canManageGroups && (
                <Text color="gray.600" mb={4}>
                  You are viewing the Telegram groups in read-only mode.
                </Text>
              )}
              <Grid templateColumns="repeat(auto-fill, minmax(300px, 1fr))" gap={6}>
                {telegramGroups.map((group) => (
                  <GroupCard 
                    key={group.id} 
                    group={group} 
                    isReadOnly={!canManageGroups}
                  />
                ))}
              </Grid>
            </>
          )}
        </Stack>
      </CardBody>
    </Card>
  );
};

export default TelegramGroupSection;