import React from 'react';
import { Box, VStack, Text, Heading, Divider } from '@chakra-ui/react';
import { Module } from '@/features/learner/course/courseTypes';

interface Props {
    modules?: Module[];
    onModuleSelect: (module: Module) => void;
    currentModuleId?: string;
}

export const CourseSidebar: React.FC<Props> = ({ modules = [], onModuleSelect, currentModuleId }) => {
    return (
        <Box
            w="300px"
            h="100%"
            bg="purple.600"
            p={4}
            overflowY="auto"
            css={{
                '&::-webkit-scrollbar': {
                    width: '6px',
                },
                '&::-webkit-scrollbar-track': {
                    background: 'rgba(255,255,255,0.1)',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: 'rgba(255,255,255,0.3)',
                    borderRadius: '4px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                    background: 'rgba(255,255,255,0.4)',
                },
            }}
        >
            <VStack spacing={4} align="stretch">
                <Heading size="md" mb={2} color="white">Modules</Heading>
                <Divider borderColor="whiteAlpha.400" />
                {modules.map((module, index) => (
                    <Box
                        key={module.id}
                        p={3}
                        bg={currentModuleId === module.id ? 'rgba(255,255,255,0.1)' : 'transparent'}
                        cursor="pointer"
                        borderRadius="md"
                        onClick={() => onModuleSelect(module)}
                        _hover={{ bg: 'rgba(255,255,255,0.15)' }}
                    >
                        <Text color="white" fontWeight="medium">
                            {index + 1}. {module.name}
                        </Text>
                    </Box>
                ))}
            </VStack>
        </Box>
    );
}; 