import { Card, CardBody, Text, VStack, HStack, CardHeader, List, ListItem, Badge } from "@chakra-ui/react";
import { useAppSelector } from "@/hooks/useRedux";

import { formatDateForDisplay } from "@/utils/helpers";

const UpcomingZoomSessions = () => {
  const { incomingZoomSessions } = useAppSelector((state) => state.learnerDashboard);

  return (
    <Card>
      <CardHeader bg="blue.500" py={3}>
        <Text color="white" fontSize="lg" fontWeight="bold">
          Upcoming Sessions
        </Text>
      </CardHeader>
      <CardBody>
        <List spacing={3}>
          {incomingZoomSessions.map((session, index) => (
            <ListItem key={index}>
              <HStack justify="space-between">
                <VStack align="start" spacing={0}>
                  <Text fontWeight="bold">{session.name}</Text>
                  <Text fontSize="sm" color="gray.600">
                    {formatDateForDisplay(session.date)}
                  </Text>
                </VStack>
                <Badge colorScheme="blue">{session.duration}min</Badge>
              </HStack>
            </ListItem>
          ))}
        </List>
      </CardBody>
    </Card>
  );
};

export default UpcomingZoomSessions;
