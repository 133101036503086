import React from 'react';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
  VStack,
  SimpleGrid,
  Button,
  Box,
  IconButton,
  Text,
  HStack,
} from '@chakra-ui/react';
import { Control, UseFormRegister, FieldErrors, UseFormSetValue, UseFormWatch, useFieldArray } from 'react-hook-form';
import { CourseFormData } from '@/components/admin/courses/courseForm/courseFormConstants';
import { DayOfWeek } from '@/features/admin/course/adminCourseTypes';
import { ControlledTimeInput } from '@/components/common/ControlledTimeInput';
import { ControlledNumberInput } from '@/components/common/ReusableControlledNumberInput';
import { formMode } from '@/types/globalTypes';
import { Clock, Plus, Trash2 } from 'lucide-react';

interface ZoomSessionSectionProps {
  register: UseFormRegister<CourseFormData>;
  control: Control<CourseFormData>;
  errors: FieldErrors<CourseFormData>;
  mode: formMode;
  setValue: UseFormSetValue<CourseFormData>;
  watch: UseFormWatch<CourseFormData>;
}

const ZoomSessionSection: React.FC<ZoomSessionSectionProps> = ({
  register,
  control,
  errors,
  mode,
  setValue,
  watch
}) => {
  
  const { fields, append, remove } = useFieldArray({
    control,
    name: "zoomTimes"
  });

  const addSession = () => {
    append({
      dayOfWeek: DayOfWeek.Monday,
      hour: 9,
      minute: 0,
      duration: 60
    });
  };

  return (
    <VStack spacing={6} align="stretch">
      <FormControl isInvalid={!!errors.zoomLink}>
        <FormLabel>Zoom Meeting Link</FormLabel>
        <Input
          {...register('zoomLink')}
          placeholder="https://zoom.us/j/..."
          isReadOnly={mode === formMode.VIEW}
        />
        <FormErrorMessage>
          {errors.zoomLink?.message}
        </FormErrorMessage>
      </FormControl>

      <Box>
        <HStack justify="space-between" mb={4}>
          <Text fontWeight="medium">Zoom Sessions</Text>
          {mode !== formMode.VIEW && (
            <Button 
              leftIcon={<Plus size={16} />}
              size="sm"
              colorScheme="purple"
              onClick={addSession}
            >
              Add Session
            </Button>
          )}
        </HStack>

        <VStack spacing={4}>
          {fields.map((field, index) => (
            <Box
              key={field.id}
              p={4}
              borderWidth={1}
              borderRadius="md"
              position="relative"
              width="100%"
            >
              {mode !== formMode.VIEW && (
                <IconButton
                  icon={<Trash2 size={16} />}
                  aria-label="Remove session"
                  size="sm"
                  colorScheme="red"
                  variant="ghost"
                  position="absolute"
                  top={2}
                  right={2}
                  onClick={() => remove(index)}
                />
              )}
              
              <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mt={2}>
                <FormControl isInvalid={!!errors.zoomTimes?.[index]?.dayOfWeek}>
                  <FormLabel>Day of Week</FormLabel>
                  <Select
                    {...register(`zoomTimes.${index}.dayOfWeek`)}
                    isReadOnly={mode === formMode.VIEW}
                  >
                    {Object.entries(DayOfWeek).map(([key, value]) => (
                      <option key={value} value={value}>
                        {key}
                      </option>
                    ))}
                  </Select>
                  <FormErrorMessage>
                    {errors.zoomTimes?.[index]?.dayOfWeek?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.zoomTimes?.[index]?.hour || !!errors.zoomTimes?.[index]?.minute}>
                  <FormLabel>Time</FormLabel>
                  <ControlledTimeInput
                    hourFieldName={`zoomTimes.${index}.hour`}
                    minuteFieldName={`zoomTimes.${index}.minute`}
                    control={control}
                    setValue={setValue}
                    watch={watch}
                    isReadOnly={mode === formMode.VIEW}
                    leftIcon={Clock}
                  />
                  <FormErrorMessage>
                    {errors.zoomTimes?.[index]?.hour?.message || errors.zoomTimes?.[index]?.minute?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors.zoomTimes?.[index]?.duration}>
                  <FormLabel>Duration (minutes)</FormLabel>
                  <ControlledNumberInput
                    name={`zoomTimes.${index}.duration`}
                    control={control}
                    min={15}
                    max={240}
                    step={15}
                    isReadOnly={mode === formMode.VIEW}
                  />
                  <FormErrorMessage>
                    {errors.zoomTimes?.[index]?.duration?.message}
                  </FormErrorMessage>
                </FormControl>
              </SimpleGrid>
            </Box>
          ))}
        </VStack>
      </Box>
    </VStack>
  );
};

export default ZoomSessionSection;