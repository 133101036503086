import React, { useState } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Heading,
  Text,
  Button,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { Users } from "lucide-react";
import { ITelegramGroup } from "@/features/admin/course/adminCourseTypes";
import GroupMembersDialog from "./GroupMembersDialog" ;
interface GroupCardProps {
  group: ITelegramGroup;
  isReadOnly: boolean;
}

const GroupCard: React.FC<GroupCardProps> = ({ group, isReadOnly }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  return (
    <>
      <Card>
        <CardHeader>
          <Heading size="md">{group.name}</Heading>
        </CardHeader>
        <CardBody>
          <VStack align="stretch" spacing={4}>
            <HStack justify="space-between">
              <HStack>
                <Users size={20} />
                <Text>{group.memberCount} members</Text>
              </HStack>
              <Button
                size="sm"
                colorScheme="blue"
                variant={isReadOnly ? "outline" : "solid"}
                onClick={() => setIsDialogOpen(true)}
              >
                {isReadOnly ? "View Members" : "Manage Members"}
              </Button>
            </HStack>
          </VStack>
        </CardBody>
      </Card>

      <GroupMembersDialog
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        group={group}
        isReadOnly={isReadOnly}
      />
    </>
  );
};

export default GroupCard;
