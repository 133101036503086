 
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Container,
  VStack,
  Heading,
  Text,
  Button,
  Icon,
  Spinner,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { MdCheckCircle, MdError } from 'react-icons/md';
import { useAppDispatch } from '@/hooks/useRedux';
import { resetEnrollment } from '@/features/learner/enrollment/enrollmentSlice';
import { useEffect, useState } from 'react';

const EnrollmentPaymentResult = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // Get session_id from URL
  const sessionId = searchParams.get('session_id');
  const [verificationStatus, setVerificationStatus] = useState<'loading' | 'success' | 'error'>('loading');

  useEffect(() => {
    const verifyPayment = async () => {
      try {
        // Call your backend to verify the payment status
        const response = await fetch(`/api/payments/verify/${sessionId}`);
        const data = await response.json();
        
        if (data.status === 'complete') {
          setVerificationStatus('success');
        } else {
          setVerificationStatus('error');
        }
      } catch (error) {
        console.log(error)
        setVerificationStatus('error');
      }
    };

    if (sessionId) {
      verifyPayment();
    } else {
      setVerificationStatus('error');
    }

    // Cleanup enrollment state when leaving
    return () => {
      dispatch(resetEnrollment());
    };
  }, [sessionId, dispatch]);

  const handleContinue = () => {
    navigate('/learner/dashboard');
  };

  if (verificationStatus === 'loading') {
    return (
      <Container maxW="container.md" py={10}>
        <VStack spacing={6}>
          <Spinner size="xl" color="purple.500" />
          <Text>Verifying your payment...</Text>
        </VStack>
      </Container>
    );
  }

  return (
    <Container maxW="container.md" py={10}>
      <VStack spacing={6}>
        {verificationStatus === 'success' ? (
          <>
            <Icon as={MdCheckCircle} w={16} h={16} color="green.500" />
            <Heading size="lg">Enrollment Successful!</Heading>
            <Text align="center">
              Thank you for enrolling. You can now access your course from your dashboard.
            </Text>
            <Alert status="success">
              <AlertIcon />
              You will receive a confirmation email with course details shortly.
            </Alert>
          </>
        ) : (
          <>
            <Icon as={MdError} w={16} h={16} color="red.500" />
            <Heading size="lg">Payment Failed</Heading>
            <Text align="center">
              We couldn't process your payment. Please try enrolling again.
            </Text>
            <Alert status="error">
              <AlertIcon />
              No payment has been processed from your account.
            </Alert>
          </>
        )}

        <Button
          colorScheme={verificationStatus === 'success' ? 'green' : 'red'}
          onClick={handleContinue}
          size="lg"
          mt={4}
        >
          Return to Dashboard
        </Button>
      </VStack>
    </Container>
  );
};

export default EnrollmentPaymentResult;