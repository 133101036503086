import { Card, CardBody, Text, VStack, HStack, Button, Link, Icon } from "@chakra-ui/react";
import { useAppSelector } from "@/hooks/useRedux";
import { FaExternalLinkAlt, FaTelegram } from "react-icons/fa";

const TelegramGroup = () => {
  const { telegramGroup } = useAppSelector((state) => state.learnerDashboard);

  return (
    <Card w="100%" bg="blue.50">
      <CardBody>
        <VStack spacing={3} align="stretch">
          <HStack>
            <Icon as={FaTelegram} boxSize={6} color="blue.500" />
            <Text fontWeight="bold">Join Our Community</Text>
          </HStack>
          {telegramGroup && (
            <>
              <Text fontSize="sm">Group Name: {telegramGroup.name}</Text>
              <Link href={telegramGroup.link} isExternal>
                <Button colorScheme="telegram" size="sm" width="100%" rightIcon={<FaExternalLinkAlt />}>
                  Join Telegram Group
                </Button>
              </Link>
            </>
          )}
          {!telegramGroup && <Text>You are not assigned to a Telegram Group yet.</Text>}
        </VStack>
      </CardBody>
    </Card>
  );
};

export default TelegramGroup;
