import { Card, CardBody, Text,    HStack, CardHeader, Progress,   SimpleGrid, StatNumber, Stat, StatLabel, StatHelpText, StatArrow, Icon } from "@chakra-ui/react";
import { useAppSelector } from "@/hooks/useRedux";
import { FaChartLine } from "react-icons/fa";

const LearningAnalyticsCard = () => {
  const { learnerAnalytics } = useAppSelector((state) => state.learnerDashboard);

  return (

    <Card>
    <CardHeader bg="purple.500" py={3}>
      <HStack>
        <Icon as={FaChartLine} color="white" />
        <Text color="white" fontSize="lg" fontWeight="bold">
          Learning Analytics
        </Text>
      </HStack>
    </CardHeader>
    <CardBody>
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
        <Stat>
          <StatLabel>Total Learning Hours</StatLabel>
          <StatNumber>{!learnerAnalytics ? 0 : learnerAnalytics.totalHours}h</StatNumber>
          <StatHelpText>
            <StatArrow type="increase" />
            {0}h this week
          </StatHelpText>
        </Stat>
        <Stat>
          <StatLabel>Participation Rate</StatLabel>
          <StatNumber>{!learnerAnalytics ? 0 : learnerAnalytics.participationRate}%</StatNumber>
          <StatHelpText>Active participation in classes</StatHelpText>
        </Stat>
        <Stat>
          <StatLabel>Completed Modules</StatLabel>
          <StatNumber>{!learnerAnalytics ? 0 : learnerAnalytics.completedModules}</StatNumber>
          <Progress value={!learnerAnalytics ? 0 : learnerAnalytics.completedModules} colorScheme="green" size="sm" mt={2} />
        </Stat>
      </SimpleGrid>
    </CardBody>
  </Card>


/* 
    <Card>
      <CardHeader bg="purple.500" py={3}>
        <Text color="white" fontSize="lg" fontWeight="bold">
          Learning Analytics
        </Text>
      </CardHeader>
      <CardBody>
        <VStack align="stretch" spacing={4}>
          <HStack justify="space-between">
            <Text>Total Hours:</Text>
            <Text fontWeight="bold">{!learnerAnalytics ? 0 : learnerAnalytics.totalHours}h</Text>
          </HStack>
          <HStack justify="space-between">
            <Text>Participation:</Text>
            <Progress value={!learnerAnalytics ? 0 : learnerAnalytics.participationRate} colorScheme="green" width="100px" />
          </HStack>
          <HStack justify="space-between">
            <Text>Completed Modules:</Text>
            <Badge colorScheme="purple">{!learnerAnalytics ? 0 : learnerAnalytics.completedModules}</Badge>
          </HStack>
        </VStack>
      </CardBody>
    </Card> */
  );
};

export default LearningAnalyticsCard;
