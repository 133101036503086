import React, { useState, useCallback } from "react";
 
import { createQuizQuestionThunk, updateQuizQuestionThunk, deleteQuizQuestionThunk } from "@/features/admin/course/adminCourseActions";
import { IQuizQuestion } from "@/features/admin/course/adminCourseTypes";
import ReusableTable, { IFetchDataOptions } from "@/components/common/ReusableTable/ReusableTable";
import { Box, useToast } from "@chakra-ui/react";
import { CellContext } from "@tanstack/react-table";
import { fetchData_ReusableTable } from "@/utils/helpers";
import { getQuizQuestions } from "@/features/admin/course/adminCourseApi";
import ReusableModal from "@/components/common/ReusableModal";
import Quiz_QuestionForm from "@/components/admin/courses/Quiz_QuestionForm";
import { useAppDispatch } from "@/hooks/useRedux";
import { useConfirmDialog } from "@/components/common/ConfirmationModal/useConfirmDialog";

interface Quiz_QuestionsProps {
  quizId: string;
}

const Quiz_Questions: React.FC<Quiz_QuestionsProps> = ({ quizId }) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [editingQuestion, setEditingQuestion] = useState<IQuizQuestion | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [triggerkey, setTriggerKey] = useState(0);
  const { confirm, ConfirmationDialog } = useConfirmDialog();

  const triggerRefetch = () => {
    setTriggerKey((old) => old + 1);
  };

  const handleSubmit = async (questionData: Omit<IQuizQuestion, 'id' | 'quizId'>) => {
    try {
      if (editingQuestion) {
        await dispatch(updateQuizQuestionThunk({ 
          quizId, 
          questionId: editingQuestion.id, 
          questionData 
        })).unwrap();
        toast({
          title: "Question updated",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        await dispatch(createQuizQuestionThunk({ 
          quizId, 
          questionData 
        })).unwrap();
        toast({
          title: "Question created",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      }
      setIsModalOpen(false);
      setEditingQuestion(null);
      triggerRefetch();
    } catch (error) {
      toast({
        title: "Error",
        description: (error as Error).message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleDeleteQuestion = async (questionId: string) => {
    if (await confirm("Are you sure you want to delete this question?")) {
      try {
        await dispatch(deleteQuizQuestionThunk({ quizId, questionId })).unwrap();
        toast({
          title: "Question deleted",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        triggerRefetch();
      } catch (error) {
        toast({
          title: "Error",
          description: (error as Error).message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    }
  };

  const columns = [
    {
      accessorKey: "content",
      header: "Question",
      cell: (context: CellContext<IQuizQuestion, unknown>) => {
        const cellValue = context.getValue() as string;
        return cellValue.slice(0, 50) + (cellValue.length > 50 ? "..." : "");
      },
    },
    {
      accessorKey: "questionType",
      header: "Type",
      cell: (context: CellContext<IQuizQuestion, unknown>) => {
        const cellValue = context.getValue() as string;
        return cellValue.replace('_', ' ').toUpperCase();
      },
    },
    {
      accessorKey: "sourceType",
      header: "Source",
      cell: (context: CellContext<IQuizQuestion, unknown>) => {
        const cellValue = context.getValue() as string;
        return cellValue.toUpperCase();
      },
    },
  ];

  return (
    <Box>
      <ReusableTable
        refreshTrigger={triggerkey}
        columns={columns}
        fetchData={useCallback(
          (options: IFetchDataOptions) => 
            fetchData_ReusableTable<IQuizQuestion>(options, () => getQuizQuestions(quizId)), 
          [quizId]
        )}
        initialPageSize={10}
        tableTitle="Quiz Questions"
        onCreate={() => {
          setEditingQuestion(null);
          setIsModalOpen(true);
        }}
        onEdit={(row) => {
          setEditingQuestion(row as IQuizQuestion);
          setIsModalOpen(true);
        }}
        onDelete={(row) => handleDeleteQuestion((row as IQuizQuestion).id)}
      />
      <ReusableModal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setEditingQuestion(null);
        }}
        title={editingQuestion ? "Edit Question" : "Add New Question"}
        size="6xl"
      >
        <Quiz_QuestionForm
          onSubmit={handleSubmit}
          onCancel={() => {
            setIsModalOpen(false);
            setEditingQuestion(null);
          }}
          initialData={editingQuestion}
        />
      </ReusableModal>
      <ConfirmationDialog />
    </Box>
  );
};

export default Quiz_Questions; 