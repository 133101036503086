import api from "@/utils/api";
import { Course, Module } from './courseTypes';
 

 
export const getCourseById = async (courseId: string): Promise<Course> => {
    const response = await api.get(`/courses/learner/${courseId}`);
    return response.data;
};

export const getModuleById = async (moduleId: string): Promise<Module> => {
    const response = await api.get(`/courses/learner/module/${moduleId}`);
    return response.data;
}; 