import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Text,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  CircularProgress,
  CircularProgressLabel,
  VStack,
  HStack,
  Icon,
  Box,
  Button,
  Divider,
} from '@chakra-ui/react';
import { FaClock, FaCalendarAlt, FaCheckCircle, FaListUl } from 'react-icons/fa';
import { UserQuiz } from '@/features/learner/userQuiz/userQuizTypes';

interface UserQuizResultsProps {
  userQuiz: UserQuiz | null;
  onStartQuiz: () => void;
}

export const UserQuizResults: React.FC<UserQuizResultsProps> = ({ userQuiz, onStartQuiz }) => {
  const calculateDuration = () => {
    if (!userQuiz?.completedAt) return '-- : --';
    const start = new Date(userQuiz.startedAt);
    const end = new Date(userQuiz.completedAt);
    const durationMs = end.getTime() - start.getTime();
    const minutes = Math.floor(durationMs / 60000);
    const seconds = Math.floor((durationMs % 60000) / 1000);
    return `${minutes}m ${seconds}s`;
  };

  const correctPercentage = userQuiz 
    ? Math.round((userQuiz.correctAnswerCount / userQuiz.totalQuestionCount) * 100)
    : 0;

  return (
    <Card variant="outline" width="full">
      <CardHeader bg="purple.400" color="white" p={4}>
        <Text fontSize="lg" fontWeight="bold">Your Quiz Results</Text>
      </CardHeader>
      <CardBody>
        <VStack spacing={6} align="stretch">
          {/* First Row - Dates and Duration */}
          <StatGroup gap={4}>
            <Stat>
              <HStack spacing={2}>
                <Icon as={FaCalendarAlt} color="blue.500" />
                <StatLabel>Start Date</StatLabel>
              </HStack>
              <StatNumber fontSize="md">
                {userQuiz?.startedAt 
                  ? new Date(userQuiz.startedAt).toLocaleString()
                  : 'Not Started Yet'}
              </StatNumber>
            </Stat>
            
            <Stat>
              <HStack spacing={2}>
                <Icon as={FaCalendarAlt} color="green.500" />
                <StatLabel>Complete Date</StatLabel>
              </HStack>
              <StatNumber fontSize="md">
                {userQuiz?.completedAt 
                  ? new Date(userQuiz.completedAt).toLocaleString()
                  : 'Not Completed'}
              </StatNumber>
            </Stat>

            <Stat>
              <HStack spacing={2}>
                <Icon as={FaClock} color="purple.500" />
                <StatLabel>Duration</StatLabel>
              </HStack>
              <StatNumber fontSize="md">{calculateDuration()}</StatNumber>
            </Stat>
          </StatGroup>

          {/* Second Row - Questions Stats and Progress */}
          <HStack spacing={4} justify="flex-start">
            <Box flex={2}>
              <Stat p={4} bg="gray.500" color="white" borderRadius="lg" h="100%">
                <StatNumber fontSize="2xl">{userQuiz?.totalQuestionCount || 0}</StatNumber>
                <HStack spacing={2}>
                  <Icon as={FaListUl} color="white" />
                  <StatLabel>Total Questions</StatLabel>
                </HStack>
              </Stat>
            </Box>

            <Box flex={2}>
              <Stat p={4} bg="green.500" color="white" borderRadius="lg" h="100%">
                <StatNumber fontSize="2xl">{userQuiz?.correctAnswerCount || 0}</StatNumber>
                <HStack spacing={2}>
                  <Icon as={FaCheckCircle} color="white" />
                  <StatLabel>Correct Answers</StatLabel>
                </HStack>
              </Stat>
            </Box>

            <Box flex={1} display="flex" justifyContent="flex-start">
              <CircularProgress
                value={correctPercentage}
                size="100px"
                thickness="8px"
                color={correctPercentage >= 70 ? "green.400" : "orange.400"}
              >
                <CircularProgressLabel>{correctPercentage}%</CircularProgressLabel>
              </CircularProgress>
            </Box>
          </HStack>
        </VStack>
      </CardBody>
      <CardFooter display="flex" justifyContent="right" w="full">
        <Divider />
        <Button 
          colorScheme="blue" 
          onClick={onStartQuiz}
          mt={4}
          size="lg"
        >
          {userQuiz ? "Restart Quiz" : "Start Quiz"}
        </Button>
      </CardFooter>
    </Card>
  );
}; 