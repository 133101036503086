import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Course, CourseState, Module } from './courseTypes';
import { fetchCourse, fetchModule } from './courseActions';

const initialState: CourseState = {
    currentCourse: null,
    currentModule: null,
    loading: false,
    error: null,
};

const courseSlice = createSlice({
    name: 'learnerCourse',
    initialState,
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            // Course fetching
            .addCase(fetchCourse.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCourse.fulfilled, (state, action: PayloadAction<Course>) => {
                state.loading = false;
                state.currentCourse = action.payload;
                state.error = null;
            })
            .addCase(fetchCourse.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            // Module fetching
            .addCase(fetchModule.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchModule.fulfilled, (state, action: PayloadAction<Module>) => {
                state.loading = false;
                state.currentModule = action.payload;
                state.error = null;
            })
            .addCase(fetchModule.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const { clearError } = courseSlice.actions;
export default courseSlice.reducer; 