// src/components/admin/courses/courseForm/sections/TelegramGroupSection/components/TelegramGroupsInfo.tsx

import React from "react";
import {
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  List,
  ListItem,
  ListIcon,
  Text,
  Stack,
} from "@chakra-ui/react";
import { CheckCircle, XCircle } from "lucide-react";
import { formMode } from "@/types/globalTypes";
import { enumCourseStatus, ICourse } from "@/features/admin/course/adminCourseTypes";

interface TelegramGroupsInfoProps {
  mode: formMode;
  currentCourse: ICourse | null;
  hasExistingGroups: boolean;
}

const TelegramGroupsInfo: React.FC<TelegramGroupsInfoProps> = ({ 
  mode, 
  currentCourse,
  hasExistingGroups
}) => {
  const getRequirements = () => {
    const requirements = [
      {
        met: mode === formMode.EDIT,
        text: "Form must be in edit mode",
        details: mode !== formMode.EDIT ? 
          "Switch to edit mode to manage telegram groups" : undefined
      },
      {
        met: currentCourse?.status === enumCourseStatus.Upcoming,
        text: "Course must be upcoming (not started)",
        details: currentCourse?.status !== enumCourseStatus.Upcoming ?
          `Current status is ${currentCourse?.status}` : undefined
      },
      {
        met: currentCourse?.isClosedToEnrolments,
        text: "Course must be closed to enrollments",
        details: !currentCourse?.isClosedToEnrolments ?
          "Close enrollments before managing groups" : undefined
      }
    ];

    return requirements;
  };

  const canManageGroups = 
    mode === formMode.EDIT && 
    currentCourse?.status === enumCourseStatus.Upcoming && 
    currentCourse?.isClosedToEnrolments;

  const getAlertProps = () => {
    if (hasExistingGroups) {
      return {
        status: "info" as const,
        title: "View Only Mode",
        description: "You are viewing existing telegram groups in read-only mode. To manage groups:"
      };
    }
    
    return {
      status: "warning" as const,
      title: "No Telegram Groups",
      description: "Telegram groups cannot be created at this time. To create groups:"
    };
  };

  const alertProps = getAlertProps();

  return (
    <Alert
      status={alertProps.status}
      variant="subtle"
      flexDirection="column"
      alignItems="flex-start"
      p={4}
      borderRadius="md"
    >
      <Stack spacing={3} width="100%">
        <Stack spacing={1}>
          <AlertTitle fontSize="lg" display="flex" alignItems="center">
            <AlertIcon mr={2} />
            {alertProps.title}
          </AlertTitle>
          <AlertDescription>
            {alertProps.description}
          </AlertDescription>
        </Stack>

        <List spacing={3}>
          {getRequirements().map((req, index) => (
            <ListItem 
              key={index} 
              display="flex" 
              alignItems="flex-start"
              gap={2}
            >
              <ListIcon 
                as={req.met ? CheckCircle : XCircle} 
                color={req.met ? "green.500" : "red.500"} 
                mt={1}
              />
              <Stack spacing={0}>
                <Text fontWeight="medium">
                  {req.text}
                  {!req.met && " (not met)"}
                </Text>
                {!req.met && req.details && (
                  <Text fontSize="sm" color="gray.600">
                    {req.details}
                  </Text>
                )}
              </Stack>
            </ListItem>
          ))}
        </List>

        {!canManageGroups && (
          <Text fontSize="sm" color="gray.600">
            Meet all requirements above to {hasExistingGroups ? "manage" : "create"} telegram groups.
          </Text>
        )}
      </Stack>
    </Alert>
  );
};

export default TelegramGroupsInfo;