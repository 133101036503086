import React, { useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Heading,
  VStack,
  HStack,
  Button,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Box,
  Text,
  Progress
} from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from '@/hooks/useRedux';
import { setPrerequisites } from '@/features/learner/enrollment/enrollmentSlice';
import { useCurrentUser } from '@/hooks/useCurrentUser';

interface PrerequisitesCheckProps {
  onBack: () => void;
  onNext: () => void;
}

const PrerequisitesCheck: React.FC<PrerequisitesCheckProps> = ({ onBack, onNext }) => {
  const dispatch = useAppDispatch();
  const { currentCourse, prerequisites } = useAppSelector((state) => state.learnerEnrollment);
  const { currentUser } = useCurrentUser()

  useEffect(() => {
    if (currentCourse && currentUser) {
      const levelTestMet = currentCourse.prerequisites.levelTestScore 
        ? (currentUser.leveltest?.score || 0) >= currentCourse.prerequisites.levelTestScore
        : true;
        
      // Note: You'll need to implement the logic to check previousCourseMet
      // based on your user's completed courses data
      const previousCourseMet = !currentCourse.prerequisites.requiredCourseTypeId || true;

      dispatch(setPrerequisites({
        levelTestMet,
        previousCourseMet
      }));
    }
  }, [currentCourse, currentUser, dispatch]);

  if (!currentCourse) return null;

  const canProceed = prerequisites.levelTestMet && prerequisites.previousCourseMet;

  return (
    <Card>
      <CardHeader>
        <Heading size="md">Prerequisites Check</Heading>
      </CardHeader>
      <CardBody>
        <VStack align="stretch" spacing={6}>
          {/* Level Test Requirement */}
          {currentCourse.prerequisites.levelTestScore && (
            <Alert
              status={prerequisites.levelTestMet ? 'success' : 'error'}
              variant="left-accent"
            >
              <AlertIcon />
              <Box flex="1">
                <AlertTitle>Level Test Requirement</AlertTitle>
                <AlertDescription display="block">
                  Required Score: {currentCourse.prerequisites.levelTestScore}
                  <br />
                  Your Score: { currentUser?.leveltest?.score ? Math.round(currentUser?.leveltest?.score) : 0}
                  <Progress
                    mt={2}
                    value={currentUser?.leveltest?.score || 0}
                    max={100}
                    colorScheme={prerequisites.levelTestMet ? 'green' : 'red'}
                  />
                </AlertDescription>
              </Box>
            </Alert>
          )}

          {/* Previous Course Requirement */}
          {currentCourse.prerequisites.requiredCourseTypeId && (
            <Alert
              status={prerequisites.previousCourseMet ? 'success' : 'error'}
              variant="left-accent"
            >
              <AlertIcon />
              <Box flex="1">
                <AlertTitle>Previous Course Requirement</AlertTitle>
                <AlertDescription>
                  {prerequisites.previousCourseMet
                    ? 'You have completed the required prerequisite course'
                    : 'You need to complete the required prerequisite course first'}
                </AlertDescription>
              </Box>
            </Alert>
          )}

          {!canProceed && (
            <Alert status="warning">
              <AlertIcon />
              <Text>
                You cannot proceed with enrollment until all prerequisites are met.
              </Text>
            </Alert>
          )}

          <HStack justify="space-between" pt={4}>
            <Button onClick={onBack}>
              Back
            </Button>
            <Button
              colorScheme="purple"
              isDisabled={!canProceed}
              onClick={onNext}
            >
              Continue to Schedule
            </Button>
          </HStack>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default PrerequisitesCheck;