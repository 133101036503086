export interface IEnrollableCourse {
  id: string;
  name: string;
  description: string;
  price: number;
  startDate: Date;
  endDate: Date;
  maxEnrollments: number;
  currentEnrollments: number;
  zoomTime: {
    dayOfWeek: string;
    hour: number;
    minute: number;
    duration: number;
  };
}

export enum enEnrollmentStatus {
  Pending = "pending", // Initial state when enrollment created
  Active = "active", // After payment completed
  Cancelled = "cancelled", // After cancellation processed
}

export interface IEnrolledCourse {
  id: string;
  name: string;
  startDate: string;
  endDate: string;
  enrollmentStatus: enEnrollmentStatus;
  currentModule: {
    moduleNo: number;
    moduleName: string;
  };
}

export interface IResource {
  id: string;
  name: string;
  type: string;
  link: string;
}

export interface ITelegramGroup {
  id: string;
  name: string;
  link: string;
}

export interface IZoomSession {
  name: string;
  date: Date;
  duration: number;
}

export interface ILearnerAnalytics {
  totalHours: number;
  participationRate: number;
  completedModules: number;
}

export interface IDashboardResponse {
  enrollableCourses: IEnrollableCourse[];
  enrolledCourses: IEnrolledCourse[];
  resources: IResource[];
  telegramGroup: ITelegramGroup | null;
  incomingZoomSessions: IZoomSession[];
  learnerAnalytics: ILearnerAnalytics | null;
}

export interface ILearnerDashboardState extends IDashboardResponse {
  isLoading: boolean;
  error: string | null;
}
