import React, { useState, useEffect } from "react";
import { Box, VStack, HStack, Text, Card, CardBody, useColorModeValue, Button, Flex } from "@chakra-ui/react";
import { DragDropContext, Droppable, Draggable, DropResult, DroppableProps } from "react-beautiful-dnd";

// StrictModeDroppable component
const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

interface Option {
  id: string;
  text: string;
  showReplacement?: boolean;
}

interface Props {
  options: Option[];
  onDragUpdate: (orderedTexts: string[]) => void;
  preview?: boolean;
}

const Quiz_DragDropPreview: React.FC<Props> = ({ options, onDragUpdate, preview = true }) => {
  const [sourceOptions, setSourceOptions] = useState<Option[]>([]);
  const [targetOptions, setTargetOptions] = useState<Option[]>([]);
  const [isCorrect, setIsCorrect] = useState<boolean | null>(null);

  useEffect(() => {
    const shuffled = [...options].sort(() => Math.random() - 0.5);
    setSourceOptions(shuffled);
    setTargetOptions(
      Array(4)
        .fill(null)
        .map(() => ({ id: "", text: "" }))
    );
    setIsCorrect(null);
  }, [options]);

  const optionBg = useColorModeValue("blue.100", "blue.700");
  const emptyBoxBg = useColorModeValue("gray.50", "gray.700");
  const borderColor = useColorModeValue("blue.200", "blue.600");

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const { source, destination } = result;

    if (source.droppableId === "source" && destination.droppableId === "target") {
      const sourceItem = sourceOptions[source.index];
      const newSourceOptions = [...sourceOptions];
      const newTargetOptions = [...targetOptions];

      if (newTargetOptions[destination.index]?.text) {
        return;
      }

      newSourceOptions[source.index] = { id: "", text: "", showReplacement: true };
      newTargetOptions[destination.index] = sourceItem;

      setSourceOptions(newSourceOptions);
      setTargetOptions(newTargetOptions);
      setIsCorrect(null);

      // Update content text
      const filledTexts = newTargetOptions.filter((opt) => opt.text).map((opt) => opt.text);
      onDragUpdate(filledTexts);
    } else if (source.droppableId === "target" && destination.droppableId === "target") {
      const newTargetOptions = [...targetOptions];
      const [removed] = newTargetOptions.splice(source.index, 1);
      newTargetOptions.splice(destination.index, 0, removed);
      setTargetOptions(newTargetOptions);
      setIsCorrect(null);

      // Update content text after reordering
      const filledTexts = newTargetOptions.filter((opt) => opt.text).map((opt) => opt.text);
      onDragUpdate(filledTexts);
    }
  };

  const handleDoubleClick = (index: number) => {
    const item = targetOptions[index];
    if (!item.text) return;

    const emptyIndex = sourceOptions.findIndex((opt) => !opt.text);
    if (emptyIndex === -1) return;

    const newSourceOptions = [...sourceOptions];
    const newTargetOptions = [...targetOptions];

    newSourceOptions[emptyIndex] = item;
    newTargetOptions[index] = { id: "", text: "" };

    setSourceOptions(newSourceOptions);
    setTargetOptions(newTargetOptions);
    setIsCorrect(null);

    // Update content text after removing item
    const filledTexts = newTargetOptions.filter((opt) => opt.text).map((opt) => opt.text);
    onDragUpdate(filledTexts);
  };

  const checkAnswer = () => {
    const filledTargetOptions = targetOptions.filter((opt) => opt.text);
    if (filledTargetOptions.length !== options.length) {
      setIsCorrect(false);
      return;
    }
    const isMatch = filledTargetOptions.every((opt, index) => opt.id === options[index].id);
    setIsCorrect(isMatch);
  };
 

  return (
    <Card>
      <CardBody>
        <DragDropContext onDragEnd={handleDragEnd}>
          <VStack spacing={4}>
            <HStack spacing={8} align="stretch" width="100%">
              <Box flex={1}>
                <Text mb={4} fontWeight="bold">
                  Available Options
                </Text>
                <StrictModeDroppable droppableId="source">
                  {(provided) => (
                    <VStack {...provided.droppableProps} ref={provided.innerRef} spacing={4} align="stretch" minH="200px" bg="gray.50" p={2} borderRadius="md" position="relative">
                      {sourceOptions.map((option, index) => (
                        <Draggable key={`source-${index}`} draggableId={`source-${index}`} index={index} isDragDisabled={!option.text}>
                          {(provided, snapshot) => (
                            <>
                              <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                p={3}
                                bg={optionBg}
                                borderRadius="md"
                                borderWidth={1}
                                borderColor={borderColor}
                                borderStyle="solid"
                                minH="40px"
                                opacity={option.text ? 1 : 0}
                                userSelect="none"
                                position="relative"
                                display={option.showReplacement ? "none" : "block"}
                              >
                                {option.text}
                              </Box>
                              {(snapshot.isDragging || option.showReplacement) && <Box p={3} bg={emptyBoxBg} borderRadius="md" borderWidth={1} borderColor={borderColor} borderStyle="solid" minH="40px" opacity={0.5} />}
                            </>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </VStack>
                  )}
                </StrictModeDroppable>
              </Box>

              <Box flex={1}>
                <Text mb={4} fontWeight="bold">
                  Drop Zones
                </Text>
                <StrictModeDroppable droppableId="target">
                  {(provided) => (
                    <VStack
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      spacing={4}
                      align="stretch"
                      minH="300px"
                      h="300px"
                      bg={isCorrect ? "green.400" : emptyBoxBg}
                      p={4}
                      pt={8}
                      borderRadius="md"
                      position="relative"
                      borderWidth="2px"
                      borderStyle="dashed"
                      borderColor={borderColor}
                      _hover={{ borderColor: "blue.400" }}
                    >
                      {targetOptions.map((option, index) =>
                        option.text ? (
                          <Draggable key={`target-${index}`} draggableId={`target-${index}`} index={index}>
                            {(provided) => (
                              <Box
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                position="absolute"
                                top={`${index * (48 + 16) + 16}px`}
                                left={0}
                                right={0}
                                mx={4}
                                p={3}
                                bg={optionBg}
                                borderRadius="md"
                                borderWidth={1}
                                borderStyle="solid"
                                borderColor={isCorrect === null ? borderColor : isCorrect ? "green.400" : "red.400"}
                                minH="40px"
                                onDoubleClick={() => handleDoubleClick(index)}
                                cursor="pointer"
                                _hover={{ opacity: 0.8 }}
                                userSelect="none"
                                zIndex={1}
                              >
                                {option.text}
                              </Box>
                            )}
                          </Draggable>
                        ) : null
                      )}
                      {provided.placeholder}
                    </VStack>
                  )}
                </StrictModeDroppable>
              </Box>
            </HStack>

            <Flex gap={4} width="100%" justify="flex-end">
              
              {preview && (
                <Button onClick={checkAnswer} colorScheme={isCorrect === null ? "blue" : isCorrect ? "green" : "red"}>
                  Check Answer
                </Button>
              )}
            </Flex>
          </VStack>
        </DragDropContext>
      </CardBody>
    </Card>
  );
};

export default Quiz_DragDropPreview;
