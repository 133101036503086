import React from "react";
import { Box, Flex, Heading, Link, Text, Progress, Divider, CircularProgress, CircularProgressLabel, Tooltip, Stack, useBreakpointValue } from "@chakra-ui/react";
import { Course } from "@/features/learner/course/courseTypes";
import { Video } from "lucide-react";
import { FaTelegram } from "react-icons/fa";

interface Props {
  course: Course;
}

export const CourseTopBar: React.FC<Props> = ({ course }) => {
  const calculateProgress = () => {
    const start = new Date(course.startDate).getTime();
    const end = new Date(course.endDate).getTime();
    const now = new Date().getTime();
    const progress = ((now - start) / (end - start)) * 100;
    return Math.min(Math.max(0, progress), 100);
  };

  const getCurrentModule = () => {
    const start = new Date(course.startDate).getTime();
    const end = new Date(course.endDate).getTime();
    const now = new Date().getTime();
    const totalDays = (end - start) / (1000 * 60 * 60 * 24);
    const daysPerModule = totalDays / course.modules.length;
    const currentDay = (now - start) / (1000 * 60 * 60 * 24);
    const currentModuleIndex = Math.min(Math.floor(currentDay / daysPerModule), course.modules.length - 1);
    return course.modules[Math.max(0, currentModuleIndex)];
  };

  const getModuleProgress = () => {
    const modulePercentage = (currentModule.order / course.modules.length) * 100;
    return Math.round(modulePercentage);
  };

  const getCurrentModuleDayProgress = () => {
    const start = new Date(course.startDate).getTime();
    const end = new Date(course.endDate).getTime();
    const now = new Date().getTime();
    const totalDays = (end - start) / (1000 * 60 * 60 * 24);
    const daysPerModule = totalDays / course.modules.length;
    const currentDay = (now - start) / (1000 * 60 * 60 * 24);

    // Calculate days passed in current module
    const moduleStartDay = (currentModule.order - 1) * daysPerModule;
    const daysPassedInModule = currentDay - moduleStartDay;
    const moduleProgress = (daysPassedInModule / daysPerModule) * 100;

    return Math.min(Math.max(0, Math.round(moduleProgress)), 100);
  };

  const getModuleStartDate = () => {
    const start = new Date(course.startDate).getTime();
    const end = new Date(course.endDate).getTime();
    const totalDays = (end - start) / (1000 * 60 * 60 * 24);
    const daysPerModule = totalDays / course.modules.length;
    const moduleStartDay = (currentModule.order - 1) * daysPerModule;
    return new Date(start + moduleStartDay * 24 * 60 * 60 * 1000).toLocaleDateString();
  };

  const getDaysPassedInModule = () => {
    const start = new Date(course.startDate).getTime();
    const end = new Date(course.endDate).getTime();
    const totalDays = (end - start) / (1000 * 60 * 60 * 24);
    const daysPerModule = totalDays / course.modules.length;
    const moduleStartDay = (currentModule.order - 1) * daysPerModule;
    const moduleStartDate = start + moduleStartDay * 24 * 60 * 60 * 1000;
    return Math.floor((new Date().getTime() - moduleStartDate) / (1000 * 60 * 60 * 24));
  };

  const isMobile = useBreakpointValue({ base: true, md: false });
  const currentModule = getCurrentModule();
  const circleSize = useBreakpointValue({ base: "35px", md: "45px" }) || "45px";

  return (
    <Box p={{ base: 2, md: 6 }} pl={{ base: 2, md: 12 }} borderBottom="1px" borderColor="gray.200" bg="white">
      <Stack direction={{ base: "column", md: "row" }} gap={4} alignItems={{ base: "stretch", md: "flex-start" }} justify="space-between">
        <Box w="full">
          <Heading size={{ base: "md", md: "lg" }} color="purple.600" mb={2}>
            Course: {course.name}
          </Heading>
          <Stack direction={{ base: "column", md: "row" }} gap={{ base: 2, md: 4 }} alignItems={{ base: "stretch", md: "center" }} w="full">
            <Box flex={{ base: 1, md: "0 1 auto" }} minW={{ base: "full", md: "auto" }}>
              <Tooltip 
                label={`Day ${Math.ceil((new Date().getTime() - new Date(course.startDate).getTime()) / (1000 * 60 * 60 * 24))} of the course`}
                placement="top"
                hasArrow
                bg="rgba(171, 71, 188, 0.9)"
                color="white"
                whiteSpace="pre-line"
                fontSize="md"
                p={3}
                maxW={{ base: "250px", md: "400px" }}
                borderRadius="md"
                boxShadow="lg"
              >
                <Box>
                  <Text color="gray.600" fontSize={{ base: "xs", md: "sm" }} whiteSpace="nowrap">
                    Start: {new Date(course.startDate).toLocaleDateString()} • End: {new Date(course.endDate).toLocaleDateString()}
                  </Text>
                  <Box mt={2}>
                    <Progress value={calculateProgress()} size="xs" colorScheme="blue" borderRadius="full" bg="gray.100" />
                  </Box>
                </Box>
              </Tooltip>
            </Box>

            {!isMobile && <Divider orientation="vertical" h="40px" borderColor="gray.300" />}

            <Flex align="center" gap={2}>
              <Tooltip 
                label={`Total ${course.modules.length} Modules\nCurrent Module: ${currentModule.order}\n(${getModuleProgress()}%) of Modules Completed`} 
                placement="top" 
                hasArrow 
                bg="rgba(171, 71, 188, 0.9)"
                color="white"
                whiteSpace="pre-line"
                fontSize="md"
                p={3}
                maxW={{ base: "250px", md: "400px" }}
                borderRadius="md"
                boxShadow="lg"
              >
                <Text color="blue.600" fontSize={{ base: "sm", md: "md" }}>
                  Module {currentModule.order}/{course.modules.length}
                </Text>
              </Tooltip>
              <CircularProgress value={getModuleProgress()} color="blue.400" size={circleSize} thickness="8px">
                <CircularProgressLabel fontSize="xs">{getModuleProgress()}%</CircularProgressLabel>
              </CircularProgress>
            </Flex>

            {!isMobile && <Divider orientation="vertical" h="40px" borderColor="gray.300" />}

            <Flex align="center" gap={2}>
              <Tooltip 
                label={`Current Module: ${currentModule.name}\nStarted at: ${getModuleStartDate()}\n${getDaysPassedInModule()} days passed`} 
                placement="top" 
                hasArrow 
                bg="rgba(171, 71, 188, 0.9)"
                color="white"
                whiteSpace="pre-line"
                fontSize="md"
                p={3}
                maxW={{ base: "250px", md: "400px" }}
                borderRadius="md"
                boxShadow="lg"
              >
                <Text color="purple.600" fontSize={{ base: "sm", md: "md" }} noOfLines={1}>
                  Current: {currentModule.name}
                </Text>
              </Tooltip>
              <CircularProgress value={getCurrentModuleDayProgress()} color="green.400" size={circleSize} thickness="8px">
                <CircularProgressLabel fontSize="xs">{getCurrentModuleDayProgress()}%</CircularProgressLabel>
              </CircularProgress>
            </Flex>
          </Stack>
        </Box>

        <Flex gap={4} alignItems="center" justifyContent={{ base: "center", md: "flex-end" }} mt={{ base: 2, md: 0 }}>
          {course.zoomLink && (
            <Link href={course.zoomLink} isExternal color="blue.400" display="flex" alignItems="center" gap={2}>
              <Video size={20} />
              Zoom
            </Link>
          )}
          {course.telegramGroup && (
            <Link href={course.telegramGroup} isExternal color="blue.500" display="flex" alignItems="center" gap={2}>
              <FaTelegram size={20} />
              Telegram
            </Link>
          )}
        </Flex>
      </Stack>
    </Box>
  );
};
