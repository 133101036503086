import React, { useEffect, useState } from "react";
import { Box, Button, useDisclosure, AlertDialog, AlertDialogBody, AlertDialogFooter, AlertDialogHeader, AlertDialogContent, AlertDialogOverlay, Progress } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { resetQuiz, nextQuestion } from "@/features/learner/userQuiz/userQuizSlice";
import { fetchUserQuiz, startNewQuiz } from "@/features/learner/userQuiz/userQuizActions";
import { Quiz } from "@/features/learner/course/courseTypes";
import { RootState } from "@/store/store";
import { ModuleQuizStats } from "./ModuleQuizStats";
import { UserQuizResults } from "./UserQuizResults";
import QuizQuestion from "./QuizQuestion";

interface ModuleQuizProps {
  quiz: Quiz;
  userId: string;
}

const ModuleQuiz: React.FC<ModuleQuizProps> = ({ quiz, userId }) => {
  const dispatch = useAppDispatch();
  const { userQuizData, currentQuestionIndex, loading } = useAppSelector((state: RootState) => state.userQuiz);
  const { isOpen: isAlertOpen, onOpen: onAlertOpen, onClose: onAlertClose } = useDisclosure();
  const { isOpen: isQuizOpen, onOpen: onQuizOpen, onClose: onQuizClose } = useDisclosure();
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const [started, setStarted] = useState(false);

  useEffect(() => {
    dispatch(fetchUserQuiz({ userId, quizId: quiz.id }));
  }, [dispatch, userId, quiz.id]);

  const handleStartQuiz = async () => {
    if (userQuizData) {
      onAlertOpen();
    } else {
      await dispatch(startNewQuiz(quiz.id));
      setStarted(true);
      onQuizOpen();
    }
  };

  const handleConfirmRestart = async () => {
    onAlertClose();
    dispatch(resetQuiz());
    await dispatch(startNewQuiz(quiz.id));
    setStarted(true);
    onQuizOpen();
  };

  const handleNextQuestion = () => {
    dispatch(nextQuestion());
  };

  const handleQuizClose = () => {
    setStarted(false);
    onQuizClose();
  };

  if (loading) {
    return <Progress size="xs" isIndeterminate />;
  }

  return (
    <Box p={4}>
      <ModuleQuizStats quiz={quiz} />
     
      {!started && (
        <Box mt={4}>
          <UserQuizResults userQuiz={userQuizData} onStartQuiz={handleStartQuiz} />
        </Box>
      )}

      {started && userQuizData && quiz.questions[currentQuestionIndex] && (
        <QuizQuestion 
          question={quiz.questions[currentQuestionIndex]}
          currentQuestionNumber={currentQuestionIndex + 1}
          totalQuestions={quiz.questions.length}
          isOpen={isQuizOpen}
          onClose={handleQuizClose}
          onNext={handleNextQuestion}
          userQuizId={userQuizData.id}
        />
      )}

      <AlertDialog isOpen={isAlertOpen} leastDestructiveRef={cancelRef} onClose={onAlertClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader>Restart Quiz?</AlertDialogHeader>
            <AlertDialogBody>Your existing quiz progress will be deleted. Are you sure you want to start over?</AlertDialogBody>
            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onAlertClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleConfirmRestart} ml={3}>
                Restart Quiz
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </Box>
  );
};

export default ModuleQuiz;
