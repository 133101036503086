import api from "@/utils/api";
import { UserQuiz } from './userQuizTypes';

export const getUserQuizData = async (userId: string, quizId: string): Promise<UserQuiz> => {
  const response = await api.get(`/user-quizes/${userId}/${quizId}`);
  return response.data;
};

export const startQuiz = async (quizId: string): Promise<UserQuiz> => {
  const response = await api.post(`/quizes/${quizId}/start`);
  return response.data;
};

export const submitAnswer = async (userQuizId: string, questionId: string, answer: string): Promise<UserQuiz> => {
  const response = await api.post('/user-quizes/submit-answer', {
    userQuizId: userQuizId,
    questionId,
    answer,
  });
  return response.data;
}; 