import {
  Container,
  Card,
  CardBody,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  Center,
  Spinner,
  Box,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { getEnrollableCourseById } from "@/features/learner/enrollment/enrollmentActions";
import { resetEnrollment } from "@/features/learner/enrollment/enrollmentSlice";
import CourseDetails from "./steps/CourseDetails";
import PrerequisitesCheck from "./steps/PrerequisitesCheck";
import ScheduleConfirmation from "./steps/ScheduleConfirmation";
import PaymentSection from "./steps/PaymentSection";
import { useEffect } from "react";

const steps = [
  { title: "Course Details", description: "Review course information" },
  { title: "Prerequisites", description: "Check eligibility" },
  { title: "Schedule", description: "Confirm time commitment" },
  { title: "Payment", description: "Complete payment" },
];

const CourseEnrollment = () => {
  const { courseId } = useParams();

  const dispatch = useAppDispatch();
  // const toast = useToast();

  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  const { currentCourse, isLoading, error } = useAppSelector((state) => state.learnerEnrollment);

  useEffect(() => {
    if (courseId) {
      dispatch(getEnrollableCourseById(courseId));
    }

    return () => {
      dispatch(resetEnrollment());
    };
  }, [courseId, dispatch]);

  if (isLoading) {
    return (
      <Container maxW="container.lg" py={8}>
        <Center h="400px">
          <Spinner size="xl" color="purple.500" />
        </Center>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxW="container.lg" py={8}>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      </Container>
    );
  }

  if (!currentCourse) {
    return (
      <Container maxW="container.lg" py={8}>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Course Not Found</AlertTitle>
          <AlertDescription>The course you're looking for doesn't exist or has been removed.</AlertDescription>
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxW="container.lg" py={8}>
      {/* Enrollment Steps */}
      <Card mb={8}>
        <CardBody>
          <Stepper index={activeStep}>
            {steps.map((step, index) => (
              <Step key={index}>
                <StepIndicator>
                  <StepStatus complete={<StepIcon />} incomplete={<StepNumber />} active={<StepNumber />} />
                </StepIndicator>
                <Box flexShrink="0">
                  <StepTitle>{step.title}</StepTitle>
                  <StepDescription>{step.description}</StepDescription>
                </Box>
                <StepSeparator />
              </Step>
            ))}
          </Stepper>
        </CardBody>
      </Card>

      {/* Step Content */}
      {activeStep === 0 && <CourseDetails onNext={() => setActiveStep(1)} />}

      {activeStep === 1 && <PrerequisitesCheck onBack={() => setActiveStep(0)} onNext={() => setActiveStep(2)} />}

      {activeStep === 2 && <ScheduleConfirmation onBack={() => setActiveStep(1)} onNext={() => setActiveStep(3)} />}

      {activeStep === 3 && <PaymentSection onBack={() => setActiveStep(2)} />}
    </Container>
  );
};

export default CourseEnrollment;
