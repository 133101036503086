import api from "@/utils/api";
import {
  ICourse,
  ICourseDashboardData,
  ICourseQueryOptions,
  ICourseType,
  IModule,
  ITelegramGroup,
  ITelegramGroupMember,
  ITelegramGroupRequirement,
  IQuiz,
  IQuizQuestion,
   
} from "./adminCourseTypes";
import { IDefaultApiResponse } from "@/types/globalTypes";
import { IFetchDataResult } from "@/components/common/ReusableTable/ReusableTable";

export const getCourseTypes = async (): Promise<ICourseType[]> => {
  const response = await api.get("/courses/types");
  return response.data;
};

export const createCourseTypeApi = async (data: ICourseType): Promise<IDefaultApiResponse> => {
  const response = await api.post<IDefaultApiResponse>("/courses/types", data);
  return { message: response.data.message };
};

export const updateCourseTypeApi = async (data: ICourseType): Promise<IDefaultApiResponse> => {
  const response = await api.put<IDefaultApiResponse>(`/courses/types/${data.id}`, data);
  return { message: response.data.message };
};

export const getModuleList = async (courseTypeId: string): Promise<IModule[]> => {
  const response = await api.get(`/courses/types/${courseTypeId}/modules`);
  return response.data;
};

export const createModuleApi = async (data: IModule): Promise<IDefaultApiResponse> => {
  const response = await api.post<IDefaultApiResponse>("/courses/modules", data);
  return { message: response.data.message };
};

export const updateModuleApi = async (data: IModule): Promise<IDefaultApiResponse> => {
  const response = await api.put<IDefaultApiResponse>(`/courses/modules/${data.id}`, data);
  return { message: response.data.message };
};

export const getCourses = async (queryOptions: ICourseQueryOptions): Promise<IFetchDataResult<ICourse>> => {
  // Make the API call with the query parameters
  const response = await api.get("/courses", { params: queryOptions });
  return response.data;
};

export const getCourseById = async (courseId: string): Promise<ICourse> => {
  // Make the API call with the query parameters
  const response = await api.get(`/courses/${courseId}`);
  return response.data;
};

export const createCourseApi = async (data: ICourse): Promise<IDefaultApiResponse> => {
  const response = await api.post<IDefaultApiResponse>("/courses", data);
  return { message: response.data.message };
};

export const updateCourseApi = async (data: ICourse): Promise<IDefaultApiResponse> => {
  const response = await api.put<IDefaultApiResponse>(`/courses/${data.id}`, data);
  return { message: response.data.message };
};

export const toggleCourseActiveApi = async (courseId: string): Promise<ICourse> => {
  const response = await api.patch(`/courses/${courseId}/toggle-active`);
  return response.data;
};

export const toggleCourseEnrollmentApi = async (courseId: string): Promise<ICourse> => {
  const response = await api.patch(`/courses/${courseId}/toggle-enrollment`);
  return response.data;
};

export const getCourseDashboardData = async (): Promise<ICourseDashboardData> => {
  const response = await api.get("courses/dashboard");

  return response.data;
};

/*Telegram group apis */
export const getTelegramGroupRequirements = async (courseId: string): Promise<ITelegramGroupRequirement> => {
  const response = await api.get(`/telegram-groups/requirements/${courseId}`);
  return response.data;
};

export const getCourseGroups = async (courseId: string): Promise<ITelegramGroup[]> => {
  const response = await api.get(`/telegram-groups/course/${courseId}`);
  return response.data;
};

export const getGroupMembers = async (groupId: string): Promise<ITelegramGroupMember[]> => {
  const response = await api.get(`/telegram-groups/${groupId}/members`);
  return response.data;
};

export const createTelegramGroups = async (courseId: string, groupNames: string[]): Promise<{ message: string; groups: ITelegramGroup[] }> => {
  const response = await api.post(`/telegram-groups/create-and-distribute/${courseId}`, { groupNames });
  return response.data;
};

export const changeUserGroup = async (data: { userId: string; currentGroupId: string; newGroupId: string }): Promise<IDefaultApiResponse> => {
  const response = await api.put<IDefaultApiResponse>("/telegram-groups/change-user-group", data);
  return response.data;
};

export const getModuleQuiz = async (moduleId: string): Promise<IQuiz> => {
  const response = await api.get(`/quizes/module/${moduleId}`);
  return response.data;
};

export const createQuizApi = async (data: { moduleId: string }): Promise<IQuiz> => {
  const response = await api.post("/quizes", data);
  return response.data;
};

export const getQuizQuestions = async (quizId: string): Promise<IQuizQuestion[]> => {
  const response = await api.get(`/quizes/${quizId}/questions`);
  return response.data;
};

export const createQuizQuestionApi = async (quizId: string, data: Omit<IQuizQuestion, "id" | "quizId">): Promise<IQuizQuestion> => {
  const response = await api.post(`/quizes/${quizId}/questions`, data);
  return response.data;
};

export const updateQuizQuestionApi = async (quizId: string, questionId: string, data: Partial<IQuizQuestion>): Promise<IQuizQuestion> => {
  const response = await api.put(`/quizes/${quizId}/questions/${questionId}`, data);
  return response.data;
};

export const deleteQuizQuestionApi = async (quizId: string, questionId: string): Promise<void> => {
  await api.delete(`/quizes/${quizId}/questions/${questionId}`);
};
