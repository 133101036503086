import React from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  Heading,
  VStack,
  HStack,
  Button,
  Alert,
  AlertIcon,
  Box,
  Text,
  Icon,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Badge,
  Flex,
  List,
  ListItem,
  ListIcon
} from '@chakra-ui/react';
import { MdAccessTime, MdEvent, MdWarning, MdCheckCircle } from 'react-icons/md';
import { useAppSelector } from '@/hooks/useRedux';
import { DayOfWeek, IZoomTime } from '@/features/admin/course/adminCourseTypes';

interface ScheduleConfirmationProps {
  onBack: () => void;
  onNext: () => void;
}

/**
 * Formats a time string from hour and minute values
 * @param hour - Hour value (0-23)
 * @param minute - Minute value (0-59)
 * @returns Formatted time string (e.g., "09:00 AM")
 */
const formatTime = (hour: number, minute: number): string => {
  const date = new Date();
  date.setHours(hour, minute);
  return date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true
  });
};

/**
 * Capitalizes the first letter of a string
 * @param str - String to capitalize
 * @returns Capitalized string
 */
const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

/**
 * Checks if two zoom sessions are adjacent
 * @param session1 - First zoom session
 * @param session2 - Second zoom session
 * @returns True if sessions are on same day and within 15 minutes of each other
 */
const areSessionsAdjacent = (session1: IZoomTime, session2: IZoomTime): boolean => {
  if (session1.dayOfWeek !== session2.dayOfWeek) return false;
  
  const time1End = session1.hour * 60 + session1.minute + session1.duration;
  const time2Start = session2.hour * 60 + session2.minute;
  
  return Math.abs(time2Start - time1End) <= 15;
};

/**
 * Generates a summary of sessions by day
 * @param sessions - Array of zoom sessions
 * @returns Array of formatted day summaries
 */
const getSessionSummaryByDay = (sessions: IZoomTime[]): string[] => {
  const sessionsByDay = sessions.reduce((acc, session) => {
    const day = capitalizeFirstLetter(session.dayOfWeek);
    if (!acc[day]) acc[day] = [];
    acc[day].push(formatTime(session.hour, session.minute));
    return acc;
  }, {} as Record<string, string[]>);

  return Object.entries(sessionsByDay).map(([day, times]) => 
    `${day}: ${times.join(', ')}`
  );
};

const ScheduleConfirmation: React.FC<ScheduleConfirmationProps> = ({ onBack, onNext }) => {
  const { currentCourse } = useAppSelector((state) => state.learnerEnrollment);

  if (!currentCourse) return null;

  const formatDate = (date: Date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  // Sort zoom sessions by day of week and time
  const sortedZoomTimes = [...currentCourse.zoomTimes].sort((a, b) => {
    const dayOrder = Object.values(DayOfWeek).indexOf(a.dayOfWeek) - 
                    Object.values(DayOfWeek).indexOf(b.dayOfWeek);
    if (dayOrder !== 0) return dayOrder;
    return (a.hour * 60 + a.minute) - (b.hour * 60 + b.minute);
  });

  // Calculate total weekly time commitment
  const totalWeeklyMinutes = sortedZoomTimes.reduce((total, session) => total + session.duration, 0);
  const weeklyHours = Math.floor(totalWeeklyMinutes / 60);
  const remainingMinutes = totalWeeklyMinutes % 60;

  // Check for adjacent sessions
  const hasAdjacentSessions = sortedZoomTimes.some((session, index) => 
    index > 0 && areSessionsAdjacent(sortedZoomTimes[index - 1], session)
  );

  // Get session summary
  const sessionSummary = getSessionSummaryByDay(sortedZoomTimes);

  return (
    <Card>
      <CardHeader>
        <Heading size="md">Schedule Confirmation</Heading>
      </CardHeader>
      <CardBody>
        <VStack align="stretch" spacing={6}>
          <Alert status="info" variant="left-accent">
            <AlertIcon />
            <Box>
              <Text fontWeight="medium">Schedule Summary</Text>
              <List spacing={2} mt={2}>
                {sessionSummary.map((summary, index) => (
                  <ListItem key={index}>
                    <ListIcon as={MdCheckCircle} color="purple.500" />
                    {summary}
                  </ListItem>
                ))}
                <ListItem mt={2}>
                  <ListIcon as={MdAccessTime} color="purple.500" />
                  Total weekly commitment: 
                  <Badge colorScheme="purple" ml={2}>
                    {weeklyHours > 0 ? `${weeklyHours} hours` : ''} 
                    {remainingMinutes > 0 ? ` ${remainingMinutes} minutes` : ''}
                  </Badge>
                </ListItem>
              </List>
            </Box>
          </Alert>

          <Card variant="outline">
            <CardBody>
              <VStack spacing={6}>
                <HStack align="start" spacing={3} width="full">
                  <Icon as={MdEvent} color="purple.500" boxSize={6} mt={1} />
                  <Box flex="1">
                    <Text fontWeight="medium">Course Duration</Text>
                    <Text>Start: {formatDate(currentCourse.startDate)}</Text>
                    <Text>End: {formatDate(currentCourse.endDate)}</Text>
                  </Box>
                </HStack>

                <HStack align="start" spacing={3} width="full">
                  <Icon as={MdAccessTime} color="purple.500" boxSize={6} mt={1} />
                  <Box flex="1">
                    <Text fontWeight="medium">Detailed Schedule</Text>
                    <Table variant="simple" size="sm" mt={2}>
                      <Thead>
                        <Tr>
                          <Th>Day</Th>
                          <Th>Time</Th>
                          <Th>Duration</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {sortedZoomTimes.map((session, index) => (
                          <Tr key={index}>
                            <Td>{capitalizeFirstLetter(session.dayOfWeek)}</Td>
                            <Td>{formatTime(session.hour, session.minute)}</Td>
                            <Td>{session.duration} minutes</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </Box>
                </HStack>
              </VStack>
            </CardBody>
          </Card>

          {hasAdjacentSessions && (
            <Alert status="warning" variant="left-accent">
              <AlertIcon as={MdWarning} />
              <Box>
                <Text fontWeight="medium">Note</Text>
                <Text>
                  Some sessions are scheduled close together. Please ensure you can
                  handle back-to-back sessions.
                </Text>
              </Box>
            </Alert>
          )}

          <Alert status="warning" variant="left-accent">
            <AlertIcon />
            <Box>
              <Text fontWeight="medium">Important Notice</Text>
              <Text>
                Regular attendance at all scheduled sessions is required for successful 
                completion of the course. Please make sure you can attend most sessions 
                before enrolling.
              </Text>
            </Box>
          </Alert>

          <Flex justify="space-between" pt={4}>
            <Button onClick={onBack}>
              Back
            </Button>
            <Button colorScheme="purple" onClick={onNext}>
              Continue to Payment
            </Button>
          </Flex>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default ScheduleConfirmation;