// src/components/admin/courses/courseForm/sections/TelegramGroupSection/components/GroupMembersDialog.tsx

import React, { useEffect, useState } from "react";
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, Table, Thead, Tbody, Tr, Th, Td, Text, useToast, HStack } from "@chakra-ui/react";
import { useAppDispatch, useAppSelector } from "@/hooks/useRedux";
import { ITelegramGroup, ITelegramGroupMember } from "@/features/admin/course/adminCourseTypes";
import { fetchGroupMembersThunk } from "@/features/admin/course/adminCourseActions";
import ChangeUserGroupDialog from "./ChangeUserGroupDialog";

interface GroupMembersDialogProps {
  isOpen: boolean;
  onClose: () => void;
  group: ITelegramGroup;
  isReadOnly: boolean;
}

 

const GroupMembersDialog: React.FC<GroupMembersDialogProps> = ({ isOpen, onClose, group, isReadOnly }) => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [selectedUser , setSelectedUser ] = useState<ITelegramGroupMember | null>(null);

  const { selectedGroupMembers, isLoading } = useAppSelector((state) => state.adminCourse);

  useEffect(() => {
    if (isOpen) {
      dispatch(fetchGroupMembersThunk(group.id))
        .unwrap()
        .catch((error) => {
          toast({
            title: "Error fetching members",
            description: error,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        });
    }
  }, [dispatch, group.id, isOpen, toast]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size="4xl" scrollBehavior={"inside"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg={"blue.400"} color={"white"}>
            <HStack spacing={2}>
              <Text>
                {group.name} - ({selectedGroupMembers?.length})Members{" "}
              </Text>
              {isReadOnly && (
                <Text fontSize="sm" color="gray.500">
                  (View Only)
                </Text>
              )}
            </HStack>
          </ModalHeader>
          <ModalBody fontSize={"small"}>
            {isLoading ? (
              <Text>Loading members...</Text>
            ) : (
              <Table variant={"striped"} size="sm">
                <Thead>
                  <Tr>
                    <Th>No</Th>
                    <Th>Username</Th>
                    <Th>Email</Th>
                    <Th>Score</Th>
                    {!isReadOnly && <Th>Action</Th>}
                  </Tr>
                </Thead>
                <Tbody>
                  {selectedGroupMembers?.map((member, index) => (
                    <Tr key={member.id}>
                      <Td>{index}</Td>
                      <Td>{member.username}</Td>
                      <Td>{member.email}</Td>
                      <Td>{new Intl.NumberFormat().format(member.levelTestResult?.score)}</Td>
                      {!isReadOnly && (
                        <Td>
                          <Button size="xs" colorScheme="purple" onClick={() => setSelectedUser(member)}>
                            Change Group
                          </Button>
                        </Td>
                      )}
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            )}
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Close</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Add back ChangeUserGroupDialog */}
      {!isReadOnly && selectedUser && (
        <ChangeUserGroupDialog isOpen={!!selectedUser} onClose={() => setSelectedUser(null)} user={selectedUser} currentGroupId={group.id} />
      )}
    </>
  );
};

export default GroupMembersDialog;
