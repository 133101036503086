import { IFetchDataOptions } from "@/components/common/ReusableTable/ReusableTable";

export interface ICourseType {
  id: string;
  name: string;
  description: string;
  isActive: boolean;
}

export interface IModuleVideo {
  title: string;
  description: string;
  source: string;
}
export interface IModuleDocument {
  title: string;
  description: string;
  source: string;
}

export interface IModule {
  id: string;
  courseTypeId: string;
  name: string;
  description: string;
  order: number;
  video: IModuleVideo;
  docs: IModuleDocument[];
}

// Define the course status enum
export enum enumCourseStatus {
  Upcoming = "upcoming",
  Ongoing = "ongoing",
  Completed = "completed",
}

// Define the prerequisites interface
export interface IPrerequisites {
  levelTestScore?: number;
  requiredCourseTypeId?: string;
}

export enum DayOfWeek {
  Monday = "monday",
  Tuesday = "tuesday",
  Wednesday = "wednesday",
  Thursday = "thursday",
  Friday = "friday",
  Saturday = "saturday",
  Sunday = "sunday",
}

export interface IZoomTime {
  dayOfWeek: DayOfWeek;
  hour: number;
  minute: number;
  duration: number;
}

// Define the Course interface
export interface ICourse {
  id: string;
  name: string;
  description: string;
  typeId: string;
  startDate: Date; // Will always be ISO string in Redux
  endDate: Date; // Will always be ISO string in Redux
  primaryCoachId: string;
  secondaryCoachId?: string;
  price: number;
  maxEnrollments: number;
  currentEnrollments: number;
  moduleDuration: number;
  status: enumCourseStatus; // This will be a virtual field
  prerequisites: IPrerequisites;
  zoomLink: string;
  zoomTimes: IZoomTime[];
  isClosedToEnrolments: boolean;
  isActive: boolean;
}

// Export utility type for querying courses
export interface ICourseQueryOptions extends IFetchDataOptions {
  status?: enumCourseStatus;
  typeId?: string;
  coachId?: string;
  isActive?: string;
}

export interface ICourseDashboardData {
  summary: {
    courseTypesCount: number;
    modulesCount: number;
    coursesCount: number;
    coachesCount: number;
  };
  ongoingCourses: {
    count: number;
    courses: [
      {
        id: string;
        name: string;
        startDate: Date;
        endDate: Date;
        type: string;
        progressPercentage: number;
      }
    ];
  };
  upcomingCourses: {
    count: number;
    courses: [
      {
        id: string;
        name: string;
        startDate: Date;
        daysLeft: number;
        enrolled: number;
        enrollmentPercentage: number;
      }
    ];
  };
}

export interface ITelegramGroupMember {
  id: string;
  username: string;
  email: string;
  levelTestResult: {
    score: number;
  };
}

export interface ITelegramGroup {
  id: string;
  name: string;
  memberCount: number;
}

export interface ITelegramGroupRequirement {
  enrollmentCount: number;
  requiredGroups: number;
  groupSizes: number[];
  canCreate: boolean;
  message?: string;
}

export interface IQuizOption {
  id: string;
  text: string;
}

export interface IQuiz {
  id: string;
  moduleId: string;
  questions: IQuizQuestion[];
  createdAt: string;
  updatedAt: string;
}

export interface IQuizQuestion {
  id: string;
  quizId: string;
  content: string;
  sourceType: 'text' | 'audio';
  questionType: 'fill_blank' | 'sentence' | 'multi-option' | 'drag-drop';
  options?: { id: string; text: string }[];
  correctAnswer: string;
  audioUrl?: string;
  imageUrl?: string;
}

export interface AdminCoursesState {
  currentCourseType: ICourseType | null;
  currentModule: IModule | null;
  currentCourse: ICourse | null;
  currentQuiz: IQuiz | null;
  
  quizQuestions: IQuizQuestion[]; 

  telegramGroups: ITelegramGroup[] | null;
  telegramGroupRequirements: ITelegramGroupRequirement | null;
  selectedGroupMembers: ITelegramGroupMember[] | null;

  isLoading: boolean;
  error: string | null;
  lastResponseMessage: string | null;
}
