import * as yup from "yup";
import { DayOfWeek, IPrerequisites, IZoomTime } from "@/features/admin/course/adminCourseTypes";

//export type CourseFormData = Omit<ICourse, "id" | "status" | "currentEnrollments">;
// Form data type without computed/auto fields
export type CourseFormData = {
  name: string;
  description: string;
  typeId: string;
  startDate: string;
  endDate: string;
  primaryCoachId: string;
  secondaryCoachId?: string;
  price: number;
  maxEnrollments: number;
  moduleDuration: number;
  prerequisites: IPrerequisites;
  zoomLink: string;
  zoomTimes: Array<{
    dayOfWeek: DayOfWeek;
    hour: number;
    minute: number;
    duration: number;
  }>;
  isActive: boolean;
};

/**
 * Checks if there are any overlapping zoom sessions on the same day
 * 
 * @param sessions - Array of zoom sessions to check
 * @returns boolean - Returns false if there are overlapping sessions, true otherwise
 * 
 * Algorithm explanation:
 * 1. For each session, compare it with all other sessions that come after it
 * 2. If two sessions are on the same day, calculate their start and end times in minutes
 * 3. Check if either session starts during the other session's time period
 * 
 * Example of overlap cases:
 * Case 1: Session 1 (9:00-10:00) and Session 2 (9:30-10:30) - Overlap
 * Case 2: Session 1 (9:00-10:00) and Session 2 (10:00-11:00) - No overlap
 * Case 3: Session 1 (9:00-11:00) and Session 2 (10:00-10:30) - Overlap
 */
const checkOverlappingSessions = (sessions: IZoomTime[]): boolean => {
  // Iterate through each session
  for (let i = 0; i < sessions.length; i++) {
    // Compare with all sessions that come after the current session
    // This avoids comparing the same pair of sessions twice
    for (let j = i + 1; j < sessions.length; j++) {
      // Only check sessions on the same day
      if (sessions[i].dayOfWeek === sessions[j].dayOfWeek) {
        // Convert session times to minutes for easier comparison
        const time1Start = sessions[i].hour * 60 + sessions[i].minute;
        const time1End = time1Start + sessions[i].duration;
        const time2Start = sessions[j].hour * 60 + sessions[j].minute;
        const time2End = time2Start + sessions[j].duration;

        // Check for overlap conditions:
        // 1. Session 2 starts during Session 1 (time2Start falls within Session 1's time period)
        // 2. Session 1 starts during Session 2 (time1Start falls within Session 2's time period)
        if ((time1Start >= time2Start && time1Start < time2End) || 
            (time2Start >= time1Start && time2Start < time1End)) {
          return false; // Overlap found
        }
      }
    }
  }
  return true; // No overlaps found
};
// Validation schema
export const courseSchema = yup.object().shape({
  name: yup.string().min(3, "Course name must be at least 3 characters").required("Course name is required"),
  description: yup.string().required("Description is required"),
  typeId: yup.string().required("Course type is required"),
  startDate: yup.string().required("Start date is required"),
  endDate: yup.string().required("End date is required"),

  primaryCoachId: yup.string().required("Primary coach is required"),
  secondaryCoachId: yup.string(),
  price: yup.number().required("Price is required").min(0, "Price cannot be negative"),
  maxEnrollments: yup.number().required("Maximum enrollments is required").min(1, "Must allow at least one enrollment"),
  moduleDuration: yup.number().required("Module duration is required").min(1, "Duration must be at least 1 day"),
  prerequisites: yup.object({
    levelTestScore: yup.number().min(0, "Score must be between 0 and 4000").max(4000, "Score must be between 0 and 4000"),
    requiredCourseTypeId: yup.string(),
  }),
  zoomLink: yup.string().required("Zoom meeting link is required").url("Must be a valid URL"),

  zoomTimes: yup
    .array()
    .of(
      yup.object().shape({
        dayOfWeek: yup.string().required("Meeting day is required").oneOf(Object.values(DayOfWeek), "Invalid day of week"),
        hour: yup.number().required("Hour is required").min(0, "Hour must be between 0 and 23").max(23, "Hour must be between 0 and 23").integer("Hour must be a whole number"),
        minute: yup
          .number()
          .required("Minute is required")
          .min(0, "Minute must be between 0 and 59")
          .max(59, "Minute must be between 0 and 59")
          .integer("Minute must be a whole number"),
        duration: yup
          .number()
          .required("Duration is required")
          .min(15, "Duration must be at least 15 minutes")
          .max(240, "Duration cannot exceed 4 hours")
          .integer("Duration must be a whole number"),
      })
    )
    .required("At least one zoom session is required")
    .min(1, "At least one zoom session is required")
    .test("no-overlapping-sessions", "Sessions on the same day cannot overlap", function (sessions) {
      return sessions ? checkOverlappingSessions(sessions) : true;
    }),

  isActive: yup.boolean().default(true),
});
