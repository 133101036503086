// src/utils/api.ts

import axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { store } from "@/store/store";
import { clearAuth, updateTokens } from "@/features/auth/authSlice";
import {refreshToken as refreshTokenApi} from "@/features/auth/authApi";
import { API_URL } from "@/constants/globalConstants";


// Create an axios instance with a base URL
const api = axios.create({
  baseURL: API_URL,
});

// Request interceptor
api.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // Get the current access token from the Redux store
    const token = store.getState().auth.token;
    // If a token exists, add it to the request headers
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error: AxiosError) => Promise.reject(error)
);

// Response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    const originalRequest = error.config as InternalAxiosRequestConfig & {
      _retry?: boolean;
    };
    // Check if the error is due to an expired token (status 401) and it's not already retrying
    if (error.response?.status === 401 && !originalRequest._retry) {
      console.log('We got an expired token (status 401)')
      originalRequest._retry = true;
      try {
        // Attempt to refresh the token
        // Get the current access token from the Redux store
        const refreshtoken = store.getState().auth.refreshToken;
        //console.log('this is the refresh token :' , refreshtoken)

        const response = await refreshTokenApi(refreshtoken!);
        const { accessToken: newAccessToken, refreshToken: newRefreshToken } =
          response;

        console.log('New tokens acquired')
        //console.log('new tokens acquired : new refresh token :' , newRefreshToken)

        // Update the tokens in the Redux store
        store.dispatch(
          updateTokens({ token: newAccessToken, refreshToken: newRefreshToken })
        );

        // Retry the original request with the new token
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;
        console.log('Trying the original request ')// , originalRequest) 
        return api(originalRequest);

      } catch (refreshError) {
        // If token refresh fails, log the user out
        console.log('!!Token refresh failed ,loggin out')
        store.dispatch(clearAuth());
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
