import React from 'react';
import { VStack, Button, Text, Divider, Tooltip, Box } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { FaHome, FaBook,   FaBell, FaEnvelope, FaChartBar,  } from 'react-icons/fa';
 

interface CoachSidebarProps {
  onClose?: () => void;
  isCollapsed?: boolean;
}

const CoachSidebar: React.FC<CoachSidebarProps> = ({ onClose, isCollapsed = false }) => {
  const menuItems = [
    { icon: FaHome, label: 'Dashboard', to: '/coach/' },
    { icon: FaBook, label: 'Courses', to: '/coach/courses' },
    { icon: FaBell, label: 'Notifications', to: '/admin/notifications' },
    { icon: FaEnvelope, label: 'Messages', to: '/admin/messages' },
    { icon: FaChartBar, label: 'Statistics', to: '/admin/statistics' },
    
    
  ];

  return (
    <VStack spacing={4} align="stretch" p={4}>
      {!isCollapsed && (
        <>
          <Text fontSize="xl" fontWeight="bold" mb={2} color="white">Coach Menu</Text>
          <Divider />
        </>
      )}
      {menuItems.map((item) => (
        <Tooltip key={item.to} label={isCollapsed ? item.label : ''} placement="right">
          <Box>
            <Button
              colorScheme='orange.500'
              leftIcon={<item.icon />}
              as={RouterLink}
              to={item.to}
              onClick={onClose}
              justifyContent={isCollapsed ? 'center' : 'flex-start'}
              width="100%"
              px={isCollapsed ? 2 : 4}
            >
              {!isCollapsed && item.label}
            </Button>
          </Box>
        </Tooltip>
      ))}
    </VStack>
  );
};

export default CoachSidebar;