import AnimatedCard from "@/components/common/AnimatedCard";
import InfoCard from "@/components/common/InfoCard";

import ReusableTable, { IFetchDataOptions } from "@/components/common/ReusableTable/ReusableTable";
import { getCourses, getCourseTypes } from "@/features/admin/course/adminCourseApi";
import { clearError, setCurrentCourse } from "@/features/admin/course/adminCourseSlice";
import { enumCourseStatus, ICourse, ICourseQueryOptions, ICourseType } from "@/features/admin/course/adminCourseTypes";
import { useAppDispatch } from "@/hooks/useRedux";
import { Button, CardBody, CardHeader, Checkbox, Flex, FormLabel, HStack, Select, Text, useToast } from "@chakra-ui/react";
import { CellContext } from "@tanstack/react-table";
import { useCallback, useEffect, useRef, useState } from "react";

import { formMode } from "@/types/globalTypes";
import { enPaginationSide, fetchData_ReusableTable } from "@/utils/helpers";
import { MdSearch } from "react-icons/md";
import { ICoachUser } from "@/features/admin/coaches/adminCoachTypes";
import { getCoachList } from "@/features/admin/coaches/adminCoachApi";
import { useNavigate } from "react-router-dom";
import { FaHourglass, FaHourglassHalf, FaRegHourglass } from "react-icons/fa";

const columns = [
  { accessorKey: "id", header: "ID" },
  { accessorKey: "name", header: "Course Name" },
  {
    accessorKey: "status",
    header: "Status",
    cell: (context: CellContext<ICourse, unknown>) => {
      const cellValue = context.getValue();
      let icon = null;
      switch (cellValue) {
        case "upcoming":
          icon = <FaRegHourglass />;
          break;
        case "ongoing":
          icon = <FaHourglassHalf />;
          break;
        case "completed":
          icon = <FaHourglass />;
          break;
      }
      return (
        <HStack>
          {icon}
          <Text>{(cellValue as string).toLocaleUpperCase()}</Text>
        </HStack>
      );
    },
  },
  {
    accessorKey: "startDate",
    header: "Start Date",
    cell: (context: CellContext<ICourse, unknown>) => {
      const cellValue = context.getValue();
      return new Date(cellValue as string).toLocaleDateString();
    },
  },
  {
    accessorKey: "endDate",
    header: "End Date",
    cell: (context: CellContext<ICourse, unknown>) => {
      const cellValue = context.getValue();
      return new Date(cellValue as string).toLocaleDateString();
    },
  },
  { accessorKey: "currentEnrollments", header: "Enrollments" },
  {
    accessorKey: "isActive",
    header: "Deleted ?",
    cell: (context: CellContext<ICourse, unknown>) => {
      const cellValue = context.getValue();
      return cellValue === false ? (
        <Text as="b" color={"red.600"}>
          deleted !{" "}
        </Text>
      ) : (
        "-"
      );
    },
  },
];

const FILTER_OPTIONS = {
  status: [
    { value: "ongoing", label: "Ongoing Courses" },
    { value: "upcoming", label: "Upcoming Courses" },
    { value: "completed", label: "Completed Courses" },
  ],
} as const;

const CourseList: React.FC = () => {
  //Course type list select data local state :
  const [courseTypes, setCourseTypes] = useState<ICourseType[] | null>(null);
  //Coach list select data  local state :
  const [coaches, setCoaches] = useState<ICoachUser[] | null>(null);

  //Query parameters state :
  const initalQueryParams: ICourseQueryOptions = {
    status: undefined,
    isActive: undefined,
    typeId: undefined,
    coachId: undefined,
    pageIndex: 0,
    pageSize: 10,
    sortBy: undefined,
    searchText: "",
    searchColumn: undefined,
  };
  const [queryOptions, setQueryOptions] = useState(initalQueryParams);

  //query parameter  control refs :
  const paramCourseTypeRef = useRef<HTMLSelectElement>(null);
  const paramCourseStatusRef = useRef<HTMLSelectElement>(null);
  const paramCoachRef = useRef<HTMLSelectElement>(null);
  const paramIsActiveRef = useRef<HTMLInputElement>(null);

  //Hooks :
  const dispatch = useAppDispatch();
  const toast = useToast();
  const navigate = useNavigate();

  //Load filter data :
  const loadFilterData = useCallback(async () => {
    try {
      const [courseTypeList, coachList] = await Promise.all([getCourseTypes(), getCoachList()]);
      setCourseTypes(courseTypeList);
      setCoaches(coachList);
    } catch (error) {
      toast({
        title: "Error fetching filter options data!",
        description: error instanceof Error ? error.message : "Unknown error occurred",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);

  useEffect(() => {
    loadFilterData();
  }, [loadFilterData]);

  const handleFilterClick = () => {
    setQueryOptions((prev) => {
      const options: ICourseQueryOptions = {
        ...prev,
        typeId: paramCourseTypeRef.current?.value || undefined,
        coachId: paramCoachRef.current?.value || undefined,
        status: (paramCourseStatusRef.current?.value as enumCourseStatus) || undefined,
        isActive: paramIsActiveRef.current?.checked ? "true" : undefined,
      };
      return options;
    });
  };

  const handleViewType = async (course: ICourse) => {
    // Test 1: Log the original course data
    //console.log("Original course data:", course);

    dispatch(clearError());
    dispatch(setCurrentCourse(course));
    navigate(`/admin/courses/courseform/${formMode.VIEW}/${course.id}`);
  };

  const handleCreateType = () => {
    dispatch(clearError());
    dispatch(setCurrentCourse(null));
    navigate(`/admin/courses/courseform/${formMode.CREATE}/null`);
  };

  const handleEditType = (course: ICourse) => {
    dispatch(clearError());
    dispatch(setCurrentCourse(course));
    navigate(`/admin/courses/courseform/${formMode.EDIT}/${course.id}`);
  };

  return (
    <AnimatedCard>
      <CardHeader bg={"pink.400"} color={"white"} p={3}>
        <Text>Course List</Text>
      </CardHeader>
      <CardBody>
        <InfoCard title="Course List" image="/assets/infocards/LevelTest.png">
          <Flex direction={"column"} gap={"10px"}>
            <HStack>
              <FormLabel flex={1}>Course Status: </FormLabel>
              <Select ref={paramCourseStatusRef} flex={2} placeholder="Select a course status">
                {FILTER_OPTIONS.status.map(({ value, label }) => (
                  <option key={value} value={value}>
                    {label}
                  </option>
                ))}
              </Select>
            </HStack>
            <HStack>
              <FormLabel flex={1}>Course Type: </FormLabel>
              <Select ref={paramCourseTypeRef} flex={2} placeholder="Select a course type">
                {courseTypes &&
                  courseTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.name}
                    </option>
                  ))}
              </Select>
            </HStack>
            <HStack>
              <FormLabel flex={1}>Primary Coach: </FormLabel>
              <Select ref={paramCoachRef} flex={2} placeholder="Select a Coach">
                {coaches &&
                  coaches.map((coach) => (
                    <option key={coach.id} value={coach.id}>
                      {coach?.user?.username}
                    </option>
                  ))}
              </Select>
            </HStack>
            <HStack>
              <FormLabel flex={1}></FormLabel>
              <Checkbox flex={2} colorScheme="purple" ref={paramIsActiveRef} defaultChecked>
                Hide deleted courses
              </Checkbox>
            </HStack>

            <Flex justify={"right"} pt={2}>
              <Button leftIcon={<MdSearch />} colorScheme="blue" size={"md"} onClick={handleFilterClick}>
                Filter Courses
              </Button>
            </Flex>
          </Flex>
        </InfoCard>

        <ReusableTable
          //key={refreshTrigger} // just to trigger a refetch using state
          columns={columns}
          fetchData={useCallback(
            (options: IFetchDataOptions) =>
              fetchData_ReusableTable<ICourse>(
                options,
                () => getCourses({ ...options, typeId: queryOptions.typeId, coachId: queryOptions.coachId, status: queryOptions.status, isActive: queryOptions.isActive }),
                enPaginationSide.SERVER
              ),
            [queryOptions]
          )}
          initialPageSize={10}
          tableTitle="Course List"
          onEdit={handleEditType}
          onCreate={handleCreateType}
          onView={handleViewType}
        />
      </CardBody>
    </AnimatedCard>
  );
};

export default CourseList;
