import React, { useState } from 'react';
import {
  HStack,
  FormLabel,
  Input,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import ImageUploader from './ImageUploader';
import {  ImageType } from '@/types/imageTypes';

interface FileUploadInputProps {
  label?: string;
  value?: string;
  onChange: (value: string) => void;
  recordId?: string;
  imageType: ImageType;
  isDisabled?: boolean;
}

const FileUploadInput: React.FC<FileUploadInputProps> = ({
  label = 'Image : ',
  value = '',
  onChange,
  recordId,
  imageType,
  isDisabled = false
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleUploadSuccess = () => {
    if (recordId) {
      onChange(recordId);
      setIsModalOpen(false);
    }
  };

  const handleUploadError = (error: string) => {
    console.error('Upload failed:', error);
  };

  return (
    <>
      <HStack align="center" spacing={4}>
        <FormLabel mb={0} minW="100px">{label}</FormLabel>
        <Input
          value={value}
          isReadOnly
          placeholder="No file selected"
          flex={1}
        />
        <Button
          onClick={() => setIsModalOpen(true)}
          isDisabled={isDisabled || !recordId}
        >
          Upload
        </Button>
      </HStack>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upload Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <ImageUploader
              id={recordId!}
              type={imageType}
              onUploadSuccess={handleUploadSuccess}
              onUploadError={handleUploadError}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default FileUploadInput; 